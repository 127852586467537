import { createSlice } from "@reduxjs/toolkit";
import { getPatientProcedures } from "services/report/report";

const initialState = {
    patientProcedures: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const patientProceduresSlice = createSlice({
    name: "patientProcedures",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPatientProcedures.pending, (state) => {
                state.patientProcedures.isLoading = true;
            })
            .addCase(getPatientProcedures.fulfilled, (state, { payload }) => {
                state.patientProcedures.isLoading = false;
                state.patientProcedures.isSuccess = true;
                state.patientProcedures.data = payload;
            })
            .addCase(getPatientProcedures.rejected, (state, { payload }) => {
                state.patientProcedures.isLoading = false;
                state.patientProcedures.isSuccess = false;
                state.patientProcedures.errorMessage = payload;
            });
    },
});

export default patientProceduresSlice.reducer;