import React, {useEffect, useState} from "react";
import moment from "moment";
import MDBox from "../MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../MDTypography";

function DateTime (){
    const [currentTime, setCurrentTime] = useState(moment().format('hh:mm A, ddd D MMM YYYY'));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format('hh:mm A, ddd D MMM YYYY'));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return(
        <MDBox>
            <Card sx={{marginLeft:-2,borderRadius:2,backgroundColor: '#FFFFFF',
            }}>
                <MDTypography sx={{marginLeft:2,marginRight:2,marginBottom:1,marginTop:1,fontSize: 15,}}>
                    {currentTime}
                </MDTypography>
            </Card>
        </MDBox>
    );
}
export default DateTime;
