import MDBox from "common/components/MDBox";
import Avatar from "@mui/material/Avatar";
import {doctor} from "config/images";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {getTodayAllDoctors} from "services/dashboard/dashboard";
import {useDispatch, useSelector} from "react-redux";

const DoctorsList = () => {
    const dispatch = useDispatch();
    const [doctorsData,setDoctorsData] = useState([]);
    const doctors = useSelector(state => state.TodayDoctorSlice.doctor);

    useEffect(() => {
        dispatch(getTodayAllDoctors());
    }, []);

    useEffect(() => {
        if(doctors.isSuccess){
            const data = doctors?.data;
            if(Array.isArray(data)){
                const array = data.map((val)=>({
                    name:val.name,
                    contactNo:val.contactNo,
                    isAvailable:val.status === 1 ,
                }));
                setDoctorsData(array);
            }
        }
    }, [doctors.data]);

    return (
        <MDBox style={{ overflowY: 'scroll', maxHeight: '470px' }}>
            {
                doctorsData.map((doc)=>(
                    <MDBox sx={{display:'flex', alignItems:'center' , border:'1px solid #EAF5FC', borderRadius:'4px', marginX:'6px',marginBottom:'6px',backgroundColor:doc.isAvailable === true ? '#EAF5FC' : 'white'}}>
                        <div >
                            <Avatar
                                alt="doctor"
                                src={doctor}
                                sx={{ bgcolor: '#EAF5FC', width: '40px', height: '40px' ,margin:1 }}
                            />
                        </div>
                        <div>
                            <Typography sx={{fontSize:"13px" , fontWeight:500 ,color:'#1C5E7C',marginBottom:'-1px' }}>
                                {doc.name}
                            </Typography>
                            <Typography sx={{fontSize:"11px", color:'#1C5E7C'}}>
                                {doc.contactNo}
                            </Typography>
                        </div>
                    </MDBox>
                ))
            }
        </MDBox>
    );
}

export default DoctorsList;
