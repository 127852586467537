import FilterModal from "common/components/FilterModal";
import TextInput from "common/components/TextInput/TextInput";
import MDBox from "common/components/MDBox";
import SearchIcon from "@mui/icons-material/Search";
import CommonButton from "common/components/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {useState} from "react";

function Index({open, onClose}) {
    const [selectedRoom, setSelectedRoom] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const handleRoomClick = (roomNumber) => {
        setSelectedRoom(roomNumber);
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    };
    const handleClose = () => {
        onClose();
    };

    const roomNumbers = [30, 31, 32, 33, 34, 36, 37, 38, 39, 40, 41, 42, 43,];
    const Times = ['08:00 to 10:00 AM', '10:00 to 12:00 AM', '12:00 to 14:00 PM', '14:00 to 16:00 PM', '16:00 to 18:00 PM'];

    return (
        <FilterModal isOpen={open} closeModel={onClose} maxWidth="md">
            <MDBox open={open} onClose={handleClose} maxWidth="md">

                <Box sx={{marginTop: '15px'}}>
                    <TextInput
                        placeholder={'Search'}
                        endIcon={<SearchIcon/>}
                    />
                </Box>

                <Typography sx={{fontSize: '12px', marginTop: '10px', marginBottom: '10px'}}> Room No</Typography>

                <Grid container spacing={1.5}>
                    {roomNumbers.map((roomNumber) => (
                        <Grid key={roomNumber} item xs={6} sm={4} md={3} lg={2.4}>
                            <CommonButton
                                name={roomNumber}
                                circular={true}
                                variant={selectedRoom === roomNumber ? 'Fill' : 'outline'}
                                onClick={() => handleRoomClick(roomNumber)}
                            />

                        </Grid>
                    ))}
                </Grid>

                <Typography sx={{fontSize: '12px', marginTop: '10px', marginBottom: '10px'}}> Time </Typography>
                <Grid container spacing={2}>
                    {Times.map((time) => (
                        <Grid key={time} item xs={12} sm={6} md={6} lg={6}>
                            <CommonButton
                                name={time}
                                circular={true}
                                variant={selectedTime === time ? 'Fill' : 'outline'}
                                onClick={() => handleTimeClick(time)}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Typography sx={{fontSize: '12px', marginTop: '10px'}}> Payment </Typography>

                <Grid container style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <Grid item xs={5} lg={3}>
                        <CommonButton
                            sx={{width: '80px'}}
                            name={'Bill'}
                            circular={true}
                            variant={'Fill'}
                            onClick={() => {
                                console.log("Clicked")
                            }}
                        />
                    </Grid>

                    <Grid item xs={1.2} lg={1.2}></Grid>

                    <Grid item xs={5} lg={3}>
                        <CommonButton
                            sx={{width: '80px'}}
                            name={'Paid'}
                            circular={true}
                            variant={'outline'}
                        />

                    </Grid>

                    <Grid item xs={12} lg={4.8}></Grid>

                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                    <CommonButton
                        name={'Show (02) patients'}
                        variant={'Fill'}
                        onClick={() => {
                            console.log("Clicked")
                        }}
                    />
                </Box>
            </MDBox>
        </FilterModal>
    );
}

Index.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.bool.isRequired,
};

export default Index;