import { createSlice } from "@reduxjs/toolkit";
import {getBranchWithChairs} from "services/branch/branch";

const initialState = {
    branch: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const getBranchWithChairsSlice = createSlice({
    name: "getBranchWithChairs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBranchWithChairs.pending, (state) => {
                state.branch.isLoading = true;
            })
            .addCase(getBranchWithChairs.fulfilled, (state, { payload }) => {
                state.branch.isLoading = false;
                state.branch.isSuccess = true;
                state.branch.data = payload;
            })
            .addCase(getBranchWithChairs.rejected, (state, { payload }) => {
                state.branch.isLoading = false;
                state.branch.isSuccess = false;
                state.branch.errorMessage = payload;
            });
    },
});

export default getBranchWithChairsSlice.reducer;
