export const pageLoading = require("assets/images/page-loading.gif");
export const logo = require("assets/images/MainLogo.png");
export const loginImage = require("assets/images/dentistry-team-office.png");
export const dentalIconImage = require("assets/images/dental.png")
export const inventoryIconImage = require("assets/images/inventory.png")
export const receptionIconImage = require("assets/images/reception.png")
export const myDentistLogo = require("assets/images/mydentistlogo.png")
export const doctor = require("assets/images/doctor.png")
export const click = require("assets/images/click.png")
export const patient = require("assets/images/patient.png")
export const patientAdd = require("assets/images/patientAdd.png")
export const dentistLogo = require("assets/images/dentist.png")
export const dentistImg = require("assets/images/dentistlogo.png")
export const blueCross = require("assets/images/bluecross.png")
export const time = require("assets/images/time.png")
export const tooth = require("assets/images/tooth.png")
export const toothChart = require("assets/images/toothChart/Tooth-18.png")
export const DoctorBag = require("assets/images/medical-bag.png")
export const toothUp = require("assets/images/tooth-up.png")
export const toothDown = require("assets/images/tooth-down.png")

// Tooth Details img
export const Tooth_18 = require("assets/images/toothChart/Tooth-18.png");
export const Tooth_17 = require("assets/images/toothChart/Tooth-17.png")
export const Tooth_16 = require("assets/images/toothChart/Tooth-16.png")
export const Tooth_15 = require("assets/images/toothChart/Tooth-15.png")
export const Tooth_14 = require("assets/images/toothChart/Tooth-14.png")
export const Tooth_13 = require("assets/images/toothChart/Tooth-13.png")
export const Tooth_12 = require("assets/images/toothChart/Tooth-12.png")
export const Tooth_11 = require("assets/images/toothChart/Tooth-11.png")
export const Tooth_21 = require("assets/images/toothChart/Tooth-21.png")
export const Tooth_22 = require("assets/images/toothChart/Tooth-22.png")
export const Tooth_23 = require("assets/images/toothChart/Tooth-23.png")
export const Tooth_24 = require("assets/images/toothChart/Tooth-24.png")
export const Tooth_25 = require("assets/images/toothChart/Tooth-25.png")
export const Tooth_26 = require("assets/images/toothChart/Tooth-26.png")
export const Tooth_27 = require("assets/images/toothChart/Tooth-27.png")
export const Tooth_28 = require("assets/images/toothChart/Tooth-28.png")
export const Tooth_48 = require("assets/images/toothChart/Tooth-48.png")
export const Tooth_47 = require("assets/images/toothChart/Tooth-47.png")
export const Tooth_46 = require("assets/images/toothChart/Tooth-46.png")
export const Tooth_45 = require("assets/images/toothChart/Tooth-45.png")
export const Tooth_44 = require("assets/images/toothChart/Tooth-44.png")
export const Tooth_43 = require("assets/images/toothChart/Tooth-43.png")
export const Tooth_42 = require("assets/images/toothChart/Tooth-42.png")
export const Tooth_41 = require("assets/images/toothChart/Tooth-41.png")
export const Tooth_31 = require("assets/images/toothChart/Tooth-31.png")
export const Tooth_32 = require("assets/images/toothChart/Tooth-32.png")
export const Tooth_33 = require("assets/images/toothChart/Tooth-33.png")
export const Tooth_34 = require("assets/images/toothChart/Tooth-34.png")
export const Tooth_35 = require("assets/images/toothChart/Tooth-35.png")
export const Tooth_36 = require("assets/images/toothChart/Tooth-36.png")
export const Tooth_37 = require("assets/images/toothChart/Tooth-37.png")
export const Tooth_38 = require("assets/images/toothChart/Tooth-38.png")
export const discount = require("assets/images/discount.png")
export const upArrow = require("assets/images/UpArrow.png")
export const downArrow = require("assets/images/DownArrow.png")
export const transaction = require("assets/images/transaction.png")
export const confirm = require("assets/images/confirm.png")

export const itm = require("assets/images/item.png")

// Inventory Dashboard img
export const inventoryDashboardImage = require("assets/images/inventory-dashboard.png")
export const inventoryDashboardImage2 = require("assets/images/inventory-dashboard_2.png")
export const inventoryDashboardImage3 = require("assets/images/inventory-dashboard_3.png")

