import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import IconButton from "@mui/material/IconButton";
import CommonButton from "common/components/Button";
import ConfirmAlert from "components/ConfirmAlert";
import {patientAdd} from "config/images";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import WideCard from "common/components/WideCard";
import SupplierAddModal from "components/SupplierAddModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteSupplier, getSuppliers} from "services/suppllier/supplier";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function ManageSupplier() {
    const dispatch = useDispatch();
    const supplierGetData = useSelector(state => state.SupplierSlice.supplier);
    const supplierDltData = useSelector(state => state.DeleteSupplierSlice.supplier);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isViewModalOpen, setViewModalOpen] = useState(false);
    const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [tableData, setTableData] = useState([])
    const [deleteLoader, setDeleteLoader] = useState(false)

    const handleDelete = (id) => {
        setDeleteLoader(true)
        dispatch(deleteSupplier(id));
    };

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false)
            if (supplierDltData.isSuccess) {
                dispatch(getSuppliers())
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Supplier Deleted Success',
                })
                setDeleteAlertOpen(false);
            } else if (!supplierDltData.isSuccess && !supplierDltData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Supplier Deleted Warning'
                })
            }
        }
    }, [supplierDltData.data]);

    useEffect(() => {
        dispatch(getSuppliers())
    }, []);

    useEffect(() => {
        if (supplierGetData.isSuccess) {
            const data = supplierGetData?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => ({
                    id: val.supplierId,
                    name: val.name,
                    address: val.address,
                    email: val.email,
                    contactNo: val.contactNo,
                    action: (
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                            <IconButton
                                onClick={() => {
                                    setSelectedSupplier(val)
                                    setViewModalOpen(true);
                                }}>
                                <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                            </IconButton>

                            <IconButton onClick={() => {
                                setSelectedSupplier(val)
                                setDeleteAlertOpen(true);
                            }}>
                                <DeleteIcon sx={{color: '#1C5D7C'}}/>
                            </IconButton>

                        </div>
                    ),
                }));

                setTableData(array);
            }
        }
    }, [supplierGetData.isSuccess, supplierGetData.data]);

    const tableColumns = [
        {field: "name", headerName: "Name", accessor: "name", textAlign: 'left'},
        {field: "address", headerName: "Address", accessor: "address", textAlign: 'left'},
        {field: "email", headerName: "Email", accessor: "email", textAlign: 'left'},
        {field: "contactNo", headerName: "Contact No", accessor: "contactNo", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['name', 'address'];

    return (
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Manage Supplier</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <CommonButton
                    onClick={() => {
                        setAddModalOpen(true)
                    }}
                    name={'+  Add Supplier'}
                    variant={'Fill'}
                />
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            {isAddModalOpen &&
                <SupplierAddModal
                    open={isAddModalOpen}
                    onClose={() => {
                        setAddModalOpen(false)
                    }}
                />
            }

            {isViewModalOpen &&
                <SupplierAddModal
                    open={isViewModalOpen}
                    onClose={() => {
                        setViewModalOpen(false)
                    }}
                    isView={true}
                    supplierDetails={selectedSupplier}
                />
            }

            <ConfirmAlert
                onClose={() => {
                    setDeleteAlertOpen(false)
                }}
                open={isDeleteAlertOpen}
                buttonText={"Delete"}
                title={'Are you sure'}
                name={selectedSupplier.name}
                subtitle={'You want to delete Supplier ?'} src={patientAdd}
                onclick={() => {
                    handleDelete(selectedSupplier.supplierId)
                }}
            />
        </WideCard>
    );
}

export default ManageSupplier;
