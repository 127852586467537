import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAllTreatment = createAsyncThunk('get-treatment', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/treatment`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const saveTreatment = createAsyncThunk('save-treatment', async (treatmentData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/treatment`, treatmentData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateTreatment = createAsyncThunk('update-treatment', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/treatment/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteTreatment = createAsyncThunk('delete-treatment', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/treatment/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
