import React, {useEffect, useState} from 'react';
import CommonButton from "common/components/Button";
import Box from "@mui/material/Box";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import Avatar from "@mui/material/Avatar";
import {dentistImg,itm} from "config/images";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import ItemAddModal from "components/ItemAddModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteItem, getItems} from "services/item/item";
import ConfirmAlert from "components/ConfirmAlert";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

const tableColumns = [
    {field: "item", headerName: "Item", accessor: "item", textAlign: 'left'},
    {field: "category", headerName: "Category", accessor: "category", textAlign: 'left'},
    {field: "code", headerName: "Code", accessor: "code", textAlign: 'left'},
    {field: "unit", headerName: "Unit", accessor: "unit", textAlign: 'left'},
    {field: "details", headerName: "Details", accessor: "details", textAlign: 'left'},
];

const searchKey = ['item', 'category'];

function Item() {

    const dispatch = useDispatch();
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isViewFormOpen, setIsViewFormOpen] = useState(false);
    const [isAlertOpen, setAlertOpen] = useState(false);
    const [tableData,setTableData] = useState([]);
    const [selectedItem,setSelectedItem] = useState({});
    const itemData = useSelector(state => state.ItemSlice.item);
    const deleteData = useSelector(state => state.DeleteItemSlice.item);
    const [loader,setLoader] = useState(false);

    const closePopup = () => {
        setIsViewFormOpen(false)
        setIsAddFormOpen(false)
    }

    useEffect(() => {
        dispatch(getItems());
    }, []);

    useEffect(() => {
        if(itemData.isSuccess && !itemData.isLoading){
            const data = itemData?.data;
            if(Array.isArray(data)){
                const array = data.map((item)=>(
                    {
                        item: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        margin: '0px',
                                        padding: '0px',
                                        backgroundColor: '#EAF5FC',
                                        marginRight: '5px',
                                        marginTop: '8px',
                                    }}
                                    src={itm}
                                />

                                <div style={{marginTop: '10px'}}>{item.itemName}</div>
                            </div>
                        ),
                        category:item.category.description,
                        code :item.code,
                        unit:item.unit,
                        details: (
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                                <IconButton
                                    onClick={() => {
                                        setSelectedItem(item);
                                        setIsViewFormOpen(true);
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>

                                <IconButton onClick={() => {
                                    setSelectedItem(item);
                                    setAlertOpen(true);
                                }}>
                                    <DeleteIcon sx={{color: '#1C5D7C'}}/>
                                </IconButton>
                            </div>
                        )
                    }
                ));
                setTableData(array);
            }
        }
    }, [itemData.data]);

    useEffect(() => {
        if (loader){
            setLoader(false);
            if (deleteData.isSuccess && !deleteData.isLoading) {
                dispatch(getItems());
                setAlertOpen(false);
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Item Delete Success',
                })
            } else if (!deleteData.isSuccess && !deleteData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Delete Error'
                })
            }
        }
    }, [deleteData.data]);

    return (
        <div>
            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <CommonButton
                    name={'+  Add Item'}
                    onClick={()=>{setIsAddFormOpen(true)}}
                />
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            {
                isViewFormOpen &&
                <ItemAddModal
                    onClose={closePopup}
                    open={isViewFormOpen}
                    isView={true}
                    itemData={selectedItem}
                />
            }
            {
                isAddFormOpen &&
                <ItemAddModal
                    onClose={closePopup}
                    open={isAddFormOpen}
                />
            }

            { isAlertOpen &&
                <ConfirmAlert
                    src={dentistImg}
                    name={selectedItem.itemName}
                    open={isAlertOpen}
                    onClose={()=>{setAlertOpen(false)}}
                    title="Are you sure"
                    subtitle={"You want to Delete Item ?"}
                    buttonText={"Delete"}
                    onclick={() => {
                        setLoader(true);
                        dispatch(deleteItem(selectedItem.itemId));
                    }}
                />
            }
        </div>
    );
}

export default Item;
