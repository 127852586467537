import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { CardContent, IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CommonButton from "common/components/Button";
import MDTable from "common/components/MDTable";
import MDBox from "common/components/MDBox";
import UserAddModal from "components/UserAddModal";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "services/user/user";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import UserDeleteModel from "components/UserDeleteModel";
const tableColumns = [
  { field: "name", headerName: "Name", accessor: "name", textAlign: "left" },
  {
    field: "clinic",
    headerName: "Clinic",
    accessor: "clinic",
    textAlign: "clinic",
  },
  { field: "role", headerName: "Role", accessor: "role", textAlign: "left" },
  {
    field: "address",
    headerName: "Address",
    accessor: "address",
    textAlign: "left",
  },
  { field: "email", headerName: "Email", accessor: "email", textAlign: "left" },
  {
    field: "userName",
    headerName: "User Name",
    accessor: "userName",
    textAlign: "left",
  },
  { field: "nic", headerName: "Nic", accessor: "Nic", textAlign: "left" },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    accessor: "phoneNumber",
    textAlign: "left",
  },
  {
    field: "action",
    headerName: "Action",
    accessor: "action",
    textAlign: "left",
  },
];

const searchKey = ["dName", "specialist"];
export default function UserManage() {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [user, setUser] = useState(null);
  const users = useSelector((state) => state.UserSlice.user);
 const fetchData=()=>{
    dispatch(getUsers());
 }
  useEffect(() => {
    fetchData();
  }, []);
  const handleDelete = (value) => {
    setUser(value);
    setDeleteModel(true);
  };
  const handleClose = () => {
    setDeleteModel(false);
   fetchData();
  };
  useEffect(() => {
    if (users.isSuccess && !users.isLoading) {
      const array = [];
      users.data?.forEach((val) => {
        array.push({
          name: val.name,
          clinic: val?.branch?.name,
          role: val.role,
          email: val.email,
          address: val.address,
          userName: val.userName,
          nic: val.nic,
          phoneNumber: val.telephone,
          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconButton>
                <RemoveRedEyeIcon sx={{ color: "#2D9DE5" }} />
              </IconButton>

              {val.role!="admin" ? <IconButton onClick={() => handleDelete(val)}>
                <DeleteIcon sx={{ color: "#1C5D7C" }} />
              </IconButton>:null}
            </div>
          ),
        });
      });
      setTableData(array);
    }
  }, [users.data]);

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <Typography
          sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "18px" }}
        >
          User Management
        </Typography>

        <Box>
          <Divider sx={{ borderBottom: "1.3px solid black" }} />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <CommonButton
            onClick={() => setModalOpen(true)}
            name={"+  Add New User"}
            variant={"Fill"}
          />
        </Box>
        <MDBox sx={{ marginY: 2 }}>
          <MDTable
            tableData={tableData}
            tableColumns={tableColumns}
            searchKey={searchKey}
            isSearch={true}
          />
        </MDBox>

        {modalOpen && (
          <UserAddModal open={modalOpen} onClose={() => setModalOpen(false)} />
        )}
        {deleteModel && (
          <UserDeleteModel open={deleteModel} handleClose={handleClose} user={user} fetch={fetchData}/>
        )}
      </CardContent>
    </Card>
  );
}
