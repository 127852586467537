import { createSlice } from "@reduxjs/toolkit";
import {getToothHistory} from "services/toothChart/toothChart";

const initialState = {
    toothHistory: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const toothHistorySlice = createSlice({
    name: "toothHistory",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getToothHistory.pending, (state) => {
                state.toothHistory.isLoading = true;
            })
            .addCase(getToothHistory.fulfilled, (state, { payload }) => {
                state.toothHistory.isLoading = false;
                state.toothHistory.isSuccess = true;
                state.toothHistory.data = payload;
            })
            .addCase(getToothHistory.rejected, (state, { payload }) => {
                state.toothHistory.isLoading = false;
                state.toothHistory.isSuccess = false;
                state.toothHistory.errorMessage = payload;
            });
    },
});

export default toothHistorySlice.reducer;
