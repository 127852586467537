import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";
export const getCategory = createAsyncThunk(
  "get-category",
  async (page, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`/category`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveCategory = createAsyncThunk(
  "save-category",
  async (categoryData, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(`/category`, categoryData, {
        headers: { "Content-type": "application/json" },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category-update",
  async ({ id, categoryUpData }, { rejectWithValue }) => {
    try {
      const { data } = await instance.put(`/category/${id}`, categoryUpData, {
        headers: { "Content-type": "application/json" },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "delete-category",
  async (id, { rejectWithValue }) => {
    try {
      const data = await instance.delete(`/category/${id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
