import MDBox from "common/components/MDBox";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";

const TotalBookingView = ({value,label})=>(
    <MDBox sx={{display:'flex', alignItems:'center'}}>
        <div >
            <Avatar sx={{ bgcolor: '#EAF5FC', width: '50px', height: '50px' ,marginRight:1 }}>
                <PersonIcon fontSize="large" sx={{ color: '#2D9DE5' }}/>
            </Avatar>
        </div>
        <div>
            <Typography sx={{fontSize:"25px" , fontWeight:500 ,color:'#1C5E7C',marginBottom:'-8px' }}>
                {value}
            </Typography>
            <div style={{display:'flex',alignItems:'center'}}>
                <Typography sx={{fontSize:"12.5px", color:'#1C5E7C'}}>
                    {label}
                </Typography>
                <IconButton fontSize={'small'} sx={{ color: '#2D9DE5',margin:0 ,padding:0 }}>
                    <KeyboardArrowRightIcon/>
                </IconButton>
            </div>
        </div>
    </MDBox>
);
export default TotalBookingView;

TotalBookingView.propTypes = {
    value: PropTypes.string,
    label:PropTypes.string,
};
