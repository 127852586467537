import React from "react";
import "./styles.css";
import {Dialog} from "@material-ui/core";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export default function ModalComponent({
                                           children,
                                           showModal = false,
                                           setShowModal,
                                           maxWidth,
                                           title
                                       }) {
    const closeModel = () => {
        setShowModal(false)
    };

    return (
        <Dialog
            open={showModal}
            maxWidth={maxWidth}
            onClose={closeModel}
            disableEnforceFocus
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                }}
            >
                <Icon
                    className="model-icon-button"
                    fontSize="medium"
                    onClick={closeModel}
                    sx={{
                        backgroundColor: '#EAF5FC',
                        borderRadius: '15px',
                        color: '#2D9DE5',
                        cursor: 'pointer',
                        margin:'5px'
                    }}
                >
                    close
                </Icon>
            </Box>

            {title && (
                <Box sx={{ display: 'flex', justifyContent: 'left',  }}>
                    <Typography variant="h5">{title}</Typography>
                </Box>
            )}

            {children}

        </Dialog>
    );
}

ModalComponent.propTypes = {
    children: PropTypes.node.isRequired,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    title: PropTypes.string,
};