import {Avatar, Card, Divider, Grid, Typography} from "@mui/material";
import {Box} from "@mui/system";
import MDBox from "common/components/MDBox";
import React, {useEffect, useState} from "react";
import {inventoryDashboardImage3} from "config/images";
import {useDispatch, useSelector} from "react-redux";
import {dailyUsagePending} from "services/stockDashboard/stockDashboard";
import CommonButton from "common/components/Button";

export default function DailyUsageView() {

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const dailyUsage = useSelector((state) => state.DailyUsagePending.daily);

    useEffect(() => {
        dispatch(dailyUsagePending());
    }, []);

    useEffect(()=> {
        if(dailyUsage.isSuccess && !dailyUsage.isLoading){
            const usageDetails = []
            dailyUsage.data?.forEach((val) => {
                usageDetails.push({
                    date: val.date,
                    qty: val.qty,
                })
            })
            setData(usageDetails);
        }
    },[dailyUsage.data]);

    return (
        <Card sx={{padding: 1}}>
            <MDBox sx={{margin: "6px"}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#1C5E7C"}}>
                        Daily Usage Pending
                    </Typography>
                    <CommonButton
                        name={"Pending"}
                        variant={"Fill"}
                        sx={{borderRadius: "25px", padding: "6px", paddingX: 2}}
                    />
                </Box>
                <Box>
                    <Divider
                        sx={{
                            borderBottom: "1.3px solid black",
                            marginTop: "5px",
                            marginBottom: "5px",
                        }}
                    />
                </Box>
            </MDBox>
            <MDBox sx={{overflowY: "scroll", maxHeight: "250px", paddingX: 0.5}}>
                {data.map((val, index) => (
                    <Card key={index} sx={{display: "flex", alignItems: "center", border: "1px solid #EAF5FC", borderRadius: "4px",marginY: 1, marginLeft: 0.2}}>
                        <Grid container spacing={0}>
                            <Grid item xs={4} sm={1} md={1} lg={2}>
                                <Box sx={{display:'flex', justifyContent: 'center'}}>
                                    <Avatar
                                        sx={{
                                            bgcolor: "#FFFFFF",
                                            width: "50px",
                                            height: "50px",
                                            marginBottom: "10px",
                                            marginTop: 1,
                                        }}
                                    >
                                        <img
                                            src={inventoryDashboardImage3}
                                            style={{
                                                width: "50%",
                                                height: "50%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Avatar>
                                </Box>
                            </Grid>
                            <Grid item xs={8} sm={11} md={11} lg={10}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: "17px",
                                            fontWeight: 500,
                                            color: "#1C5D7C",
                                            marginTop: 2.5,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            paddingRight: 1
                                        }}
                                    >
                                        {val.date} <span style={{fontSize: '12.5px', color: '#113d50'}}>{val.qty + " Items"}</span>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                ))}
            </MDBox>
        </Card>
    );
}
