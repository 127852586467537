import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import React, {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import DropDown from "common/components/DropDown/DropDown";
import MDTable from "common/components/MDTable";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches} from "services/branch/branch";
import {getItemsMainStock} from "services/item/item";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import dayjs from "dayjs";
import {postGrn} from "services/grn/grn";
import {generateDate} from "common/utils/CommonFunctions";

function GrnIssueAddModal({open, onClose, isView = false}) {
    const dispatch = useDispatch();
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const itemData = useSelector(state => state.GetItemMainStockSlice.item);
    const grnSvData = useSelector(state => state.SaveGrnSlice.grn);
    const [items, setItems] = useState([]);
    const [branch, setBranch] = useState([])
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [date, setDate] = useState(null);
    const [qty, setQty] = useState('');
    const [cuurentQty, setCurrentQty] = useState('');
    const [qtyError, setQtyError] = useState('');
    const [saveLoader, setSaveLoader] = useState(false)
    const [tableData, setTableData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [isDisable, setDisable] = useState(true);

    const isDataComplete = tableData.length > 0;

    useEffect(() => {
        setDisable(tableData.length > 0);
    }, [tableData])

    const handleAction = () => {
        const grnData = {
            branchId: selectedBranch.branchId,
            grnDetails: tableData.map(row => ({
                itemId: row.itemId,
                qty: row.qty,
            })),
            date: generateDate(date),
        };
        setSaveLoader(true);
        dispatch(postGrn(grnData));
    }

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (grnSvData.isSuccess && !grnSvData.isLoading) {
                onClose();
                sendSuccessNotification({
                    title: "Success",
                    message: "Grn Issue Saved Success",
                });
            } else if (!grnSvData.isSuccess && !grnSvData.isLoading) {
                sendWarningNotification({
                    title: "Warning",
                    message: "Grn Issue Saved Warning",
                });
            }
        }
    }, [grnSvData.data]);

    const clearForm = () => {
        setSelectedItem('');
        setQty('');
        setQtyError('');
        setCurrentQty('');
    };

    const tableColumns = [
        {field: "item", headerName: "Item", accessor: "item", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "qty", headerName: "Location Wise Quantity", accessor: "qty", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['pono', 'status'];

    useEffect(() => {
        dispatch(getAllBranches());
        dispatch(getItemsMainStock());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(data.map((branch) => ({value: branch, label: branch.name})));
            }
        }
    }, [getBranchData.data]);

    useEffect(() => {
        if (itemData.isSuccess && !itemData.isLoading) {
            const data = itemData?.data;
            if (Array.isArray(data)) {
                const array = data.map((item) => (
                    {
                        label: item?.item?.itemName,
                        value: {
                            code: item?.item?.code,
                            itemId: item?.item?.itemId,
                            itemName: item?.item?.itemName,
                            unit: item?.item?.unit,
                            totalQty: item?.totalQty,
                        }
                    }
                ));
                setItems(array);
            }
        }
    }, [itemData.data]);

    const isComplete = selectedItem && selectedBranch && date && qty && !qtyError;

    const handleAddGRN = () => {
        const newData = {
            item: selectedItem.itemName,
            itemId: selectedItem.itemId,
            itemDetails: selectedItem,
            branch: selectedBranch.name,
            date: generateDate(date),
            qty: qty,
            action: (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <CommonButton
                        sx={{width: '100px'}}
                        name={'Update'}
                        circular={true}
                        variant={'Fill'}
                        onClick={() => handleUpdate(newData)}
                    />
                    <div style={{marginLeft: '20px'}}>
                        <IconButton onClick={() => {
                            handleDelete(newData)
                        }}>
                            <DeleteIcon sx={{color: '#1C5D7C'}}/>
                        </IconButton>
                    </div>
                </div>
            ),
        };
        setTableData([...tableData, newData]);
        clearForm()
    };

    const handleUpdate = (rowData) => {
        console.log(rowData)
        setUpdate(true)
        setCurrentQty(rowData?.itemDetails?.totalQty)
        setSelectedItem(rowData.itemDetails);
        setDate(dayjs(rowData.date, "YYYY-MM-DD"));
        setQty(rowData.qty);
    };

    const handleSaveUpdate = () => {
        setUpdate(false)
        const updatedRow = {
            item: selectedItem.itemName,
            branch: selectedBranch.name,
            itemDetails: selectedItem,
            date: generateDate(date),
            qty: qty,
            action: (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <CommonButton
                        sx={{width: '100px'}}
                        name={'Update'}
                        circular={true}
                        variant={'Fill'}
                        onClick={() => handleUpdate(updatedRow)}
                    />
                    <div style={{marginLeft: '20px'}}>
                        <IconButton onClick={() => {
                            handleDelete(updatedRow)
                        }}>
                            <DeleteIcon sx={{color: '#1C5D7C'}}/>
                        </IconButton>
                    </div>
                </div>
            ),

        };
        const index = tableData.findIndex((row) => row.id === updatedRow.id);
        tableData.splice(index, 1, updatedRow);
        clearForm();
    };

    const handleDelete = (rowData) => {
        const updatedTableData = tableData.filter(row => row !== rowData);
        setTableData(updatedTableData);
    };

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin: '10px'}}>
                <Box sx={{marginBottom: '10px'}}>
                    <div>
                        <Typography sx={{
                            color: '#1C5E7C',
                            fontWeight: 450,
                            fontSize: '16px',
                            marginBottom: '25px',
                            position: 'absolute',
                            top: '15px',
                            left: '15px'
                        }}> {'GRN Issue'} </Typography>
                    </div>
                </Box>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '1000px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={8}>
                        <DropDown
                            label={'Branch'}
                            options={branch}
                            value={selectedBranch}
                            onChange={(value) => {
                                setSelectedBranch(value);
                            }}
                            disabled={isDisable}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{width: "100%"}}
                                label="Date"
                                value={date}
                                onChange={(date) => setDate(date)}
                                disabled={isDisable}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <DropDown
                            label={'Item'}
                            options={items}
                            value={selectedItem}
                            onChange={(value) => {
                                setSelectedItem(value);
                                setCurrentQty(value.totalQty)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={4}>
                        <Box>
                            <TextInput
                                label={'Main Stock Quantity'}
                                placeholder={'Main Stock Quantity'}
                                value={cuurentQty}
                                disabled={true}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={4}>
                        <Box>
                            <TextInput
                                placeholder={'Location Wise Quantity'}
                                value={qty}
                                onChange={(e) => {
                                    setQty(e.target.value)
                                    if ((!/^\d+$/.test(e.target.value)) || !(parseInt(e.target.value) < parseInt(cuurentQty))) {
                                        setQtyError("Invalid Qty");
                                    } else {
                                        setQtyError("");
                                    }
                                }}
                                error={qtyError !== ""}
                                variant="outlined"
                            />
                            {qtyError && <div style={{color: 'red', fontSize: '12px'}}>{qtyError}</div>}
                        </Box>
                    </Grid>

                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    {update && (
                        <div style={{margin: '5px'}}>
                            <CommonButton
                                name={'Cancel'}
                                variant={'outline'}
                                onClick={() => {
                                    setUpdate(false);
                                }}
                            />
                        </div>
                    )}

                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={update ? 'Update GRN' : 'Add GRN'}
                            variant={'Fill'}
                            onClick={update ? handleSaveUpdate : handleAddGRN}
                            disabled={!isComplete}
                        />
                    </div>
                </Box>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={false}/>
                </MDBox>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <div style={{margin: '5px'}}>

                        <CommonButton
                            name={'Submit'}
                            variant={'Fill'}
                            onClick={() => {
                                handleAction()
                            }}
                            disabled={!isDataComplete}
                        />
                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

GrnIssueAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView: PropTypes.bool,
};

export default GrnIssueAddModal;
