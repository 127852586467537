import { createSlice } from "@reduxjs/toolkit";
import {getGrn} from "services/grn/grn";

const initialState = {
    grn: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const grnSlice = createSlice({
    name: "grn",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGrn.pending, (state) => {
                state.grn.isLoading = true;
            })
            .addCase(getGrn.fulfilled, (state, { payload }) => {
                state.grn.isLoading = false;
                state.grn.isSuccess = true;
                state.grn.data = payload;
            })
            .addCase(getGrn.rejected, (state, { payload }) => {
                state.grn.isLoading = false;
                state.grn.isSuccess = false;
                state.grn.errorMessage = payload;
            });
    },
});

export default grnSlice.reducer;
