import React, {useEffect, useState} from 'react';
import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Modal";
import Grid from "@mui/material/Grid";
import TextInput from "common/components/TextInput/TextInput";
import Typography from "@mui/material/Typography";
import CommonButton from "common/components/Button";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import DropDown from "common/components/DropDown/DropDown";
import {getAllBranchesWithChairCount} from "services/branch/branch";
import {getUsers, saveUsers} from 'services/user/user';
import {sendSuccessNotification} from "common/components/Notification/SendNotification";

function UserAddModal({open, onClose}) {
    const dispatch = useDispatch();
    const [branch, setBranch] = useState(null);
    const [role, setRole] = useState(null);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [nic, setNic] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [branchOption, setBranchOption] = useState([]);
    const [saveLoader, setSaveLoader] = useState(false);
    const branchesData = useSelector(state => state.BranchWithChairCountSlice.branch);
    const saveUsersData = useSelector(state => state.SaveUserSlice.user);

    const isDataComplete = branch && role && name !== '' && address !== '' && email !== '' && phoneNumber !== '' &&
        nic !== '' && userName !== '' && password !== '' && !emailError && !phoneNumberError;

    const validatePhoneNumber = (e) => {
        setPhoneNumber(e.target.value)
        if (!/^(\+94|0)\d{9}$/.test(e.target.value)) {
            setPhoneNumberError("Invalid Mobile No");
        } else {
            setPhoneNumberError("");
        }
    };

    const validateEmail = (e) => {
        setEmail(e.target.value)
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value)) {
            setEmailError("Invalid Email");
        } else {
            setEmailError("");
        }
    };

    const clear = () => {
        setBranch(null);
        setRole(null);
        setName('');
        setAddress('');
        setEmail('');
        setPhoneNumber('');
        setNic('');
        setUserName('');
        setPassword('')
    }

    useEffect(() => {
        dispatch(getAllBranchesWithChairCount());
    }, []);

    useEffect(() => {
        if (branchesData.isSuccess && !branchesData.isLoading) {
            const array = []
            branchesData.data?.forEach((val)=> {
                array.push({
                    value: val.branchId,
                    label: val.branchName,
                })
            })
            setBranchOption(array)
        }
    }, [branchesData.data]);

    const saveDoctor = () => {
        const userData = {
            name: name,
            address: address,
            email: email,
            telephone: phoneNumber,
            role: role,
            nic: nic,
            userName: userName,
            password: password,
            branchId: branch
        };
        setSaveLoader(true);
        dispatch(saveUsers(userData));
    };

    useEffect(() => {
        if(saveUsersData.isSuccess && !saveUsersData.isLoading && saveLoader){
            dispatch(getUsers());
            sendSuccessNotification({
                title: 'Success',
                message: 'User Saved Success',
            })
            onClose();
        }
    }, [saveUsersData.data]);

    const handleClose = () => {
        onClose();
        clear();
    };

    const roleOption = [
        {value: 'admin', label: 'Admin'},
        {value: 'financial-manager', label: 'Financial Manager'},
        {value: 'stock_manager', label: 'Stock Manager'},
        {value: 'reception', label: 'Reception'},
        {value: 'stock', label: 'Stock'},
    ];

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox open={open} onClose={handleClose} maxWidth="sm" sx={{margin: '15px'}}>

                <Typography sx={{
                    color: '#1C5E7C',
                    fontWeight: 450,
                    fontSize: '19px',
                    marginBottom: '25px',
                    position: 'absolute',
                    top: '15px',
                    left: '15px'
                }}> {"User Add"}</Typography>

                <Grid container spacing={2} sx={{marginTop: '10px'}}>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <DropDown
                            label={'Select Clinic'}
                            options={branchOption}
                            value={branch}
                            onChange={(val)=> setBranch(val)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <DropDown
                            label={'Select Role'}
                            options={roleOption}
                            value={role}
                            onChange={(val)=> setRole(val)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextInput placeholder={'Name'}
                                   value={name}
                                   onChange={(val)=> setName(val.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput placeholder={'Address'}
                                   value={address}
                                   onChange={(val)=> setAddress(val.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput placeholder={'Email'}
                                   value={email}
                                   onChange={ validateEmail}
                                   error={emailError !== ""}
                        />
                        {emailError && <div style={{color: 'red', fontSize: '12px'}}>{emailError}</div>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput placeholder={'Phone Number'}
                                   value={phoneNumber}
                                   onChange={validatePhoneNumber}
                                   error={phoneNumberError !== ""}
                        />
                        {phoneNumberError && <div style={{color: 'red', fontSize: '12px'}}>{phoneNumberError}</div>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput placeholder={'Nic'}
                                   value={nic}
                                   onChange={(val)=> setNic(val.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput placeholder={'User Name'}
                                   value={userName}
                                   onChange={(val)=> setUserName(val.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextInput placeholder={'Password'}
                                   value={password}
                                   type={'password'}
                                   onChange={(val)=> setPassword(val.target.value)}
                        />
                    </Grid>
                </Grid>

                <MDBox sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '15px'}}>
                    <Box marginRight={'10px'}>
                        <CommonButton
                            sx={{width: '90px'}}
                            onClick={handleClose}
                            name={'Cancel'}
                            variant={'outline'}
                        />
                    </Box>
                    <CommonButton onClick={()=>saveDoctor()}
                                  name={'Add User'}
                                  variant={'Fill'}
                                  disabled={!isDataComplete}
                    />
                </MDBox>
            </MDBox>
        </ModalComponent>
    )
}

UserAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
export default UserAddModal;
