import React, { useState } from 'react';
import moment from 'moment';

const TimeSlotPartition = () => {
    const [selectedSlot, setSelectedSlot] = useState(null);

    // Generate time slots using moment.js
    const generateTimeSlots = () => {
        const startTime = moment('09:00', 'HH:mm');
        const endTime = moment('18:00', 'HH:mm'); // Adjusted end time
        const timeSlots = [];

        let currentTime = startTime.clone();

        while (currentTime.isSameOrBefore(endTime)) {
            const slotStartTime = currentTime.format('HH:mm');
            const slotEndTime = currentTime.add(30, 'minutes').format('HH:mm');
            const formattedSlot = `${slotStartTime} - ${slotEndTime}`;

            timeSlots.push(formattedSlot);

        }

        return timeSlots;
    };

    const handleSlotClick = (time) => {
        setSelectedSlot(time);
        // Perform any other actions, such as booking the selected time slot
    };

    const timeSlots = generateTimeSlots();

    return (
        <div>
            <h2>Select a Time Slot</h2>
            <div>
                {timeSlots.map((time) => (
                    <div
                        key={time}
                        className={`time-slot ${selectedSlot === time ? 'selected' : ''}`}
                        onClick={() => handleSlotClick(time)}
                    >
                        {time}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TimeSlotPartition;
