import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import MDBox from "common/components/MDBox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

function DetailContent({label,value}){

    return(
        <Box sx={{display:'flex',justifyContent:'space-between',marginY:'5px'}}>
            <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#2D9DE5',margin:'5px' }}>
                {label}
            </Typography>
            <div style={{display:'flex',alignItems:'center',width:'65%',height:'34px',borderRadius:'4px',backgroundColor:'#EAF5FC'}}>
                <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#1C5D7C',margin:'5px' }}>
                    {value}
                </Typography>
            </div>
        </Box>
    )
}
function AppointmentBookingView(){

    return(
        <div>
            <MDBox>
                <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#100f0f',margin:'5px' }}>
                    Patient Details
                </Typography>
                <Divider sx={{borderBottom: '1.3px solid black', marginTop: '5px', marginBottom: '5px'}}/>
            </MDBox>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <DetailContent label={'Name'} value={'Mr Samantha Lakshan'}/>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <DetailContent label={'Status'} value={'Checking'}/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <DetailContent label={'Mobile No'} value={'0770826701'}/>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <DetailContent label={'Hos Ref'} value={'No ref'}/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Box sx={{display:'flex',justifyContent:'space-between',marginY:'5px'}}>
                        <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#2D9DE5',margin:'5px' }}>
                            Birthday
                        </Typography>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'65%',height:'34px',borderRadius:'4px'}}>
                            <div style={{display:'flex',alignItems:'center',width:'50%',height:'34px',borderRadius:'4px',backgroundColor:'#EAF5FC'}}>
                                <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#1C5D7C',margin:'5px' }}>
                                    2023-01-22
                                </Typography>
                            </div>
                            <div style={{display:'flex'}}>
                                <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#2D9DE5',margin:'5px' }}>
                                    Age
                                </Typography>
                                <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'50px',height:'34px',borderRadius:'4px',backgroundColor:'#EAF5FC',marginLeft:'7px'}}>
                                    <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#1C5D7C',margin:'5px' }}>
                                        25
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default AppointmentBookingView;
