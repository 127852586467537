import React, { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes, {any} from "prop-types";

function DropDown({
                      label,
                      options,
                      defaultValue,
                      onChange,
                      value,
                      disabled= false,
                      ...rest
                  }) {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <FormControl sx={{ width: '100%' }} disabled={disabled}>
            <InputLabel id="demo-simple-select-disabled-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-disabled-label"
                {...rest}
                label={label}
                value={value}
                onChange={handleChange}
                IconComponent={()=>(
                    <IconButton disabled={disabled} onClick={()=> setIsDropdownOpen(!isDropdownOpen)}>
                        <ArrowDropDownIcon />
                    </IconButton>
                )}
                sx={{ backgroundColor: '#F0F3F5B2', borderRadius: '5px' }}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                open={isDropdownOpen}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default DropDown;

DropDown.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(any),
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.any
};
