import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import POStockAddModal from "components/POStockAddModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranchPO} from "services/purchaseOrderBranch/purchaseOrderBranch";

function POStock(){

    const dispatch = useDispatch();
    const [isAddModalOpen,setAddModalOpen] = useState(false);
    const [isViewModalOpen,setViewModalOpen] = useState(false);
    const [purchaseOrders,setPurchaseOrders] = useState([]);
    const [selectedPO,setSelectedPO] = useState({});
    const poData = useSelector(state => state.BranchPOSlice.branchPurchaseOrder);

    const tableColumns = [
        {field: "pono", headerName: "PO No", accessor: "pono", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "approvedDate", headerName: "Approved Date", accessor: "approvedDate", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];
    const searchKey = ['pono', 'date'];

    useEffect(() => {
        dispatch(getAllBranchPO());
    }, []);

    useEffect(() => {
        if(poData.isSuccess && !poData.isLoading){
            const data = poData?.data;
            if(Array.isArray(data)){
                const array = data.map((item)=>(
                    {
                        pono:item.poNo,
                        status:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px',backgroundColor:item.postatusPo === 'approval'? '#2D9DE5' :item.postatusPo === 'approved'? '#1C5D7C' : '' }}
                                    name={item.postatusPo}
                                    circular={true}
                                    variant={item.postatusPo === 'pending'? 'outline': 'Fill'}
                                    onClick={() => {

                                    }}
                                />
                            </div>
                        ),
                        date : item.poDate,
                        approvedDate:item.approvedDate===null? '-' :item.approvedDate,
                        action:(
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <IconButton
                                    onClick={() => {
                                        const poData = {
                                            poId:item.branchPoId,
                                            status:item.postatusPo
                                        }
                                        setSelectedPO(poData);
                                        setViewModalOpen(true);
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>
                            </div>
                        ),

                    }
                ));
                setPurchaseOrders(array)
            }
        }
    }, [poData.data]);

    return(
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Purchase Order</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <CommonButton
                    onClick={()=>{setAddModalOpen(true)}}
                    name={'+  Add Purchase Order'}
                    variant={'Fill'}
                />
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={purchaseOrders} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            {isAddModalOpen &&
                <POStockAddModal
                    open={isAddModalOpen}
                    onClose={()=>{setAddModalOpen(false)}}
                />
            }

            {isViewModalOpen &&
                <POStockAddModal
                    open={isViewModalOpen}
                    onClose={()=>{setViewModalOpen(false)}}
                    isView={true}
                    purchaseDetails={selectedPO}
                />
            }
        </WideCard>
    )
}

export default POStock;
