import { createSlice } from "@reduxjs/toolkit";
import {savePurchaseOrderItem} from "services/purchaseOrderMain";

const initialState = {
    purchaseOrder: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const savePurchaseOrderSlice = createSlice({
    name: "savePO",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(savePurchaseOrderItem.pending, (state) => {
                state.purchaseOrder.isLoading = true;
            })
            .addCase(savePurchaseOrderItem.fulfilled, (state, { payload }) => {
                state.purchaseOrder.isLoading = false;
                state.purchaseOrder.isSuccess = true;
                state.purchaseOrder.data = payload;
            })
            .addCase(savePurchaseOrderItem.rejected, (state, { payload }) => {
                state.purchaseOrder.isLoading = false;
                state.purchaseOrder.isSuccess = false;
                state.purchaseOrder.errorMessage = payload;
            });
    },
});

export default savePurchaseOrderSlice.reducer;
