import { Box, Grid, ToggleButton } from "@mui/material";
import './style.css'
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import styled from 'styled-components'
import {upArrow,downArrow} from "config/images";
import PropTypes from "prop-types";

const SideButton = styled(ToggleButton)(({bgColor})=>({
    backgroundColor: bgColor,
    width: 100,
    height: 100,
    border: 'none',
    '&:hover': {
        backgroundColor: bgColor,
    },
}))

// RCT status color
const rctCansel = {borderColor: '#2D9DE5', color: '#2D9DE5', backgroundColor: '#FEFEFE'}
const rct1 = {borderColor: '#FFBFCD', color: '#FEFEFE', backgroundColor: '#FFBFCD'}
const rct2 = {borderColor: '#00CCFF', color: '#FEFEFE', backgroundColor: '#00CCFF'}
const rct3 = {borderColor: '#FFFF00', color: '#171717', backgroundColor: '#FFFF00'}

// filter coler
const fillingFilterColor = (val) => {
    let color = ''
    switch(val?.fillingType) {
        case 'temporary':
             color = '#fd0000'
            break;
        case 'gic':
            color = '#0000fd'
            break;
        case 'compsite':
            color = '#007f00'
            break;
        case 'amalgam':
            color = '#7f007f'
            break;
        case 'caries':
            color = '#B98989'
            break;
        default:
            color = val?.side === 'occlusal' ? '#D9D9D9' : ''
    }
    return color;
}

const filterColor = (val)=>{
    let color = ''
    switch(val) {
        case 'A1': color = '#DCCEAB'
            break;
        case 'A2': color = '#DCC798'
            break;
        case 'A3': color = '#DAC189'
            break;
        case 'A3.5': color = '#D6B776'
            break;
        case 'A4': color = '#CEAA6E'
            break;
        case 'B1': color = '#DAD0AD'
            break;
        case 'B2': color = '#D8C696'
            break;
        case 'B3': color = '#D6BF7C'
            break;
        case 'B4': color = '#D4B773'
            break;
        case 'C1': color = '#D1BF97'
            break;
        case 'C2': color = '#CCB883'
            break;
        case 'C3': color = '#C9B27C'
            break;
        case 'C4': color = '#C09E5E'
            break;
        case 'D1': color = '#FFFFFF'
            break;
        case 'D2': color = '#D2C39C'
            break;
        case 'D3': color = '#D3BC88'
            break;
        case 'D4': color = '#CCB67C'
            break;
        case 'D5': color = '#FFFFFF'
            break;
        case '-': color = '#FFFFFF'
            break;
        default:
            color =  'white'
    }
    return color;
}

const options = [
    {label:'A1',value:'A1'},{label:'A2',value:'A2'},{label:'A3',value:'A3'},{label:'A3.5',value:'A3.5'},{label:'A4',value:'A4'},
    {label:'B1',value:'B1'},{label:'B2',value:'B2'},{label:'B3',value:'B3'},{label:'B4',value:'B4'},
    {label:'C1',value:'C1'},{label:'C2',value:'C2'},{label:'C3',value:'C3'},{label:'C4',value:'C4'},
    {label:'D1',value:'D1'},{label:'D2',value:'D2'},{label:'D3',value:'D3'},{label:'D4',value:'D4'},{label:'D5',value:'D5'},{label:'-',value:'-'}

]
export default function ToothSelect({toothData,handleClick, historyOpen,disable}) {

    return (
        <Box>
            <Box sx={{
                marginX: 1,
                position: 'relative',
                border: 2,
                borderTopLeftRadius: 9,
                borderTopRightRadius: 9,
                borderColor: '#2d9ce4',
                borderBottom: 0,
                paddingY: 0,
                paddingX: 0.7,
                backgroundColor:toothData?.crownBridgeDto?.bridge !== null ? '#1C5D7C' : toothData?.crownBridgeDto?.crown !== null? '#2D9DE5' : 'white',
            }}>
                <select
                    style={{backgroundColor:filterColor(toothData?.crownBridgeDto?.bridge !== null ? toothData?.crownBridgeDto?.bridge : toothData?.crownBridgeDto?.crown !== null? toothData?.crownBridgeDto?.crown : '')}}
                    disabled={!disable}
                    className="select"
                    value={toothData?.crownBridgeDto?.bridge !== null ? toothData?.crownBridgeDto?.bridge : toothData?.crownBridgeDto?.crown !== null? toothData?.crownBridgeDto?.crown : 'A1'}
                    onChange={(val)=>{
                        handleClick("crown-bridge",{type:val.target.value,id:toothData?.crownBridgeDto.id})
                    }}
                >
                    {options.map((val)=>(<option value={val.value}>{val.label}</option>))}
                </select>
            </Box>
            <Box sx={{
                marginX: 1,
                position: 'relative',
                border: 2,
                borderBottomLeftRadius: 9,
                borderBottomRightRadius: 9,
                borderColor: '#2d9ce4',
                width: 100,
                height: 100,
                overflow: 'hidden'}}>
                <Box onDoubleClick={()=> historyOpen(toothData?.toothId)} onClick={()=>{handleClick("update-primary",toothData);}} className={'img'} sx={{backgroundColor: fillingFilterColor(toothData?.fillingDtoGets?.occlusal), cursor: 'pointer'}}>
                    {toothData?.missingDto?.status === 1 &&
                        <img className={'imgTooch'} src={toothData?.img} alt="tooth"/>
                    }
                    {toothData?.directionDto?.directionType !== 0 &&
                        <Box sx={{height:'15px',width:'15px',position:'absolute',bottom:'10px',right:'1px'}}>
                            <img className={'imgTooch'} src={toothData?.directionDto?.directionType === 1? upArrow : downArrow} alt="direction"/>
                        </Box>
                    }
                </Box>.
                <Box className={'box-main'} sx={{width: 200, position: 'relative', marginLeft: -6.5, marginTop: -10.5}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <SideButton onClick={()=>{handleClick("update-filling",{fillingId:toothData?.fillingDtoGets.buccal.fillingId});}} bgColor={fillingFilterColor(toothData?.fillingDtoGets?.buccal)}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SideButton onClick={()=>{handleClick("update-filling",{fillingId:toothData?.fillingDtoGets.mesial.fillingId});}} bgColor={fillingFilterColor(toothData?.fillingDtoGets?.mesial)}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SideButton onClick={()=>{handleClick("update-filling",{fillingId:toothData?.fillingDtoGets.distal.fillingId});}} bgColor={fillingFilterColor(toothData?.fillingDtoGets?.distal)}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SideButton onClick={()=>{handleClick("update-filling",{fillingId:toothData?.fillingDtoGets.lingual.fillingId});}} bgColor={fillingFilterColor(toothData?.fillingDtoGets?.lingual)}>
                                {toothData?.rctDto?.primaryStatus === 1 &&
                                    <Typography
                                        sx={{fontSize: 10}}
                                        variant="caption"
                                        display="block"
                                        className={'letter letter-s'}
                                    >
                                        Primary
                                    </Typography>
                                }
                            </SideButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{margin: 1, position: 'relative', display: 'flex', justifyContent: 'center'}}>
                <Avatar
                    sx={[{border: 2, cursor: 'pointer',},
                        toothData?.rctDto?.rctRound === 0 && {...rctCansel},
                        toothData?.rctDto?.rctRound === 1 && {...rct1},
                        toothData?.rctDto?.rctRound === 2 && {...rct2},
                        toothData?.rctDto?.rctRound === 3 && {...rct3},
                    ]}
                    onClick={()=>{
                        handleClick("rct-update",{rctId:toothData?.rctDto.rctId,rctRound:toothData.rctDto.rctRound});
                    }}
                >
                    {toothData?.toothId}
                </Avatar>
            </Box>
        </Box>
    )
}

ToothSelect.propTypes = {
    toothData:PropTypes.object,
    handleClick:PropTypes.func,
    historyOpen:PropTypes.func,
    disable:PropTypes.bool
};
