import {createSlice} from "@reduxjs/toolkit";
import {deleteDoctor} from "services/doctor/doctor";

const initialState = {
    doctor: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const deleteDoctorSlice = createSlice({
    name: "deleteDoctor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteDoctor.pending, (state) => {
                state.doctor.isLoading = true;
            })
            .addCase(deleteDoctor.fulfilled, (state, {payload}) => {
                state.doctor.isLoading = false;
                state.doctor.isSuccess = true;
                state.doctor.data = payload;
            })
            .addCase(deleteDoctor.rejected, (state, {payload}) => {
                state.doctor.isLoading = false;
                state.doctor.isSuccess = false;
                state.doctor.errorMessage = payload;
            });
    },
});

export default deleteDoctorSlice.reducer;
