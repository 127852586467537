import {Tooth_27, Tooth_25, Tooth_24, Tooth_23, Tooth_22, Tooth_21, Tooth_26, Tooth_31,
    Tooth_16, Tooth_32, Tooth_28, Tooth_13, Tooth_12, Tooth_14, Tooth_15, Tooth_18, Tooth_17,
    Tooth_41, Tooth_42, Tooth_11, Tooth_33, Tooth_34, Tooth_38, Tooth_43, Tooth_44, Tooth_47,
    Tooth_48, Tooth_46, Tooth_35, Tooth_36, Tooth_37, Tooth_45} from 'config/images';

const toothDetails = [
    {
        toothId: 18,
        img: Tooth_18
    },
    {
        toothId: 17,
        img: Tooth_17
    },
    {
        toothId: 16,
        img: Tooth_16
    },
    {
        toothId: 15,
        img: Tooth_15
    },
    {
        toothId: 14,
        img: Tooth_14
    },
    {
        toothId: 13,
        img: Tooth_13
    },
    {
        toothId: 12,
        img: Tooth_12
    },
    {
        toothId: 11,
        img: Tooth_11
    },
    {
        toothId: 21,
        img: Tooth_21
    },
    {
        toothId: 22,
        img: Tooth_22
    },
    {
        toothId: 23,
        img: Tooth_23
    },
    {
        toothId: 24,
        img: Tooth_24
    },
    {
        toothId: 25,
        img: Tooth_25
    },
    {
        toothId: 26,
        img: Tooth_26
    },
    {
        toothId: 27,
        img: Tooth_27
    },
    {
        toothId: 28,
        img: Tooth_28
    },
    {
        toothId: 48,
        img: Tooth_48
    },
    {
        toothId: 47,
        img: Tooth_47
    },
    {
        toothId: 46,
        img: Tooth_46
    },
    {
        toothId: 45,
        img: Tooth_45
    },
    {
        toothId: 44,
        img: Tooth_44
    },
    {
        toothId: 43,
        img: Tooth_43
    },
    {
        toothId: 42,
        img: Tooth_42
    },
    {
        toothId: 41,
        img: Tooth_41
    },
    {
        toothId: 31,
        img: Tooth_31
    },
    {
        toothId: 32,
        img: Tooth_32
    },
    {
        toothId: 33,
        img: Tooth_33
    },
    {
        toothId: 34,
        img: Tooth_34
    },
    {
        toothId: 35,
        img: Tooth_35
    },
    {
        toothId: 36,
        img: Tooth_36
    },
    {
        toothId: 37,
        img: Tooth_37
    },
    {
        toothId: 38,
        img: Tooth_38
    },
];

export default toothDetails
