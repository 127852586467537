import dayjs from "dayjs";
import { getBaseUrl, getFrontendBaseUrl } from 'services/apiBaseUrls'

export const getMainUrl = () => {
    return getFrontendBaseUrl();
}

export const onImageEdit = async (imgUrl) => {
    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const file = new File([blob], "profileImage.jpg", {
        type: blob.type,
    });
    return file;
}

export const generateDate = (date) => {
    return dayjs(date).format('YYYY-MM-DD')
}

export const generateTime = (time) => {
    return dayjs(time).format('HH:mm');
};

export function numberFormat(val){
    const value = val && val.toString().replace(/[^0-9.-]+/g, '');
    let nStr = value + '';
    return nStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getImageUrl = (imageName) => {
    const Url = getBaseUrl()+"/uploads/";
    return Url + imageName;
};

export const size = [
    {
        label: 'Ex-small',
        value: "Ex-small",
    },
    {
        label: "Small",
        value: "Small",
    },
    {
        label: "Medium",
        value: "Medium",
    },
    {
        label: "Large",
        value: "Large",
    },
    {
        label: "Ex-large",
        value: "Ex-large",
    },
    {
        label: "Straight ",
        value: "Straight ",
    },
    {
        label: "Curved",
        value: "Curved",
    },
    {
        label: "Anterior",
        value: "Anterior",
    },
    {
        label: "Premolar",
        value: "Premolar",
    },
    {
        label: "Molar",
        value: "Molar",
    },
    {
        label: "15",
        value: "15",
    },
    {
        label: "20",
        value: "20",
    },
    {
        label: "25",
        value: "25",
    },
    {
        label: "30",
        value: "30",
    },
    {
        label: "35",
        value: "35",
    },
    {
        label: "40",
        value: "40",
    },
    {
        label: "45",
        value: "45",
    },
    {
        label: "50",
        value: "50",
    },
    {
        label: "55",
        value: "55",
    },
    {
        label: "60",
        value: "60",
    },
    {
        label: "70",
        value: "70",
    },
    {
        label: "80",
        value: "80",
    },
    {
        label: "F1",
        value: "F1",
    },
    {
        label: "F2",
        value: "F2",
    },
    {
        label: "F3",
        value: "F3",
    },
    {
        label: "21mm",
        value: "21mm",
    },
    {
        label: "25mm",
        value: "25mm",
    },
    {
        label: "28mm",
        value: "28mm",
    },
    {
        label: "31mm",
        value: "31mm",
    },
]

