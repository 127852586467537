import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "common/components/Sidenav";
import theme from "assets/theme";
import routes from "common/navigation/routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import './style.css'
import DashboardLayout from "common/layoutContainers/DashboardLayout";
import DashboardNavbar from "common/components/DashboardNavbar";
import Box from "@mui/material/Box";
import Footer from "common/components/Footer";
import { pageLoading } from "config/images"
import PageLayout from "common/layoutContainers/PageLayout";
import SignUp from "pages/sign-up";
import SignIn from "pages/sign-in";
import { useDispatch, useSelector } from "react-redux";
import {getAuth} from "services/auth/auth";
import {getItem} from "common/utils/Storage/Storage";
import userRoleConfig from "config/userRoleConfig";

export default function App() {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [authentication, setAuthentication] = useState(true);

  const auth = useSelector((state) => state.AuthSlice.auth);

  useEffect(() => {
      const login = getItem("my-dentist-token");
      if (login) {
          setAuthentication(true);
          dispatch(getAuth());
      } else {
          setAuthentication(false);
          setTimeout(function () { setLoading(false) }, 2000);
      }

  }, []);

  useEffect(()=> {
      if(auth.isSuccess && auth.data){
          setLoading(false)
      }else if(auth.errorMessage === "Request failed with status code 401" || auth.errorMessage === "TOKEN_INVALID"){
          setAuthentication(false);
          setLoading(false)
      }
  },[auth])

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {loading ? (
            <div className="loading-container">
              <img className="loader" src={pageLoading} alt="loading" />
              <h4 className="">Loading...</h4>
            </div>
        ) : (
            <Main dashboard={authentication} />
        )}
      </ThemeProvider>
  )
}

function Main({ dashboard }) {

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

    const auth = useSelector((state) => state.AuthSlice.auth);

  const getRoutes = (allRoutes) =>
      allRoutes.map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }
          // console.log(route)
        if (route.route) {
          return userRoleConfig(route.userRole,auth?.data?.role) &&
              <Route exact path={route.route} element={route.component} key={route.key} />;
        }else {
            return route?.subRoute?.map((sub) =>
                userRoleConfig(sub.userRole,auth?.data?.role) && <Route exact path={sub.route} element={sub.component} key={sub.key} />
            )
        }
      });

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
      <div>
        {dashboard ? (
            <div>
              <Sidenav
                  color={sidenavColor}
                  brand={"Dashboard"}
                  brandName="My Dentist"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
              />
              <DashboardLayout>
                <DashboardNavbar/>
                <Box mt={2} mb={2}>
                  <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                  </Routes>
                </Box>
                <Footer />
              </DashboardLayout>
            </div>
        ) : (
            <PageLayout>
              <Routes>
                <Route path="*" element={<Navigate to="/sign-in" />} />
                <Route exact path={"/sign-up"} element={<SignUp/>} key={'sign-up'}/>
                <Route exact path={"/sign-in"} element={<SignIn/>} key={'sign-in'}/>
              </Routes>
            </PageLayout>
        )}
      </div>
  )
}
