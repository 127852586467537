import { createSlice } from "@reduxjs/toolkit";
import {getAllGrn} from "services/grn/grn";

const initialState = {
    grn: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const getAllGrnSlice = createSlice({
    name: "get-all-grn",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllGrn.pending, (state) => {
                state.grn.isLoading = true;
            })
            .addCase(getAllGrn.fulfilled, (state, { payload }) => {
                state.grn.isLoading = false;
                state.grn.isSuccess = true;
                state.grn.data = payload;
            })
            .addCase(getAllGrn.rejected, (state, { payload }) => {
                state.grn.isLoading = false;
                state.grn.isSuccess = false;
                state.grn.errorMessage = payload;
            });
    },
});

export default getAllGrnSlice.reducer;
