import React, {useEffect, useState} from 'react';
import TableComponent from "common/components/TableComponent";
import MobileList from "common/components/MobileList";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

function MDTable({tableData, tableColumns, searchKey, isSearch=false}) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [windowDimensions]);

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {width, height};
    }

    return (
        <Box>
            {windowDimensions.width > 625 ? (
                <TableComponent data={tableData} columns={tableColumns} isSearch={isSearch}/>
            ) : (
                <MobileList columns={tableColumns} dataJson={tableData} searchKey={searchKey} isSearch={isSearch}/>
            )}
        </Box>
    );
}

MDTable.propTypes = {
    tableData: PropTypes.array.isRequired,
    tableColumns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            headerName: PropTypes.string.isRequired,
        })
    ).isRequired,
    searchKey: PropTypes.array.isRequired,
    isSearch:PropTypes.bool,
};
export default MDTable;
