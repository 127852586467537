import MDTable from "common/components/MDTable";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Avatar from "@mui/material/Avatar";
import MDButton from "common/components/MDButton";
import {useState} from "react";
import PrintBillModal from "components/PrintBillModal";
import CommonButton from "common/components/Button";
import DropDown from "common/components/DropDown/DropDown";
import TextInput from "common/components/TextInput/TextInput";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SearchIcon from '@mui/icons-material/Search';
import MDBox from "common/components/MDBox";
import {sendWarningNotification , sendDangerNotification, sendSuccessNotification} from "common/components/Notification/SendNotification";
import Toggle from "common/components/Toggle/Toggle";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ConfirmAlert from "components/ConfirmAlert";
import FileUploadButton from "common/components/FileUploadButton";
import WideCard from "common/components/WideCard";
import CommonCheckBox from "common/components/CheckBox";
import TimeSlotPartition from "components/samplecomponent";

export default function Sample() {
    const toggle = [
        {
            view: <DescriptionOutlinedIcon/>,
            value: "track",
            text: "Track",
        },
        {
            view: <DescriptionOutlinedIcon/>,
            value: "rma",
            text: "RMA",
        },
        {
            view: <DescriptionOutlinedIcon/>,
            value: "aod",
            text: "AOD",
        },
    ];

    const toggleData = [
        {
            view: 'Online Bookings',
            value: "booking",
        },
        {
            view: "Patient Rechecking Approval",
            value: "rechecking",
        },
    ];

    const [alignment, setAlignment] = useState("track");
    const [onAlignment, setOnAlignment] = useState("booking");
    const [selectedValue,setSelectedValue] = useState('');
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const handleOnAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setOnAlignment(newAlignment);
        }
    };

    const openAddForm = () => {
        setIsAddFormOpen(true);
    };

    const closeForm = () => {
        setIsAddFormOpen(false);
    };

    const openAlert = () => {
        setIsAlertOpen(true);
    };

    const closeAlert = () => {
        setIsAlertOpen(false);
    };

    const optionsArray = [
        {value:"One" , label:"One"},
        {value:"Two" , label:"Two"},
        {value:"Three" , label:"Three"}
    ]

    const tableData = [
        {
            id: 1,
            pName: (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Avatar sx={{backgroundColor: '#EAF5FC', marginRight: '5px'}}>
                        <IconButton sx={{color: '#2D9DE5'}}>
                            <PersonIcon fontSize={"large"}/>
                        </IconButton>
                    </Avatar>

                    <div style={{marginTop: '10px'}}>
                        Chamikara ruchiranga
                    </div>

                < /div>
            ),
            name: 'Chamikara ruchiranga',
            age: '35',
            mobileNum: "0717071121",
            date: "2023-11-22",
            time: '22:30',
            details: (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button variant="outlined" sx={{
                        color: '#2D9DE5',
                        borderRadius: '20px',
                        borderColor: '#2D9DE5',
                        margin: "0px",
                        '&:hover': {borderColor: '#2D9DE5',},
                        '&:focus': {borderColor: '#2D9DE5',},
                    }}>
                        View
                    </Button>
                    <IconButton>
                        <MoreHorizIcon/>
                    </IconButton>
                </div>

            )
        },

        {
            id: 1,
            pName: (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Avatar sx={{backgroundColor: '#EAF5FC', marginRight: '5px'}}>
                        <IconButton sx={{color: '#2D9DE5'}}>
                            <PersonIcon fontSize={"large"}/>
                        </IconButton>
                    </Avatar>

                    <div style={{marginTop: '10px'}}>
                        trishan priya sachin
                    </div>

                < /div>
            ),
            name: 'trishan priya sachin',
            age: '22',
            mobileNum: "0768302461",
            date: "2023-11-22",
            time: '12.30',
            details: (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button variant="outlined" sx={{
                        color: '#2D9DE5',
                        borderRadius: '20px',
                        borderColor: '#2D9DE5',
                        margin: "0px",
                        '&:hover': {borderColor: '#2D9DE5',},
                        '&:focus': {borderColor: '#2D9DE5',},
                    }}>
                        View
                    </Button>
                    <IconButton>
                        <MoreHorizIcon/>
                    </IconButton>
                </div>

            )
        },
    ];

    const tableColumns = [
        {field: "pName", headerName: "Patient Name", accessor: "pName", textAlign: 'left'},
        {field: "age", headerName: "Age", accessor: "age", textAlign: 'left'},
        {field: "mobileNum", headerName: "Mobile Number", accessor: "mobileNum", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "time", headerName: "Time", accessor: "time", textAlign: 'left'},
        {field: "details", headerName: "Details", accessor: "details", textAlign: 'left'},
    ];

    const searchKey = ['pName', 'age'];

    return(
        <div>
            <MDBox sx={{marginY: 2}}>
                <CommonButton
                    name={'Book Now'}
                    circular={true}
                    variant={'Fill'}
                    onClick={() => {
                        console.log("Clicked")
                    }}
                />
                <CommonButton
                    name={'View'}
                    circular={true}
                    variant={'outline'}
                />
                <CommonButton
                    name={'Show Booking'}
                    variant={'Fill'}
                />
                <CommonButton
                    name={'Update'}
                    variant={'outline'}
                />
            </MDBox>

            <MDBox sx={{marginY: 2}}>
                <DropDown
                    label={'Select'}
                    options={optionsArray}
                    value={selectedValue}
                    onChange={(value) => {
                        setSelectedValue(value);
                    }}
                />
            </MDBox>

            <MDBox sx={{marginY: 2}}>
                <TextInput placeholder={'Name'}/>
                <TextInput
                    placeholder={'Telephone No'}
                    startIcon={<AccountBoxIcon/>}
                />
                <TextInput
                    placeholder={'Search'}
                    endIcon={<SearchIcon/>}
                />
            </MDBox>
            <MDBox sx={{marginY: 2}}>
                <MDTable
                    tableData={tableData}
                    tableColumns={tableColumns}
                    searchKey={searchKey}
                    isSearch={true}
                />
            </MDBox>

            <MDButton variant="contained" onClick={openAddForm} sx={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',}}>
                + add
            </MDButton>

            <PrintBillModal
                open={isAddFormOpen}
                onClose={closeForm}
            />

            <MDButton variant="contained" onClick={openAlert} sx={{boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',}}>
                + save
            </MDButton>
            <ConfirmAlert
                open={isAlertOpen}
                onClose={closeAlert}
                title="Are you sure"
                subtitle="you want to inactive doctor ?"
            />

            <MDBox sx={{marginY: 2}}>
                <Button onClick={() => {
                    sendSuccessNotification({
                        title: 'Success',
                        message: 'Saved Success',
                    })

            }}> Success Notification</Button>

            <Button onClick={()=>{
                sendDangerNotification({
                    title: 'Error',
                    message: 'Saved Error',
                })

            }}> Error Notification</Button>

            <Button onClick={()=>{
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Saved Success',
                })

                }}> Error Notification</Button>
            </MDBox>
            <MDBox sx={{marginY: 2}}>
                <FileUploadButton onFileSelect={()=>{}}/>
            </MDBox>

            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Toggle data={toggle} value={alignment} onChange={handleAlignment}/>
            </div>
            <MDBox sx={{marginY: 2}}>
                <Toggle data={toggleData} value={onAlignment} onChange={handleOnAlignment}/>
            </MDBox>

            <WideCard  >
                <div style={{border:'2px solid red' , height:'200px', width:'100%'}}></div>
            </WideCard>

            <MDBox sx={{marginY:2}}>
                <CommonCheckBox label={'Healthy'} onChange={()=>{}}/>
                <CommonCheckBox />
                <CommonCheckBox variant={'Fill'} label={'Healthy'} />
                <CommonCheckBox variant={'Fill'}/>
            </MDBox>

            <TimeSlotPartition/>
        </div>
    )
}
