import React, {useEffect, useState} from 'react';
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {useDispatch, useSelector} from "react-redux";
import {getAllProcedures} from "services/procedure/procedure";
import {
    deleteBookingDetails,
    getAllBookingDetailsBookingWise,
    saveBookingDetails
} from "services/BookingDetails/BookingDetails";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {tooth} from "config/images";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmAlert from "components/ConfirmAlert";
import DropDown from "common/components/DropDown/DropDown";
import {getAllBranches} from "services/branch/branch";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const tableColumns = [
    {field: "procedure", headerName: "Procedure", accessor: "procedure", textAlign: 'left'},
    {field: "type", headerName: "Type", accessor: "type", textAlign: 'left'},
    {field: "total", headerName: "Total", accessor: "total", textAlign: 'left'},
    {field: "action", headerName: "", accessor: "action", textAlign: 'center'},
];

const searchKey = ['procedure', 'type'];

function SelectProcedure({changePage,bookingId}) {
    const dispatch = useDispatch();
    const getProcedureData = useSelector(state => state.ProcedureSlice.procedure);
    const getBookingDetails = useSelector(state => state.BookingDetailsSlice.booking);
    const postBookingDetails = useSelector(state => state.SaveBookingDetailsSlice.booking);
    const deletedBookingDetails = useSelector(state => state.DltBookingDetailsSlice.booking);
    const authData = useSelector(state => state.AuthSlice.auth);
    const [selectedProcedure, setSelectedProcedure] = useState(null);
    const [fees, setFees] = useState('');
    const [labCost, setLabCost] = useState('');
    const [extraCons, setExtraCons] = useState('');
    const [totalCharge, setTotalCharge] = useState('');
    const [type, setType] = useState('');
    const [tableData, setTableData] = useState([]);
    const [saveLoader, setSaveLoader] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState();
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [option, setOption] = useState(false);
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const [branch, setBranch] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({});
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (authData.data.role === 'admin') {
            dispatch(getAllBranches());
            setDisable(false)
        } else {
            dispatch(getAllProcedures(authData.data.branchId));
            setDisable(true);
        }

        dispatch(getAllBookingDetailsBookingWise(bookingId))
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(data.map((branch) => ({value: branch, label: branch.name})));
                const defaultBranch = data.find(branch => branch.branchId === 1);
                if (defaultBranch) {
                    setSelectedBranch({value: defaultBranch, label: defaultBranch.name});
                    dispatch(getAllProcedures(defaultBranch.branchId));
                    setSelectedBranch(defaultBranch)
                }
            }
        }
    }, [getBranchData.data]);

    const isDataComplete = selectedProcedure;

    const openDltAlert = () => {
        setAlertOpen(true);
    };

    const closeForm = () => {
        setAlertOpen(false);
    }

    const handleProcedureChange = (event, value) => {
        if(event.type === "click"){
            setSelectedProcedure(value);
            if (value){
                setFees(value?.value.fees || '');
                setLabCost(value?.value.labCost || '');
                setExtraCons(value?.value.extraCons || '');
                setTotalCharge(value?.value.totalCharge || '');
                setType(value?.value.type || '');
            }
        }
    };

    const handleAddButtonClick = () => {

        const newData = {
            procedureId: selectedProcedure?.value?.procedureId,
            bookingId: bookingId,
        };
        setSaveLoader(true);
        dispatch(saveBookingDetails(newData));

        setSelectedProcedure(null);
        setFees('');
        setLabCost('');
        setExtraCons('');
        setTotalCharge('');
        setType('');
    };
    useEffect(() => {
        if (getProcedureData.isSuccess) {
            const data = getProcedureData?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => ({
                    label: val.procedureName,
                    value: val
                }));
                setOption(array || []);
            }
        }

    }, [getProcedureData.data]);

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (postBookingDetails.isSuccess && !postBookingDetails.isLoading) {
                dispatch(getAllBookingDetailsBookingWise(bookingId));
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Procedure Saved Success',
                })

            } else if (!postBookingDetails.isSuccess && !postBookingDetails.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Procedure Saved Warning'
                })
            }
        }
    }, [postBookingDetails.data]);

    useEffect(() => {
        if (getBookingDetails.isSuccess) {
            const data = getBookingDetails?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => ({
                    id: val.bookingDetailsId,
                    procedure: val?.procedureDto?.procedureName,
                    type: val?.procedureDto?.type,
                    total: val?.procedureDto?.totalCharge,
                    action: (
                        <IconButton onClick={() => {
                            setSelectedDetail(val);
                            openDltAlert();
                        }}>
                            <DeleteIcon sx={{color: '#1C5D7C'}}/>
                        </IconButton>
                    )
                }));
                setTableData(array);
            }
        }
    }, [getBookingDetails.isSuccess, getBookingDetails.data]);

    function handleDelete(id) {
        setDeleteLoader(true)
        dispatch(deleteBookingDetails(id));
    }

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false)
            if (deletedBookingDetails.isSuccess) {
                dispatch(getAllBookingDetailsBookingWise(bookingId))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Procedure Deleted Success',
                })
                setAlertOpen(false);
            } else if (!deletedBookingDetails.isSuccess && !deletedBookingDetails.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Procedure Deleted Warning'
                })
            }
        }
    }, [deletedBookingDetails.data]);

    return (
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Select Procedure</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Grid container spacing={2}>
                {!disable &&
                    <Grid item xs={12} sm={6} md={7} lg={7}>
                        <DropDown
                            label={'Clinic'}
                            options={branch}
                            value={selectedBranch}
                            disabled={disable}
                            onChange={(value) => {
                                setSelectedBranch(value);
                                dispatch(getAllProcedures(value.branchId));
                            }}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={7} lg={7}>
                    <Autocomplete
                        id="Procedure"
                        freeSolo
                        value={selectedProcedure=== null?'':selectedProcedure}
                        onChange={handleProcedureChange}
                        options={option}
                        renderInput={(params) => <TextField {...params} label="Procedure" size={'small'}/>}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                            }
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={5} lg={5}>
                    <div style={{width: '100%', height: '40px', backgroundColor: '#EAF5FC', borderRadius: '5px'}}>
                        {type ? (
                            <Typography sx={{
                                fontSize: '15px',
                                position: 'relative',
                                top: '8px',
                                left: '8px',
                                color: '#2D9DE5'
                            }}>{` ${type}`}</Typography>
                        ) : (
                            <Typography sx={{
                                fontSize: '15px',
                                position: 'relative',
                                top: '8px',
                                left: '8px',
                                color: '#2D9DE5'
                            }}>Type</Typography>
                        )}
                    </div>
                </Grid>

                <Grid item xs={12} sm={6} md={2.33} lg={2.33}>
                    <TextInput
                        placeholder={"Fees"}
                        value={fees}
                        onChange={(e) => setFees(e.target.value)}
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2.33} lg={2.33}>
                    <TextInput
                        placeholder={"Lab Cost"}
                        value={labCost}
                        onChange={(e) => setLabCost(e.target.value)}
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2.33} lg={2.33}>
                    <TextInput
                        placeholder={"Extra Cons"}
                        value={extraCons}
                        onChange={(e) => setExtraCons(e.target.value)}
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={5} lg={5}>
                    <div style={{width: '100%', height: '40px', backgroundColor: '#EAF5FC', borderRadius: '5px'}}>
                        {totalCharge ? (
                            <Typography sx={{
                                fontSize: '15px',
                                position: 'relative',
                                top: '8px',
                                left: '8px',
                                color: '#2D9DE5'
                            }}>{` ${totalCharge}`}</Typography>
                        ) : (
                            <Typography sx={{
                                fontSize: '15px',
                                position: 'relative',
                                top: '8px',
                                left: '8px',
                                color: '#2D9DE5'
                            }}>Total Charge</Typography>
                        )}

                    </div>
                </Grid>
            </Grid>

            <Box sx={{display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
                <CommonButton
                    name={'+  Add  '}
                    variant={'Fill'}
                    onClick={handleAddButtonClick}
                    disabled={!isDataComplete}
                />
            </Box>

            <MDBox sx={{marginY: 2}}>
                <MDTable
                    tableData={tableData}
                    tableColumns={tableColumns}
                    searchKey={searchKey}

                />
            </MDBox>

            <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                <IconButton aria-label="back">
                    <ArrowBackIosNewIcon sx={{ color: '#2D9DE5' }} onClick={()=>{
                        changePage('checking-list');
                    }} />
                </IconButton>
                <CommonButton
                    name={'Next'}
                    variant={'Fill'}
                    onClick={()=>{
                        changePage('tooth-chart');
                    }}
                />

            </Box>

            <ConfirmAlert onClose={closeForm} open={alertOpen} buttonText={"Delete"} title={'Are you sure'}
                          subtitle={'you want to delete procedure ?'} src={tooth} onclick={() => {
                handleDelete(selectedDetail.bookingDetailsId)
            }}/>

        </WideCard>
    );
}

export default SelectProcedure;
