import React from 'react';
import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import CommonButton from "common/components/Button";

function PaymentDetailsViewModal({open, onClose,details}) {

    const handleClose = () => {
        onClose();
    };

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox open={open} onClose={handleClose} maxWidth="sm" sx={{margin: '15px'}}>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '1000px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <div>
                    <Typography sx={{
                        color: '#1C5E7C',
                        fontWeight: 450,
                        fontSize: '16px',
                        marginBottom: '25px',
                        position: 'absolute',
                        top: '15px',
                        left: '15px'
                    }}> {details.action === 'booking' ? 'Booking Details' : 'Discount Details'} </Typography>
                </div>

                <Grid container
                      marginTop={'20px'}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        {details.value.map((val)=>(
                            <Typography marginY={'10px'} sx={{color: '#999999', fontSize: '14px'}}>{val.label}</Typography>
                        ))}
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        {details.value.map((val)=>(
                            <Typography marginY={'10px'} sx={{color: '#1C5D7C', fontSize: '14px'}}>{val.value}</Typography>
                        ))}
                    </Grid>
                </Grid>

                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black'}}/>
                </Box>
                <Box sx={{display:'flex', justifyContent:'end'}}>
                    <CommonButton
                        name={'Close'}
                        variant={"Fill"}
                        onClick={handleClose}
                    />
                </Box>

            </MDBox>
        </ModalComponent>
    );
}

PaymentDetailsViewModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    details: PropTypes.object
};

export default PaymentDetailsViewModal;
