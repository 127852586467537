import { createSlice } from "@reduxjs/toolkit";
import {getAllBranchPO} from "services/purchaseOrderBranch/purchaseOrderBranch";

const initialState = {
    branchPurchaseOrder: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const BranchPOSlice = createSlice({
    name: "getBranchPO",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllBranchPO.pending, (state) => {
                state.branchPurchaseOrder.isLoading = true;
            })
            .addCase(getAllBranchPO.fulfilled, (state, { payload }) => {
                state.branchPurchaseOrder.isLoading = false;
                state.branchPurchaseOrder.isSuccess = true;
                state.branchPurchaseOrder.data = payload;
            })
            .addCase(getAllBranchPO.rejected, (state, { payload }) => {
                state.branchPurchaseOrder.isLoading = false;
                state.branchPurchaseOrder.isSuccess = false;
                state.branchPurchaseOrder.errorMessage = payload;
            });
    },
});

export default BranchPOSlice.reducer;
