import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import MDTable from "common/components/MDTable";
import MDBox from "common/components/MDBox";
import DropDown from "common/components/DropDown/DropDown";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";

const tableColumns = [
    {field: "procedure", headerName: "Procedure", accessor: "procedure", textAlign: 'left'},
    {field: "total", headerName: "Total", accessor: "total", textAlign: 'left'},
];

const searchKey = ['procedure', 'charge'];

function PaymentTable({ChangeDiscount}) {

    const [selectedValue, setSelectedValue] = useState({});
    const [optionsArray, setOptionsArray] = useState([])
    const [tblData, setTblData] = useState([]);
    const paymentData = useSelector(state => state.GetPaymentSlice.payment);
    const activeDiscount = useSelector(state => state.ActiveDiscountSlice.discount);

    useEffect(() => {
        if(!paymentData.isLoading && paymentData.isSuccess){
            const array = []
            paymentData.data?.bookingDetails.forEach((val)=> {
                array.push({
                    procedure: val?.procedureDto?.procedureName,
                    total: val?.procedureDto?.totalCharge,
                })
            })
            setTblData(array)
        }
    }, [paymentData.data]);

    useEffect(() => {
        if(activeDiscount.isSuccess && !activeDiscount.isLoading){
            const array = []
            activeDiscount.data.forEach((val)=> {
                array.push({
                    value: val,
                    label: val.description
                })
            })
            setOptionsArray(array)
        }
    }, [activeDiscount.data]);

    const paymentMethods = [
        { value: 'Cash', label: 'Cash' },
        { value: 'Card', label: 'Card' },
        { value: 'Qr Payment', label: 'Qr Payment' },
        { value: 'Bank Transfer', label: 'Bank Transfer' },

    ];

    return (
        <MDBox>
            <Grid container spacing={2}  >
                <Grid item xs={12} md={12} lg={12}>
                    <MDTable
                        tableData={tblData}
                        tableColumns={tableColumns}
                        searchKey={searchKey}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'row', marginTop: '30px' }}>
                <Grid item xs={12} md={4} lg={4}>
                    <DropDown
                        label={'Discount'}
                        options={optionsArray}
                        value={selectedValue}
                        onChange={(value) => {
                            ChangeDiscount(value)
                            setSelectedValue(value);
                        }}
                    />
                </Grid>

                <Grid item xs={0} md={0.5} lg={1}></Grid>
            </Grid>
        </MDBox>
    );
}

PaymentTable.propTypes = {
    ChangeDiscount: PropTypes.func.isRequired,
};
export default PaymentTable;
