import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import CommonButton from "common/components/Button";
import SlotAddModal from "components/SlotAddModal";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import Avatar from "@mui/material/Avatar";
import {time} from "config/images";
import Typography from "@mui/material/Typography";
import SlotsViewModal from "components/SlotsViewModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllSlots} from "services/slot/slot";

function Slot() {
    const dispatch = useDispatch();
    const getSlotsData = useSelector(state => state.SlotSlice.slot);
    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [tableData , setTableData] = useState([])
    const [docData, setDocData] = useState('')

    useEffect(() => {
        if (getSlotsData.isSuccess) {
            const data = getSlotsData?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => ({
                    id: val.doctor_id,
                    dName: (
                        <div style={{width: 500, display: 'flex', flexDirection: 'row'}}>
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    margin: '0px',
                                    padding: '0px',
                                    backgroundColor: '#EAF5FC',
                                    marginRight: '5px',
                                    marginTop: '8px',
                                }}
                                src={time}
                            />

                            <Typography sx={{fontSize: '14px', fontWeight: 'bold', marginTop: '10px'}}>{val.doctorName}</Typography>
                        </div>
                    ),
                    name: val.doctorName,
                    branch: (
                        <div>
                            <Typography sx={{fontSize: '14px', fontWeight: 'bold', marginTop: '10px'}}>{val.branchName}</Typography>
                        </div>
                    ),
                    room: (
                        <div>
                            <Typography sx={{fontSize: '14px', fontWeight: 'bold'}}>{val.chairNo}</Typography>
                        </div>
                    ),
                    status: (
                        <div>
                            <CommonButton

                                sx={{width: '100px'}}
                                name={'open'}
                                circular={true}
                                variant={"Fill"}
                            />
                        </div>
                    ),

                    slots:  (
                        <div>
                            <Typography sx={{fontSize: '14px', fontWeight: 'bold'}}>{val.slotCount}</Typography>
                        </div>
                    ),
                    view: (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <CommonButton
                                onClick={()=>{
                                    handleOpenViewModal(val.doctor_id)
                                }}
                                sx={{width: '100px'}}
                                name={'view'}
                                circular={true}
                                variant={"outline"}
                            />
                        </div>
                    )

                }));
                setTableData(array);
            }
        }
    }, [getSlotsData.isSuccess, getSlotsData.data]);

    useEffect(() => {
        dispatch(getAllSlots());
    }, []);

    const handleOpenViewModal = (id) => {
        setDocData(id)
        setOpenViewModal(true)
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const closeModal = () => {
        setOpenModal(false)
        setOpenViewModal(false)
    }

    const tableColumns = [
        {field: "dName", headerName: "Doctor Name", accessor: "dName", textAlign: 'left'},
        {field: "branch", headerName: "Clinic", accessor: "branch", textAlign: 'left'},
        {field: "room", headerName: "Room No", accessor: "room", textAlign: 'left'},
        {field: "slots", headerName: "Time Slots", accessor: "slots", textAlign: 'left'},
        {field: "view", headerName: "View", accessor: "view", textAlign: 'left'},
    ];

    const searchKey = ['dName', 'branch'];

    return (
        <div>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <div></div>

                <CommonButton
                    onClick={handleOpenModal}
                    name={'+ Add Slot'}
                    variant={'Fill'}
                />
            </Box>

            <MDBox sx={{marginY: 2}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            { openViewModal &&
                <SlotsViewModal
                    open={openViewModal}
                    onClose={closeModal}
                    doctorId={docData}
                />
            }

            {openModal &&
                <SlotAddModal
                    onClose={closeModal}
                    open={openModal}
                />
            }


        </div>
    );
}

export default Slot;
