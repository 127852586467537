import React, {useState} from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import MDBox from "common/components/MDBox";
import TextInput from "common/components/TextInput/TextInput";
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles(({
    tableCell: {
        maxWidth: 170,
    },
    headerCell: {
        fontWeight: 'bolder',
    },
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#448be8"
        },
        "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "#448be8",
            color: "#fff",
        },
        "& .MuiPaginationItem-root.Mui-selected:hover": {
            backgroundColor: "#448be8",
            color: "#fff",
        },
    },
}));

function TableComponent(props) {
    const {data, columns, isSearch} = props;
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = data.filter((row) =>
        Object.keys(row).some((column) => {
            const value = row[column];
            if (value !== null && value !== undefined) {
                return (
                    value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                );
            }
            return false;
        })
    );

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const classes = useStyles();
    return (
        <Grid>
            {isSearch &&
                <MDBox sx={{width:'250px'}}>
                    <TextInput
                        placeholder={'Search'}
                        onChange={handleSearch}
                        value={searchQuery}
                        endIcon={<SearchIcon/>}
                    />
                </MDBox>
            }
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                key={column.field}
                                className={classes.headerCell}
                                sx={{justifyContent: 'left', textAlign: 'left', fontSize: '12px' , borderBottom:'none'}}
                            >
                                {column.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {filteredData.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.id} hover onClick={() => {
                        }}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.field}
                                    style={{
                                        paddingRight: 0,
                                        position: "relative",
                                        textAlign: column.textAlign || 'center',
                                        border: 'none',
                                        fontSize: '14px',
                                        color: '#1C5D7C',
                                        fontWeight: 'bold'
                                    }}
                                    className={classes.tableCell}
                                >
                                    {row[column.field]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px'}}>
                <Pagination
                    classes={{ ul: classes.ul}}
                    count={Math.ceil(data.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    color="secondary"
                />
            </Box>

        </Grid>
    );
}

TableComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        headerName: PropTypes.string.isRequired,
        textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    })).isRequired,
    isSearch:PropTypes.bool,
};
export default TableComponent;
