import { createSlice } from "@reduxjs/toolkit";
import {getPatientCheck} from "services/PatientCheck/patientCheck";


const initialState = {
    patientCheck: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const patientCheckSlice = createSlice({
    name: "patientCheck",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPatientCheck.pending, (state) => {
                state.patientCheck.isLoading = true;
            })
            .addCase(getPatientCheck.fulfilled, (state, { payload }) => {
                state.patientCheck.isLoading = false;
                state.patientCheck.isSuccess = true;
                state.patientCheck.data = payload;
            })
            .addCase(getPatientCheck.rejected, (state, { payload }) => {
                state.patientCheck.isLoading = false;
                state.patientCheck.isSuccess = false;
                state.patientCheck.errorMessage = payload;
            });
    },
});

export default patientCheckSlice.reducer;
