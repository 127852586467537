import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MDTable from "common/components/MDTable";
import WideCard from "common/components/WideCard";
import React, {useEffect, useState} from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CommonButton from "common/components/Button";
import StockTransferViewModel from "components/StockTransferViewModel";
import {useDispatch, useSelector} from "react-redux";
import {getAllStockTransfers} from "services/stockTransfer/stockTransfer";

export default function StockTransferView() {

    const dispatch = useDispatch();
    const [modelOpen, setModelOpen] = useState(false);
    const [tableData, setTableData] = useState([])
    const [itemDetails,setItemDetails] = useState([])
    const stockTransferData = useSelector(state => state.StockTransferSlice.stockTransfer);

    const tableColumns = [
    {
      field: "receiveBranch",
      headerName: "Receive Branch",
      accessor: "receivebranch",
      textAlign: "left",
    },
    {
      field: "receiveData",
      headerName: "Receive Date",
      accessor: "receivedata",
      textAlign: "left",
    },
    {
      field: "status",
      headerName: "Status",
      accessor: "status",
      textAlign: "right",
    },
    {
      field: "action",
      headerName: "Action",
      accessor: "action",
      textAlign: "left",
    },
  ];
    const searchKey = ["receive branch", "receive data"];

    useEffect(() => {
        dispatch(getAllStockTransfers())
    }, []);

    useEffect(() => {
        if(stockTransferData.isSuccess && !stockTransferData.isLoading){
            const data = stockTransferData?.data;
            if(Array.isArray(data)){
                const array = data.map((val)=>(
                    {
                        receiveBranch: val.sentBranch.name,
                        receiveData: val.date,
                        status: (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "start",
                                    justifyContent: "start",
                                }}
                            >
                                <CommonButton
                                    sx={{ width: "100px"}}
                                    name={val.transferStatus}
                                    circular={true}
                                    variant={val.transferStatus === 'pending'? 'outline' : 'Fill'}
                                />
                            </div>
                        ),
                        action: (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "start",
                                    justifyContent: "start",
                                }}
                            >
                                <IconButton onClick={() => {
                                    setItemDetails(val)
                                    setModelOpen(true)
                                }}>
                                    <RemoveRedEyeIcon sx={{ color: "#2D9DE5" }} />
                                </IconButton>
                            </div>
                        ),
                    }
                ));
                setTableData(array)
            }
        }
    }, [stockTransferData.data]);

  return (
    <WideCard>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{ color: "#1C5D7C", fontWeight: 410, fontSize: "18px" }}
          >
            Stock Transfer View
          </Typography>
          <Box>
            <Divider sx={{ borderBottom: "1.3px solid black" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTable
            tableData={tableData}
            tableColumns={tableColumns}
            searchKey={searchKey}
            isSearch={true}
          />
        </Grid>
      </Grid>
        {modelOpen &&
            <StockTransferViewModel
                open={modelOpen}
                onClose={() => setModelOpen(false)}
                data={itemDetails}
            />
        }

    </WideCard>
  );
}
