import React, {useEffect, useState} from 'react';
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PatientAddModal from "components/PatientAddModal";
import PatientViewModal from "components/PatientViewModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import PatientFilterPopup from "components/PatientFilterPopup";
import {Popover} from "@material-ui/core";
import ConfirmAlert from "components/ConfirmAlert";
import {patientAdd} from "config/images";
import {deletePatient, getAllPatients} from "services/patient/patient";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {getImageUrl} from 'common/utils/CommonFunctions/index';
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function Patient() {
    const dispatch = useDispatch();
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isViewFormOpen, setIsViewFormOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [selectedPatient , setSelectedPatient] = useState();
    const [updatePatientData , setUpdatePatientData] = useState();
    const [patientsData,setPatientsData] = useState([]);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const deletePatientData = useSelector(state => state.DeletePatientSlice.patient);
    const patients = useSelector(state => state.PatientSlice.patient);

    const openAlert = () => {
        setIsAlertOpen(true);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const openFilterForm = () => {
        setIsFilterOpen(true);
    };

    const closeFilter = () => {
        setIsFilterOpen(false);
    };

    const openAddForm = () => {
        setIsAddFormOpen(true);
    };

    const openEditForm = () => {
        setIsEditFormOpen(true);
    };

    const openViewForm = (val) => {
        setSelectedPatient(val);
        setIsViewFormOpen(true);
    };

    const closeForm = () => {
        setIsAddFormOpen(false);
        setIsEditFormOpen(false);
        setIsViewFormOpen(false);
        setIsAlertOpen(false);
    };

    const tableColumns = [
        {field: "pName", headerName: "Patient Name", accessor: "pName", textAlign: 'left'},
        {field: "age", headerName: "Age", accessor: "age", textAlign: 'left'},
        {field: "mobileNum", headerName: "Mobile Number", accessor: "mobileNum", textAlign: 'left'},
        {field: "birthday", headerName: "Birthday", accessor: "birthday", textAlign: 'left'},
        {field: "details", headerName: "Details", accessor: "details", textAlign: 'left'},
    ];

    const searchKey = ['pName', 'age'];

    function calculateAge(birthdate) {
        const birthdateObj = dayjs(birthdate);
        const currentDatetime = dayjs();

        return currentDatetime.diff(birthdateObj, 'year');
    }

    useEffect(() => {
        dispatch(getAllPatients());
    }, []);

    useEffect(() => {
        if (deleteLoader){
            setDeleteLoader(false);
            if (deletePatientData.isSuccess && !deletePatientData.isLoading) {
                dispatch(getAllPatients());
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Patient Delete Success',
                })
            } else if (!deletePatientData.isSuccess && !deletePatientData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Delete Error'
                })
            }
        }
    }, [deletePatientData.data]);

    useEffect(() => {
        if(patients.isSuccess){
            const data = patients?.data;
            if(Array.isArray(data)){
                const array = data.map((val,index)=>({
                    id: index,
                    pName: (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Avatar src={val.image ? getImageUrl(val.image) : ''} sx={{backgroundColor: '#EAF5FC', color: '#2D9DE5', marginRight: '5px'}}/>
                            <div style={{marginTop: '10px'}}>
                                {`${val.title} ${val.firstName} ${val.lastName}`}
                            </div>
                        < /div>
                    ),
                    name:`${val.title} ${val.firstName} ${val.lastName}`,
                    age: calculateAge(val.birthDay),
                    mobileNum: val.mobileNumber,
                    birthday: val.birthDay,
                    details: (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Button
                                onClick={()=>{openViewForm(val)}}
                                variant="outlined"
                                sx={{
                                    color: '#2D9DE5',
                                    borderRadius: '20px',
                                    borderColor: '#2D9DE5',
                                    margin: "0px",
                                    '&:hover': {borderColor: '#2D9DE5',},
                                    '&:focus': {borderColor: '#2D9DE5',},
                                }}
                            >
                                View
                            </Button>
                            <IconButton onClick={(event)=>{
                                setAnchorEl(event.currentTarget);
                                setUpdatePatientData(val);
                            }}>
                                <MoreHorizIcon/>
                            </IconButton>
                        </div>

                    ),
                }));
                setPatientsData(array);
            }
        }
    }, [patients.data]);

    return (
        <div>
            <WideCard>
                <Typography
                    sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '19px',}}>Patient Management</Typography>
                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black', marginTop: '6px',}}/>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                    <CommonButton
                        name={'+  Add New Patient'}
                        variant={'Fill'}
                        onClick={openAddForm}
                    />
                </Box>

                <PatientFilterPopup
                    open={isFilterOpen}
                    onClose={closeFilter}
                />

                <MDBox sx={{marginY: 2}}>
                    <MDTable tableData={patientsData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
                </MDBox>

                <PatientAddModal
                    open={isAddFormOpen}
                    onClose={closeForm}

                />

                {isEditFormOpen &&
                    <PatientAddModal
                        open={isEditFormOpen}
                        onClose={closeForm}
                        update={true}
                        patientData={updatePatientData}
                        onClosePopup={handleClose}
                    />
                }

                {isViewFormOpen &&
                    <PatientViewModal
                        open={isViewFormOpen}
                        onClose={closeForm}
                        patientData={selectedPatient}
                    />
                }

                <ConfirmAlert
                    src={patientAdd}
                    open={isAlertOpen}
                    onClose={closeForm}
                    name={`${updatePatientData?.title} ${updatePatientData?.firstName} ${updatePatientData?.lastName}`}
                    title="Are you sure"
                    subtitle="you want to delete patient ?"
                    buttonText={'Delete'}
                    onclick={()=>{
                        setDeleteLoader(true);
                        dispatch(deletePatient(updatePatientData.id));
                        closeForm();
                    }}
                />

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Button onClick={openEditForm}
                                sx={{
                                    color: '#1C5D7C',
                                    '&:hover': {
                                        backgroundColor: '#2D9DE599',
                                        color: '#FFFFFF',
                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                    }
                                }}
                        >Update</Button>
                        <Button onClick={openAlert}
                                sx={{
                                    color: '#1C5D7C',
                                    '&:hover': {color: 'red'},
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                }}
                        >Delete</Button>
                    </Box>
                </Popover>
            </WideCard>
        </div>
    );
}

export default Patient;
