import Grid from "@mui/material/Grid";
import {FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import SquareIcon from "@mui/icons-material/Square";
import CommonButton from "common/components/Button";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, {useEffect, useState} from "react";
import {toothDown, toothUp} from "config/images";
import PropTypes from "prop-types";

const buttonsData = [
    {id: 1, color: '#FFBFCD', label: 'RCT 1'},
    {id: 2, color: '#00CCFF', label: 'RCT 2'},
    {id: 3, color: '#FFFF00', label: 'RCT 3'},
];

const toothBtn = [
    {id: 1, src: toothDown, label: 'OVERRUPTED'},
    {id: 2, src: toothUp, label: 'IMPACTED'},
];
export default function ToothChartToolKit({handleClick,clearData,setClearData,setDisable}) {

    const [selectedButton, setSelectedButton] = useState('');
    const [newButtonSelected, setNewButtonSelected] = useState(false);
    const [toothSelected, setToothSelected] = useState('');
    const [fillingType, setFillingType] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [missing, setMissing] = useState(false);
    const [caries, setCaries] = useState(false);
    const [crownOrBridge,setCrownOrBridge] = useState('');

    useEffect(() => {
        if (clearData===true){
            setSelectedButton('');
            setNewButtonSelected(false);
            setToothSelected('');
            setMissing(false);
            setFillingType('');
            setCaries(false);
            setCrownOrBridge('');
            setClearData();
            setDisable(false);
        }
    }, [clearData]);

    const handleButtonClick = (buttonId) => {
        if (selectedButton===buttonId){
            setSelectedButton('')
            handleClick({});
        }else {
            setSelectedButton(buttonId);
            handleClick({action:'rct',value:{rctRound:buttonId}})
        }

        setNewButtonSelected(false);
        setToothSelected('');
        setMissing(false);
        setFillingType('');
        setCaries(false);
        setCrownOrBridge('');
        setDisable(false);
    };

    const handleNewButtonClick = () => {
        if(newButtonSelected === true){
            setNewButtonSelected(false);
            handleClick({});
        }else {
            setNewButtonSelected(true);
            handleClick({action:'primary',value:{primaryStatus:1}})
        }

        setToothSelected('');
        setMissing(false);
        setSelectedButton('')
        setCaries(false);
        setFillingType('');
        setCrownOrBridge('');
        setDisable(false);
    };

    const handleToothClick = (buttonId) => {
        if(buttonId===toothSelected){
            setToothSelected('')
            handleClick({});
        }else {
            setToothSelected(buttonId);
            handleClick({action:'overrupted',value:buttonId===1? {directionType:-1} : {directionType:+1}});
        }

        setNewButtonSelected(false);
        setMissing(false);
        setSelectedButton('')
        setCaries(false);
        setFillingType('');
        setCrownOrBridge('');
        setDisable(false);
    };

    const handleCrown = ()=> {
        if (crownOrBridge === 'crown'){
            setCrownOrBridge('')
            setDisable(false);
            handleClick({});
        }else {
            setCrownOrBridge('crown')
            setDisable(true);
            handleClick({action:'crown',value:{}})
        }

        setNewButtonSelected(false);
        setToothSelected('');
        setMissing(false);
        setSelectedButton('')
        setFillingType('');
        setCaries(false);
    }

    const handleBridge = ()=>{
        if (crownOrBridge === 'bridge'){
            setCrownOrBridge('')
            setDisable(false);
            handleClick({});
        }else {
            setCrownOrBridge('bridge')
            setDisable(true);
            handleClick({action:'bridge',value:{}})
        }

        setNewButtonSelected(false);
        setToothSelected('');
        setMissing(false);
        setSelectedButton('')
        setFillingType('');
        setCaries(false);
    }

    const handleMissingChange = () => {
        if(missing===true){
            setMissing(false);
            handleClick({});
        }else {
            setMissing(true);
            handleClick({action:'missing',value:{status:0}});
        }

        setNewButtonSelected(false);
        setToothSelected('');
        setSelectedButton('')
        setCaries(false);
        setFillingType('');
        setCrownOrBridge('');
        setDisable(false);
    };

    const handleChange = (event) => {
        setFillingType(event.target.value)

        setNewButtonSelected(false);
        setToothSelected('');
        setMissing(false);
        setSelectedButton('')
        setCaries(false);
        setCrownOrBridge('');
        setDisable(false);

        if(event.target.value==='cancel'){
            handleClick({action:'filling',value:{fillingType:null}})
        }else{
            handleClick({action:'filling',value:{fillingType:event.target.value}})
        }
    };

    const handleCariesChange = () => {
        if(caries===true){
            setCaries(false);
            handleClick({});
        }else {
            setCaries(true);
            handleClick({action:'filling',value:{fillingType:"caries"}});
        }

        setNewButtonSelected(false);
        setToothSelected('');
        setMissing(false);
        setSelectedButton('')
        setFillingType('');
        setCrownOrBridge('');
        setDisable(false);
    };

    return(
        <Grid container spacing={1}>
            {buttonsData.map((buttonConfig) => (
                <Grid item xs={4} sm={3} md={1.5} lg={0.85} key={buttonConfig.id}>
                    <Tooltip title={`  ${buttonConfig.label}`} arrow>
                        <Button
                            variant="text"
                            sx={{
                                margin: '0px',
                                padding: '0px',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: selectedButton === buttonConfig.id ? '#e0f7fa' : 'transparent',
                                color: selectedButton === buttonConfig.id ? buttonConfig.color : '',
                                '&:hover': {
                                    backgroundColor: '#e0f7fa',
                                },
                            }}
                            onClick={() => handleButtonClick(buttonConfig.id)}
                        >
                            <SquareIcon sx={{color: buttonConfig.color}}/>

                        </Button>
                    </Tooltip>
                </Grid>
            ))}

            <Grid item xs={4} sm={2} md={1.5} lg={1.1}>
                <Tooltip title="PRIMARY" arrow>
                    <Button
                        variant="text"
                        sx={{
                            margin: '0px',
                            padding: '0px',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: newButtonSelected ? '#e0f7fa' : 'transparent',
                            color: newButtonSelected ? '#YourColorHere' : '',
                            '&:hover': {
                                backgroundColor: '#e0f7fa',
                            },
                        }}
                        onClick={handleNewButtonClick}
                    >
                        <SquareIcon sx={{color: '#FFA500'}}/>

                    </Button>
                </Tooltip>
            </Grid>

            {toothBtn.map((buttonConfig) => (
                <Grid item xs={4} sm={3} md={2} lg={1} key={buttonConfig.id}>
                    <Tooltip title={`  ${buttonConfig.label}`} arrow>
                        <Button
                            variant="text"
                            sx={{
                                margin: '0px',
                                padding: '0px',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: toothSelected === buttonConfig.id ? '#e0f7fa' : 'transparent',
                                color: toothSelected === buttonConfig.id ? buttonConfig.color : '',
                                '&:hover': {
                                    backgroundColor: '#e0f7fa',
                                },
                            }}
                            onClick={() => handleToothClick(buttonConfig.id)}
                        >
                            <img style={{width: '20px', height: '20px'}} src={buttonConfig.src} alt={'tooth'}/>

                        </Button>
                    </Tooltip>
                </Grid>
            ))}

            <Grid item xs={4} sm={3} md={1.5} lg={1}>
                <CommonButton
                    sx={{
                        backgroundColor: crownOrBridge === 'bridge' ? '#1C5D7C' : 'white',
                        borderColor:'#1C5D7C',
                        borderWidth:'2px',
                        color:crownOrBridge === 'bridge' ? '#f0f1f6' : '#1769a1',
                        '&:hover': {
                            backgroundColor: crownOrBridge === 'bridge' ? '#1C5D7C' : 'white',
                            color:crownOrBridge === 'bridge' ? '#f0f1f6' : '#1769a1',
                            borderColor:'#1C5D7C',
                            borderWidth:'2px',
                        },
                    }}
                    name={'Bridge'}
                    variant={"outline"}
                    onClick={handleBridge}
                />

            </Grid>

            <Grid item xs={4} md={3} sm={3} lg={1.1}>
                <CommonButton
                    sx={{
                        backgroundColor: crownOrBridge === 'crown' ? '#2D9DE5' : 'white',
                        borderColor:'#2D9DE5',
                        borderWidth:'2px',
                        color:crownOrBridge === 'crown' ? '#f0f1f6' : '#1769a1',
                        '&:hover': {
                            backgroundColor: crownOrBridge === 'crown' ? '#2D9DE5' : 'white',
                            color:crownOrBridge === 'crown' ? '#f0f1f6' : '#1769a1',
                            borderColor:'#2D9DE5',
                            borderWidth:'2px',
                        },
                    }}
                    name={'Crown'}
                    variant={"outline"}
                    onClick={handleCrown}
                />

            </Grid>

            <Grid item xs={4} sm={4} md={3} lg={1}>
                <Button variant={'text'}
                        onClick={handleMissingChange}
                        sx={{
                            margin: '0px',
                            padding: '0px',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundColor: missing ? '#e0f7fa' : 'transparent',
                            color: missing ? '#YourColorHere' : '',
                            '&:hover': {
                                backgroundColor: '#e0f7fa',
                            },
                        }}
                >
                    <Typography sx={{fontSize: '14px',}}>MISSING</Typography>
                </Button>
            </Grid>

            <Grid item xs={8} sm={5} md={4} lg={2}>
                <FormControl sx={{width: '100%'}}>
                    <InputLabel id="demo-simple-select-label">Select Filling</InputLabel>
                    <Select
                        sx={{color: "white"}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={fillingType}
                        label="Select Filling"
                        onChange={handleChange}
                        IconComponent={() => (
                            <IconButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                <ArrowDropDownIcon/>
                            </IconButton>
                        )}
                    >
                        <MenuItem
                            sx={{
                                color: '#ffffff',
                                backgroundColor: '#c5c1c1' ,
                                '&:hover': {color: '#ffffff',backgroundColor: '#c5c1c1'}   ,
                                '&.Mui-selected': {color: '#ffffff',backgroundColor: '#c5c1c1','&:hover': {color: '#ffffff',backgroundColor: '#c5c1c1'}}
                            }}
                            value={'cancel'}
                        >
                            Cancel Filling
                        </MenuItem>

                        <MenuItem sx={{color: '#ffffff',backgroundColor: 'red'     , '&:hover': {color: '#ffffff',backgroundColor: 'red'}     ,'&.Mui-selected': {color: '#ffffff',backgroundColor: 'red'     , '&:hover': {color: '#ffffff',backgroundColor: 'red    '}}}} value={"temporary"}>Temporary Filling</MenuItem>
                        <MenuItem sx={{color: '#ffffff',backgroundColor: '#0000FF' , '&:hover': {color: '#ffffff',backgroundColor: '#0000FF'} ,'&.Mui-selected': {color: '#ffffff',backgroundColor: '#0000FF' , '&:hover': {color: '#ffffff',backgroundColor: '#0000FF'}}}} value={"gic"}>GIC</MenuItem>
                        <MenuItem sx={{color: '#ffffff',backgroundColor: '#008000' , '&:hover': {color: '#ffffff',backgroundColor: '#008000'} ,'&.Mui-selected': {color: '#ffffff',backgroundColor: '#008000' , '&:hover': {color: '#ffffff',backgroundColor: '#008000'}}}} value={"compsite"}>Composite Filling</MenuItem>
                        <MenuItem sx={{color: '#ffffff',backgroundColor: '#800080' , '&:hover': {color: '#ffffff',backgroundColor: '#800080'} ,'&.Mui-selected': {color: '#ffffff',backgroundColor: '#800080' , '&:hover': {color: '#ffffff',backgroundColor: '#800080'}}}} value={"amalgam"}>Amalgam</MenuItem>

                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={4} sm={3} md={2} lg={1}>
                <Button
                    sx={{
                        margin: '0px',
                        padding: '0px',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: caries ? '#e0f7fa' : 'transparent',
                        color: caries ? '#YourColorHere' : '',
                        '&:hover': {
                            backgroundColor: '#e0f7fa',
                        },
                    }}
                    variant={'text'}
                    onClick={handleCariesChange}
                >
                    <Typography sx={{fontSize: '14px',}}>CARIES</Typography>
                </Button>
            </Grid>

        </Grid>
    )
}

ToothChartToolKit.propTypes = {
    handleClick:PropTypes.func,
    clearData:PropTypes.bool,
    setClearData:PropTypes.func,
    setDisable:PropTypes.func,
};
