import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { deleteUsers } from "services/user/user";
import { sendSuccessNotification } from "common/components/Notification/SendNotification";
export default function UserDeleteModel({ open, handleClose, user,fetch }) {
  console.log(user);
  const dispatch = useDispatch();

  const handleDelete = () => {
    //call delete function on user service
    dispatch(deleteUsers(user.id));
    sendSuccessNotification({
      title: "Success",
      message: "User Removed Successfully",
    });
    fetch();
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Remove?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will remove this user
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
