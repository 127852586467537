import React from 'react';
import ModalComponent from "common/components/Modal";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function PatientViewModal({open, onClose, patientData}) {
    const handleClose = () => {
        onClose();
    };

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="xs">
            <MDBox open={open} onClose={handleClose} maxWidth="xs" sx={{margin: '15px'}}>

                <Grid container spacing={2} sx={{display: 'flex', flexDirection: 'row'}}>
                    <Grid item>
                        <Typography sx={{fontSize: '17px', color: '#1C5D7C'}}>{`${patientData?.title} ${patientData?.firstName} ${patientData?.lastName}`}</Typography>
                        <Typography sx={{fontSize: '14px', color: '#999999'}}>{patientData?.mobileNumber}</Typography>
                        <Typography sx={{fontSize: '14px', color: '#999999'}}>{patientData?.email}</Typography>
                    </Grid>

                </Grid>

                <Grid container display={'flex'} flexDirection={'column'} marginTop={'20px'}>
                    <Grid item sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row', sm: 'row', lg: 'row'},}}>
                        <Typography sx={{color: '#CACACA', width: '180px', fontSize: '14px'}}>NIC : </Typography>
                        <Typography sx={{color: '#1C5D7C', fontSize: '14px'}}>{patientData?.nic}</Typography>

                    </Grid>
                    <Grid item sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row', sm: 'row', lg: 'row'},}}>
                        <Typography sx={{color: '#CACACA', width: '180px', fontSize: '14px'}}>Birthday :</Typography>
                        <Typography sx={{color: '#1C5D7C', fontSize: '14px'}}>{patientData?.birthDay}</Typography>

                    </Grid>
                    <Grid item sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row', sm: 'row', lg: 'row'},}}>
                        <Typography sx={{color: '#CACACA', width: '180px', fontSize: '14px'}}>Address :</Typography>
                        <Typography sx={{color: '#1C5D7C', fontSize: '14px'}}>{patientData?.address}</Typography>

                    </Grid>

                    <Grid item sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row', sm: 'row', lg: 'row'}}}>
                        <Typography sx={{color: '#CACACA', width: '180px', fontSize: '14px'}}>Country :</Typography>
                        <Typography sx={{color: '#1C5D7C', fontSize: '14px'}}>{patientData?.country}</Typography>

                    </Grid>

                    <Grid item sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row', sm: 'row', lg: 'row'},}}>
                        <Typography sx={{color: '#CACACA', width: '180px', fontSize: '14px'}}>Occupation :</Typography>
                        <Typography sx={{color: '#1C5D7C', fontSize: '14px'}}>{patientData?.occupation}</Typography>
                    </Grid>

                </Grid>

                <Grid container spacing={2} sx={{marginTop: '10px'}}>

                    <Grid item xs={12}>
                        <div
                            style={{
                                width: '100%',
                                borderRadius: '5px',
                                backgroundColor: '#EAF5FC',
                                padding: '10px',
                                boxSizing: 'border-box',
                            }}
                        >
                            <Typography sx={{color: '#CACACA', fontSize: '14px', marginBottom: '8px'}}>
                                How did you hear about us?
                            </Typography>
                            <Typography sx={{color: '#1C5D7C', fontSize: '14px'}}>
                                {patientData?.description}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div
                            style={{
                                width: '100%',
                                borderRadius: '5px',
                                backgroundColor: '#EAF5FC',
                                padding: '10px',
                                boxSizing: 'border-box',
                            }}
                        >
                            <Typography sx={{color: '#CACACA', fontSize: '14px', marginBottom: '8px'}}>
                                Comment
                            </Typography>
                            <Typography sx={{color: '#1C5D7C', fontSize: '14px'}}>
                                {patientData?.comment}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

            </MDBox>
        </ModalComponent>
    );
}

export default PatientViewModal;
