import { createSlice } from "@reduxjs/toolkit";
import { getPatientClinicalNotes } from "services/report/report";

const initialState = {
    patientClinicalNote: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const patientClinicalNoteSlice = createSlice({
    name: "patientClinicalNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPatientClinicalNotes.pending, (state) => {
                state.patientClinicalNote.isLoading = true;
            })
            .addCase(getPatientClinicalNotes.fulfilled, (state, { payload }) => {
                state.patientClinicalNote.isLoading = false;
                state.patientClinicalNote.isSuccess = true;
                state.patientClinicalNote.data = payload;
            })
            .addCase(getPatientClinicalNotes.rejected, (state, { payload }) => {
                state.patientClinicalNote.isLoading = false;
                state.patientClinicalNote.isSuccess = false;
                state.patientClinicalNote.errorMessage = payload;
            });
    },
});

export default patientClinicalNoteSlice.reducer;