import React, {useState} from 'react';
import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import PropTypes from "prop-types";
import {confirm} from 'config/images'
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CommonButton from "common/components/Button";

function PaymentPdfModal({open, onClose,handleConfirm}) {

    const [printBill, setPrintBill] = useState(false);
    const [downloadBill, setDownloadBill] = useState(false);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox maxWidth="sm" sx={{marginX: 3}}>
                <Typography sx={{color: "#1C5D7C", fontWeight: '450'}}>Payment process success..!</Typography>

                <MDBox sx={{display: 'flex', justifyContent: 'center', margin: 2}}>
                    <img src={confirm} alt=""/>
                </MDBox>

                <MDBox sx={{display:'flex' , flexDirection:'row', justifyContent:'space-around'}}>
                    <FormControlLabel control={<Checkbox checked={downloadBill} onChange={()=> setDownloadBill(!downloadBill)}/>} label="Download Bill" />
                    <FormControlLabel control={<Checkbox checked={printBill} onChange={()=> setPrintBill(!printBill)}/>} label="Print Bill" />
                </MDBox>

                <MDBox sx={{display: 'flex', justifyContent: 'end', margin:'20px 0px 20px 0px'}}>
                    <CommonButton
                        onClick={() => {handleConfirm({download: downloadBill, print: printBill})}}
                        name={"Confirm"}
                        variant={'Fill'}
                    />
                </MDBox>

            </MDBox>
        </ModalComponent>
    );
}

PaymentPdfModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func
};

export default PaymentPdfModal;
