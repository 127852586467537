import 'react-notifications-component/dist/theme.css';
import { Store } from 'react-notifications-component';
import { CheckCircle, Warning, Error } from '@mui/icons-material';
import './Notification.css';
import Grid from '@mui/material/Grid';

const notificationContent = {
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated animate__fadeIn"],
    animationOut: ["animate__animated animate__fadeOut"],
    dismiss: {
        duration: 5000,
        showIcon : true,
    }
};

export const sendSuccessNotification = (notification) => {
    Store.addNotification({
        ...notificationContent,
        message: (
            <Grid container className="notification-grid" >
                <Grid item xs={3} className="success-icon">
                    <CheckCircle fontSize="large" />
                </Grid>
                <Grid item xs={9} className="notification-content">
                    <div className="notification-title">
                        {notification.title}
                    </div>
                    <div className="notification-message">
                        {notification.message}
                    </div>
                </Grid>
            </Grid>
        ),
        type: 'success',

    });
};

export const sendWarningNotification = (notification) => {
    Store.addNotification({
        ...notificationContent,
        message: (
            <Grid container className="notification-grid" >
                <Grid item xs={3} className="warning-icon">
                    <Warning fontSize="large" />
                </Grid>
                <Grid item xs={9} className="notification-content">
                    <div className="notification-title">
                        {notification.title}
                    </div>
                    <div className="notification-message">
                        {notification.message}
                    </div>
                </Grid>
            </Grid>
        ),
        type: 'warning',

    });
};

export const sendDangerNotification = (notification) => {
    Store.addNotification({
        ...notificationContent,
        message: (
            <Grid container className="notification-grid" >
                <Grid item xs={3} className="danger-icon">
                    <Error fontSize="large" />
                </Grid>
                <Grid item xs={9} className="notification-content">
                    <div className="notification-title">
                        {notification.title}
                    </div>
                    <div className="notification-message">
                        {notification.message}
                    </div>
                </Grid>
            </Grid>
        ),
        type: 'danger',
    });
};
