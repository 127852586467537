import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";


export const getDailyUsage = createAsyncThunk('get-daily-usage', async (searchData, { rejectWithValue }) => {
        try {
            const {data} = await instance.post('/daily_usage/search_daily_usage',searchData,{
                headers: { "Content-type": "application/json" },
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const saveDailyUsage = createAsyncThunk(
    "save-daily-usage",
    async (usageData, { rejectWithValue }) => {
        try {
            const { data } = await instance.post(`/daily_usage`, usageData, {
                headers: { "Content-type": "application/json" },
            });
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const updateDailyUsage = createAsyncThunk('daily-usage-update', async ({id,usageData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/daily_usage/${id}`,  usageData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateDailyUsageStatus = createAsyncThunk('daily-usage-status-update', async (date, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/daily_usage/submit_state`,  date,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteDailyUsage = createAsyncThunk('delete-daily-usage', async (id, { rejectWithValue }) => {
        try {
            const {data} = await instance.delete(`/daily_usage/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
