import { createSlice } from "@reduxjs/toolkit";
import {deleteItem} from "services/item/item";

const initialState = {
    item: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const deleteItemSlice = createSlice({
    name: "delete-item",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteItem.pending, (state) => {
                state.item.isLoading = true;
            })
            .addCase(deleteItem.fulfilled, (state, { payload }) => {
                state.item.isLoading = false;
                state.item.isSuccess = true;
                state.item.data = payload;
            })
            .addCase(deleteItem.rejected, (state, { payload }) => {
                state.item.isLoading = false;
                state.item.isSuccess = false;
                state.item.errorMessage = payload;
            });
    },
});

export default deleteItemSlice.reducer;
