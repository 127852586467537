import React, {useEffect, useState} from 'react';
import ModalComponent from "common/components/Modal";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import DropDown from "common/components/DropDown/DropDown";
import CommonButton from "common/components/Button";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import moment from "moment";
import {generateDate, generateTime} from "common/utils/CommonFunctions";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches, getBranchWithChairs} from "services/branch/branch";
import {getDoctorsByBranch} from "services/doctor/doctor";
import {getAllSlots, postSlot, searchSlots} from "services/slot/slot";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

const Content = ({val, id, dltSlot}) => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '42px',
        border: '1.5px solid #2D9DE5',
        borderRadius: '4px',
        backgroundColor: 'white',
        marginBottom: '5px'
    }}>
        <div>
            <Typography sx={{fontSize: "14px", fontWeight: 500, color: '#1C5D7C', margin: '5px'}}>
                {val.sttime + "-" + val.edtime}
            </Typography>
        </div>
        <div>
            <IconButton aria-label="delete">
                <CloseIcon onClick={() => {
                    dltSlot(id)
                }}/>
            </IconButton>
        </div>
    </div>
)

function SlotAddModal({open, onClose}) {
    const dispatch = useDispatch();
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const searchSlotData = useSelector(state => state.SearchSlotSlice.slot);
    const saveSlotData = useSelector(state => state.SaveSlotSlice.slot);
    const getDoctData = useSelector(state => state.DoctorsByBranchSlice.doctor);
    const getDocChair = useSelector(state => state.GetBranchWithCharisSlice.branch);

    const [branch, setBranch] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [roomNoOptions, setRoomNoOptions] = useState([]);

    const [selectedBranch, setSelectedBranch] = useState({});
    const [selectedDoctor, setSelectedDoctor] = useState({});
    const [roomNo, setRoomNo] = useState({});
    const [slotData, setSlotData] = useState('15');
    const [timesOfDay, setTimesOfDay] = useState('morning');
    const [slotDate, setSlotDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const [array, setArray] = useState([]);
    const [dynamicSlots, setDynamicSlots] = useState([]);
    const [saveLoader, setSaveLoader] = useState(false);
    const [isAddSlotButtonDisable , setAddSlotButtonDisable] = useState(true);
    const [isConfirmButtonDisable , setConfirmButtonDisable] = useState(true);
    const [searchDetails , setSearchDetails] = useState({});

    const disableConfirmButton = !(selectedBranch && selectedDoctor && roomNo && slotData && slotDate && startTime && endTime && (dynamicSlots.length > 0));

    const clear = () =>{
        setSelectedBranch({});
        setSelectedDoctor({});
        setRoomNo({});
        setSlotDate(null);
        setStartTime(null);
        setEndTime(null);
        setTimesOfDay("");
    }

    const handleClose = () => {
        onClose();
    }
    const addNewSlot = () =>{

        const data = array.map((val)=>({
            date: generateDate(slotDate),
            startTime: val.sttime,
            endTime: val.edtime,
            chairId: roomNo.chairId,
            doctorId: selectedDoctor.doctorId,
            doctorName:selectedDoctor.name,
            chairNo:roomNo.chairNo,
        }))

        setDynamicSlots(data);
        setConfirmButtonDisable(false);
    }

    const saveSlots = () => {
        const array = dynamicSlots.map((slot)=>({
            date: slot.date,
            startTime: slot.startTime,
            endTime: slot.endTime,
            chairId: slot.chairId,
            doctorId: slot.doctorId
        }))

        setSaveLoader(true);
        dispatch(postSlot(array));
      
    }

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (saveSlotData.isSuccess && !saveSlotData.isLoading) {
                dispatch(getAllSlots());
                setAddSlotButtonDisable(true);
                setConfirmButtonDisable(true);
                setArray([]);
                clear();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Slot Saved Success',
                })

            } else if (!saveSlotData.isSuccess && !saveSlotData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Slot Saved Warning'
                })
            }
        }
    }, [saveSlotData.data]);

    useEffect(() => {
        if (getDocChair.isSuccess) {
            const data = getDocChair.data.chairs;
            if(Array.isArray(data)){
                const roomNumbers = data.map((chair) => ({
                    value: chair ,
                    label: chair.chairNo
                }));
                setRoomNoOptions(roomNumbers);
            }
        }
    }, [getDocChair.data]);

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if(Array.isArray(data)){
                setBranch(data.map((branch) => ({ value: branch, label: branch.name })));
            }
        }
    }, [getBranchData.data]);

    useEffect(() => {
        if(getDoctData.isSuccess){
            const data = getDoctData.data;
            setDoctor(data.map((doctor) => ({ value: doctor, label: doctor.name })));
        }
    }, [getDoctData.data]);

    const generateTimeSlots = (startT, endT, timeGap) => {
        const startTime = moment(startT, 'HH:mm');
        const endTime = moment(endT, 'HH:mm');
        const timeSlots = [];
        let currentTime = startTime.clone();
        while (currentTime.isSameOrBefore(endTime)) {
            const slotStartTime = currentTime.format('HH:mm');
            const slotEndTime = currentTime.add(timeGap, 'minutes').format('HH:mm');
            const formattedSlot = {sttime: slotStartTime, edtime: slotEndTime}
            timeSlots.push(formattedSlot);
        }
        return timeSlots;
    };

    const dltSlot = (id) => {
        const ar = [...array];
        ar.splice(id, 1);
        setArray(ar);
    }

    const deleteSlot = (id)=>{
        const ar = [...dynamicSlots];
        ar.splice(id, 1);
        setDynamicSlots(ar);

    }

    const slotDataArray = [
        {value: "30", label: "30 min"},
        {value: "15", label: "15 min"},
        {value: "45", label: "45 min"},
    ]

    const timesOfDayData = [
        {value: "morning", label: "Morning"},
        {value: "evening", label: "Evening"},
    ]

    useEffect(() => {
        if(searchSlotData.isSuccess){
            const data = searchSlotData.data;
            if(Array.isArray(data)){
                const array = data.map((slot)=>({
                    doctorName:slot.title+" "+slot.doctorName,
                    chairNo:slot.chairNo,
                    startTime:slot.startTime,
                    date:slot.date,
                }));
                setDynamicSlots(array);
            }
        }
    }, [searchSlotData.data]);

    useEffect(() => {
        dispatch(searchSlots(searchDetails));
    }, [selectedDoctor,selectedBranch,roomNo,slotDate,timesOfDay,endTime]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="lg">
            <MDBox open={open} onClose={onClose} maxWidth="lg" sx={{margin: '10px'}}>

                <Typography sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0px',
                    margin: '15px',
                    color: '#1C5E7C',
                    fontWeight: 450,
                    fontSize: '19px',
                }}>Add Slot</Typography>

                <Grid container sx={{display: 'flex', flexDirection: 'row', marginTop: '13px'}}>
                    <Grid container spacing={2} sx={{display: 'flex', flexDirection: 'row'}} md={12} lg={5}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DropDown
                                label={'Clinic'}
                                options={branch}
                                value={selectedBranch}
                                onChange={(value) => {
                                    setSelectedBranch(value);
                                    setRoomNoOptions([]);
                                    setSelectedDoctor({});
                                    setRoomNo({});
                                    setSlotDate(null);
                                    setStartTime(null);
                                    setEndTime(null);
                                    setSearchDetails({
                                        branchId:value.branchId
                                    })
                                    dispatch(getBranchWithChairs(value.branchId));
                                    dispatch(getDoctorsByBranch(value.branchId));
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <DropDown
                                label={'Doctor'}
                                options={doctor}
                                value={selectedDoctor}
                                onChange={(value) => {
                                    setSelectedDoctor(value);
                                    setRoomNo({});
                                    setSlotDate(null);
                                    setStartTime(null);
                                    setEndTime(null);
                                    setSearchDetails({
                                        branchId:selectedBranch.branchId,
                                        doctorId:value.doctorId
                                    })
                                }}
                                disabled={!selectedBranch || Object.keys(selectedBranch).length === 0}
                            />
                        </Grid>

                        <Grid item xs={12} sm={8} md={8} lg={8}>
                            <DropDown
                                label={'Room No'}
                                options={roomNoOptions}
                                value={roomNo}
                                onChange={(value) => {
                                    setSlotDate(null);
                                    setStartTime(null);
                                    setEndTime(null);
                                    setRoomNo(value);
                                    setSearchDetails({
                                        branchId:selectedBranch.branchId,
                                        doctorId:selectedDoctor.doctorId,
                                        chairId:value.chairId,

                                    })
                                }}
                                disabled={!selectedBranch || Object.keys(selectedBranch).length === 0 || !selectedDoctor || Object.keys(selectedDoctor).length === 0}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <DropDown
                                options={slotDataArray}
                                value={slotData}
                                onChange={(value) => {
                                    setSlotData(value);
                                }}
                                disabled={!selectedBranch || Object.keys(selectedBranch).length === 0 || !selectedDoctor || Object.keys(selectedDoctor).length === 0 || !roomNo || Object.keys(roomNo).length === 0}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label="Date"
                                    value={slotDate}
                                    onChange={(date) => {
                                        setSlotDate(date);
                                        setSearchDetails({
                                            branchId:selectedBranch.branchId,
                                            doctorId:selectedDoctor.doctorId,
                                            chairId:roomNo.chairId,
                                            date:generateDate(date),
                                            shift: timesOfDay,
                                        })
                                    }}
                                    disabled={!selectedBranch || Object.keys(selectedBranch).length === 0 || !selectedDoctor || Object.keys(selectedDoctor).length === 0 || !roomNo || Object.keys(roomNo).length === 0}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <DropDown
                                options={timesOfDayData}
                                value={timesOfDay}
                                onChange={(value) => {
                                    setTimesOfDay(value);
                                    setSearchDetails({
                                        branchId:selectedBranch.branchId,
                                        doctorId:selectedDoctor.doctorId,
                                        chairId:roomNo.chairId,
                                        date:generateDate(slotDate),
                                        shift:value,
                                    })

                                }}
                                disabled={!selectedBranch || Object.keys(selectedBranch).length === 0 || !selectedDoctor || Object.keys(selectedDoctor).length === 0 || !roomNo || Object.keys(roomNo).length === 0 || !slotDate || Object.keys(slotDate).length === 0}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    sx={{width: '100%'}}
                                    label="Start time"
                                    value={startTime}
                                    renderInput={(params) => <input {...params} />}
                                    onChange={(time) => {
                                        setEndTime(null);
                                        setStartTime(time)
                                    }}
                                    ampm={false}
                                    disabled={!selectedBranch || Object.keys(selectedBranch).length === 0 || !selectedDoctor || Object.keys(selectedDoctor).length === 0 || !roomNo || Object.keys(roomNo).length === 0 || !slotDate || Object.keys(slotDate).length === 0 || !timesOfDay || Object.keys(timesOfDay).length === 0}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    sx={{width: '100%'}}
                                    label="End time"
                                    value={endTime}
                                    ampm={false}
                                    onChange={(time) => {
                                        setEndTime(time)
                                        setSearchDetails({
                                            branchId:selectedBranch.branchId,
                                            doctorId:selectedDoctor.doctorId,
                                            chairId:roomNo.chairId,
                                            date:generateDate(slotDate),
                                            shift:timesOfDay,
                                            startTime:generateTime(startTime),
                                            endTime:generateTime(time),
                                        })
                                    }}
                                    disabled={!selectedBranch || Object.keys(selectedBranch).length === 0 || !selectedDoctor || Object.keys(selectedDoctor).length === 0 || !roomNo || Object.keys(roomNo).length === 0 || !slotDate || Object.keys(slotDate).length === 0 || !timesOfDay || Object.keys(timesOfDay).length === 0}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <CommonButton
                                onClick={() => {
                                    const arr = generateTimeSlots(generateTime(startTime), generateTime(endTime), slotData);
                                    if (arr.length > 0) {
                                        arr.pop();
                                        setArray([...arr]);
                                    }
                                    setAddSlotButtonDisable(false);

                                }}
                                fullWidth
                                name={'Add'}
                                variant={'Fill'}
                                disabled={!selectedBranch || Object.keys(selectedBranch).length === 0 || !selectedDoctor || Object.keys(selectedDoctor).length === 0 || !roomNo || Object.keys(roomNo).length === 0 || !slotData || Object.keys(slotData).length === 0 || !timesOfDay || Object.keys(timesOfDay).length === 0 || !slotDate || Object.keys(slotDate).length === 0 || !startTime || Object.keys(startTime).length === 0 || !endTime || Object.keys(endTime).length === 0 || !(dynamicSlots.length <= 0)}
                            />
                        </Grid>

                        <Grid container spacing={2} sx={{marginTop: '30px', marginLeft: '3px',height:"100px",overflow: 'auto'}}>
                                {array.map((val, index) => (
                                    <Grid key={index} item xs={12} sm={6} md={3} lg={6}>
                                        <Content id={index} dltSlot={(id) => {
                                            dltSlot(id)
                                        }} val={val}/>
                                    </Grid>
                                ))}
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <CommonButton
                                onClick={addNewSlot}
                                sx={{marginTop: '10px'}}
                                fullWidth
                                name={'Add Slot'}
                                variant={'Fill'}
                                disabled={isAddSlotButtonDisable}
                            />
                        </Grid>
                    </Grid>

                    <Grid container md={1} lg={0.5}>
                        <Grid item>
                            <Divider orientation="vertical" variant="middle" flexItem/>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} xs={12} sm={12} md={12} lg={6.3} sx={{
                        marginTop: {xs: 4, sm: 4, md: 4, lg: 0},
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '2px'
                    }}>
                        <div style={{height:"350px" , width:"100%", overflow: 'auto'}}>
                            {[...dynamicSlots].map((item, index) => (
                                <Grid
                                    container
                                    key={index}
                                    sx={{
                                        width: '100%',
                                        height: '60px',
                                        backgroundColor: '#EAF5FC',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: '15px',
                                        alignItems: 'center',
                                        padding: {xs: 0.4}
                                    }}
                                >
                                    <Grid item xs={9} md={3.5} lg={3.5}>
                                        <Typography sx={{
                                            fontSize: '14px',
                                            color: '#1C5D7C',
                                            marginLeft: '10px'
                                        }}>{item.doctorName}</Typography>
                                    </Grid>

                                    <Grid
                                        item xs={3} lg={1.5} md={1.5}
                                        sx={{
                                            width: '70px',
                                            height: '28px',
                                            border: '1px solid #2D9DE5',
                                            borderRadius: '15px',
                                            fontSize: '14px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: '#1C5D7C',
                                        }}
                                    >
                                        {item.chairNo}
                                    </Grid>

                                    <Grid
                                        item xs={10} lg={5} md={4}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '5px'
                                        }}
                                    >
                                        <EventIcon sx={{fontSize: '10px', marginRight: '5px', color: '#2D9DE5'}}/>
                                        <Typography sx={{
                                            fontSize: {lg: '14px', xs: '12px'},
                                            marginRight: '10px',
                                            color: '#1C5D7C'
                                        }}>
                                            {item.date}
                                        </Typography>
                                        <ScheduleIcon sx={{fontSize: '10px', marginRight: '5px', color: '#2D9DE5'}}/>
                                        <Typography sx={{
                                            fontSize: {lg: '14px', xs: '12px'},
                                            color: '#1C5D7C'
                                        }}>{item.startTime}</Typography>
                                    </Grid>

                                    <Grid
                                        item xs={1.5} lg={1} md={1}
                                        style={{marginTop: '-5px'}}>
                                        <IconButton aria-label="delete">
                                            <CloseIcon sx={{color: '#2D9DE5', fontSize: 2}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    </Grid>

                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={4} md={8} lg={7.7}>

                    </Grid>

                    <Grid item xs={4} md={2} lg={2}>
                        <CommonButton
                            onClick={() => {
                                handleClose();
                            }}
                            fullWidth
                            name={'close'}
                            variant={'outline'}
                        />
                    </Grid>

                    <Grid item xs={4} md={2} lg={2}>
                        <CommonButton
                            fullWidth
                            name={'confirm'}
                            variant={'Fill'}
                            onClick={()=>{
                                saveSlots();
                            }}
                            disabled={isConfirmButtonDisable || disableConfirmButton}
                        />
                    </Grid>
                    <Grid item md={1} lg={1.3}></Grid>
                </Grid>
            </MDBox>
        </ModalComponent>
    );
}

export default SlotAddModal;
