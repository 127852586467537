import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";
export const getDoctors = createAsyncThunk('get-doctors', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/doctor`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postDoctor = createAsyncThunk('post-doctor', async (docData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/doctor`, docData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updateDoctor = createAsyncThunk('doc-update', async ({id,docUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/doctor/${id}`,  docUpData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteDoctor = createAsyncThunk('delete-doctor', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/doctor/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getAllDoctorsWithSlotCounts = createAsyncThunk('doctors-with-slotcount', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/doctor/get_all_doctors_with_slot_count/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getDoctorsByBranch = createAsyncThunk('doctors-by-branch', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/doctor/get_all_doctor_by_branch/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

