import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";


export const getAllPurchaseOrders = createAsyncThunk('get-all-po', async (page, { rejectWithValue }) => {
        try {
            const {data} = await instance.get(`/main_po`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getPurchaseOrderItemsById = createAsyncThunk('get-po-items-by-id', async (id, { rejectWithValue }) => {
        try {
            const {data} = await instance.get(`/main_po/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const savePurchaseOrderItem = createAsyncThunk('save-po-item', async (poItemData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/main_po`, poItemData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updatePurchaseOrderStatus = createAsyncThunk('update-po-status', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/main_po/status_update/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updatePurchaseOrderItem = createAsyncThunk('update-po-item', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/main_po/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deletePurchaseOrderItem = createAsyncThunk('delete-po-item', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/main_po/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
