import { createSlice } from "@reduxjs/toolkit";
import {updateSupplier} from "services/suppllier/supplier";

const initialState = {
    supplier: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const updateSupplierSlice = createSlice({
    name: "update-supplier",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateSupplier.pending, (state) => {
                state.supplier.isLoading = true;
            })
            .addCase(updateSupplier.fulfilled, (state, { payload }) => {
                state.supplier.isLoading = false;
                state.supplier.isSuccess = true;
                state.supplier.data = payload;
            })
            .addCase(updateSupplier.rejected, (state, { payload }) => {
                state.supplier.isLoading = false;
                state.supplier.isSuccess = false;
                state.supplier.errorMessage = payload;
            });
    },
});

export default updateSupplierSlice.reducer;
