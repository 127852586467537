import { createSlice } from "@reduxjs/toolkit";
import {allStockDashboardCount} from "services/stockDashboard/stockDashboard";

const initialState = {
    count: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const dashboardCount = createSlice({
    name: "dashboardCount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(allStockDashboardCount.pending, (state) => {
                state.count.isLoading = true;
            })
            .addCase(allStockDashboardCount.fulfilled, (state, { payload }) => {
                state.count.isLoading = false;
                state.count.isSuccess = true;
                state.count.data = payload;
            })
            .addCase(allStockDashboardCount.rejected, (state, { payload }) => {
                state.count.isLoading = false;
                state.count.isSuccess = false;
                state.count.errorMessage = payload;
            });
    },
});

export default dashboardCount.reducer;
