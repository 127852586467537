import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAllProcedures = createAsyncThunk('get-procedure', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/procedure/${id}`  );
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postProcedure = createAsyncThunk('post-procedure', async (proData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/procedure`, proData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateProcedure = createAsyncThunk('pro-update', async ({id,proUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/procedure/${id}`,  proUpData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteProcedure = createAsyncThunk('delete-pro', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/procedure/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveExcelSheet = createAsyncThunk('save-excel', async (excelData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/procedure/get_excel_sheet_data`, excelData, {
            headers: {"Content-type": "multipart/form-data"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
