import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import React, {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useDispatch, useSelector} from "react-redux";
import {getAllTreatment, saveTreatment, updateTreatment} from "services/treatment/treatment";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function TreatmentAddModal({open, onClose ,isView=false,treatmentData}) {

    const dispatch = useDispatch();
    const [viewMode,setViewMode] = useState(isView);
    const [editMode, setEditMode] = useState(!viewMode);
    const [treatmentName, setTreatmentName] = useState(isView? treatmentData.treatmentName : '');
    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const saveTreatmentData = useSelector(state => state.SaveTreatmentSlice.treatment);
    const updateTreatmentData = useSelector(state => state.UpdateTreatmentSlice.treatment);
    const isDataComplete = treatmentName !== '';

    const handleAction = ()=> {
        const treatData = {
            treatmentName:treatmentName,
        }

        if(viewMode){
            setUpdateLoader(true);
            dispatch(updateTreatment({id:treatmentData.treatmentId,updateData: treatData}));
        }else {
            setSaveLoader(true);
            dispatch(saveTreatment(treatData));
        }
    }

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if (saveLoader){
            setSaveLoader(false);
            if (saveTreatmentData.isSuccess && !saveTreatmentData.isLoading) {
                setViewMode(true);
                setEditMode(false);
                dispatch(getAllTreatment());
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Treatment Saved Success',
                })
            } else if (!saveTreatmentData.isSuccess && !saveTreatmentData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Save Error'
                })
            }
        }
    }, [saveTreatmentData.data]);

    useEffect(() => {
        if (updateLoader){
            setUpdateLoader(false);
            if (updateTreatmentData.isSuccess && !updateTreatmentData.isLoading) {
                setViewMode(true);
                setEditMode(false);
                dispatch(getAllTreatment());
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Treatment Update Success',
                })
            } else if (!updateTreatmentData.isSuccess && !updateTreatmentData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Update Error'
                })
            }
        }
    }, [updateTreatmentData.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin:'10px'}}>
                <Box>
                    <div>
                        <Typography sx={{
                            color: '#1C5E7C',
                            fontWeight: 450,
                            fontSize: '16px',
                            marginBottom: '25px',
                            position: 'absolute',
                            top: '15px',
                            left: '15px'
                        }}> {viewMode? 'Treatment' : 'Add Treatment'} </Typography>
                    </div>
                    <div style={{
                        position: 'absolute',
                        top: '8px',
                        left: '85px'}}
                    >
                        {viewMode && <IconButton aria-label="delete">
                            <DriveFileRenameOutlineIcon fontSize={'small'} onClick={() => {
                                if(editMode){
                                    setEditMode(false);
                                }else {
                                    setEditMode(true);
                                }
                            }}/>
                        </IconButton>}
                    </div>
                </Box>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                        <Box sx={{marginY:'10px'}}>
                            <TextInput
                                placeholder={'Treatment'}
                                value={treatmentName}
                                onChange={(e)=>{
                                    setTreatmentName(e.target.value)
                                }}
                                disabled={!editMode}
                                variant="outlined"
                                fullwidth
                            />
                        </Box>

                <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                    <div style={{margin:'5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={()=>{
                                handleClose();
                            }}
                        />
                    </div>
                    <div style={{margin:'5px'}}>
                        {editMode &&
                            <CommonButton
                                name={viewMode? 'Update' : 'Add'}
                                variant={'Fill'}
                                onClick={handleAction}
                                disabled={!isDataComplete}
                            />
                        }

                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

TreatmentAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView:PropTypes.bool,
};

export default TreatmentAddModal;
