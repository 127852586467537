import Typography from "@mui/material/Typography";
import CommonButton from "common/components/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import PropTypes from "prop-types";


export default function RoomNoContent({isNew=false , room ,deleteRoom, id,mode=false}) {

    return(
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',height:'42px',border:isNew? '' : '1.2px solid #2D9DE5',borderRadius:'4px',backgroundColor:isNew? '#EAF5FC' : 'white',marginBottom:'10px'}}>
            <div>
                <Typography sx={{fontSize:"14px" , fontWeight:500 ,color:'#1C5D7C',margin:'5px' }}>
                    {room.chairNo}
                </Typography>
            </div>
            <div>
                <CommonButton
                    sx={{height:'28px'}}
                    name={isNew? 'New' : 'Open'}
                    variant={isNew? 'outline':'Fill'}
                    circular={true}
                />
                <IconButton aria-label="delete" disabled={mode}>
                    <CloseIcon onClick={()=>{
                        deleteRoom(room.id);
                    }} />
                </IconButton>

            </div>
        </div>
    )
}

RoomNoContent.propTypes = {
    isNew:PropTypes.bool,
    deleteRoom:PropTypes.func,
    id:PropTypes.number,
    room:PropTypes.object,
};
