import React, {useEffect, useState} from 'react';
import ModalComponent from "common/components/Modal";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CommonButton from "common/components/Button";
import PropTypes from "prop-types";
import MDTable from "common/components/MDTable";
import CommonCheckBox from "common/components/CheckBox";
import TextInput from "common/components/TextInput/TextInput";
import Avatar from "@mui/material/Avatar";
import {itm} from "config/images";
import {useDispatch, useSelector} from "react-redux";
import {getAllStockTransfers, saveStockTransfer} from "services/stockTransfer/stockTransfer";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function StockTransferItemAddModal({open, onClose, isView = false,sentItems,branchDetails}) {

    const dispatch = useDispatch();
    const [isAdd, setAdd] = useState(false);
    const [tableData,setTableData] = useState([]);
    const [tableDataAdded,setTableDataAdded] = useState([]);
    const [tableDataWithAction, setTableDataWithAction] = useState(sentItems)
    const [tableDataView,setTableDataView] = useState([])
    const [selectedItemIdArray, setSelectedItemIdArray] = useState([]);
    const [selectedItemArray, setSelectedItemArray] = useState([]);
    const [loader, setLoader] = useState(false);
    const saveStockTransferData = useSelector(state => state.SaveStockTransferSlice.stockTransfer)

    const tableColumns = [
        {field: "item", headerName: "Item", accessor: "item", textAlign: 'left'},
        {field: "qty", headerName: "Qty", accessor: "qty", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
        {field: "newQty", headerName: "New Qty", accessor: "newQty", textAlign: 'left'},
    ];

    const tableColumnsAdded = [
        {field: "item", headerName: "Item", accessor: "item", textAlign: 'left'},
        {field: "qty", headerName: "Qty", accessor: "qty", textAlign: 'left'},
    ];
    const searchKey = ['item', 'qty'];

    const updateSelectedIdArray = (val, id,index) => {
        setSelectedItemIdArray(prevArray => {
            const array = [...prevArray];
            const isIdExist = array.includes(id);
            if (val) {
                if (!isIdExist) {
                    array.push(id);
                }
            } else {
                if (isIdExist) {
                    const index = array.indexOf(id);
                    if (index !== -1) {
                        array.splice(index, 1);
                    }
                }
            }
            return array;
        });
    };

    const updateSelectedItemArray = (val,id,name)=>{
        setSelectedItemArray(prevArray => {
            let array = [...prevArray];
            const isIdExist = array.some(item => item.id === id);

            if(isIdExist){
                array = array.map(item => {
                    if (item.id === id) {
                        return {...item, qty: val};
                    }
                    return item;
                });
            }else {
                array.push({id:id,qty:val,itemName:name})
            }
            return array;
        });
    }

    const updateFinalArray = ()=>{
        const filteredArray = selectedItemArray.filter(item => selectedItemIdArray.includes(item.id));
        if(Array.isArray(filteredArray)){
            const array = filteredArray.map((itms)=>({
                itemId:itms.id,
                item:(
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                margin: '0px',
                                padding: '0px',
                                backgroundColor: '#EAF5FC',
                                marginRight: '5px',
                                marginTop: '8px',
                            }}
                            src={itm}
                        />
                        <div style={{marginTop: '10px'}}>{itms.itemName}</div>
                    </div>
                ),
                qty:itms.qty,
            }));
            setTableDataAdded(array)
        }

    }

    const handleAction = ()=>{

        if (isAdd){
            const  array = tableDataAdded.map((itms)=>({
                itemId:itms.itemId,
                qty:itms.qty
            }))

            const transferData = {
                sentBranch: branchDetails.sendBranchId,
                receiveBranch: branchDetails.recBranchId,
                stockTransferDetailsDto:array
            }
            setLoader(true)
            dispatch(saveStockTransfer(transferData))
        }else {
            updateFinalArray();
            setAdd(true);
        }
    }

    useEffect(() => {
        if(isView){
            if(Array.isArray(sentItems)){
                const array = sentItems.map((itms)=>({

                    item:(
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    margin: '0px',
                                    padding: '0px',
                                    backgroundColor: '#EAF5FC',
                                    marginRight: '5px',
                                    marginTop: '8px',
                                }}
                                src={itm}
                            />
                            <div style={{marginTop: '10px'}}>{itms.itemId.itemName}</div>
                        </div>
                    ),
                    qty:itms.qty,
                }));
                setTableDataView(array)
            }
        }else {
            if(Array.isArray(tableDataWithAction)){
                const array = tableDataWithAction.map((itms,index)=>({
                    itemId:itms.itemId,
                    item:(
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    margin: '0px',
                                    padding: '0px',
                                    backgroundColor: '#EAF5FC',
                                    marginRight: '5px',
                                    marginTop: '8px',
                                }}
                                src={itm}
                            />
                            <div style={{marginTop: '10px'}}>{itms.itemName}</div>
                        </div>
                    ),
                    itemName:itms.itemName,
                    qty:itms.qty,
                    action:(
                        <CommonCheckBox
                            onChange={(val)=>{
                                updateSelectedIdArray(val,itms.itemId,index);
                            }}
                        />
                    ),
                    newQty:(
                        <TextInput
                            placeholder={"Qty"}
                            onChange={(e)=>{
                                updateSelectedItemArray(e.target.value,itms.itemId,itms.itemName);
                            }}
                        />
                    ),
                }));
                setTableData(array);
            }
        }

    }, []);

    useEffect(() => {
        if (loader){
            setLoader(false);
            if (saveStockTransferData.isSuccess && !saveStockTransferData.isLoading) {
                dispatch(getAllStockTransfers())
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Stock Transfer Success',
                })
            } else if (!saveStockTransferData.isSuccess && !saveStockTransferData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Stock Transfer Error'
                })
            }
        }
    }, [saveStockTransferData.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox sx={{margin: '10px'}}>

                {isView &&
                    <Typography sx={{
                        position: 'absolute',
                        top: '0',
                        left: '0px',
                        margin: '15px',
                        color: '#1C5E7C',
                        fontWeight: 450,
                        fontSize: '19px',
                    }}>Item View</Typography>
                }

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                {!isView &&

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MDBox sx={{display:'flex'}}>
                                <Typography sx={{ color: "#000000", fontWeight: 410, fontSize: "16px" }}>
                                    Sent Branch -
                                </Typography>
                                <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "16px" }}>
                                    {branchDetails.sendBranchName}
                                </Typography>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <MDBox sx={{display:'flex'}}>
                                <Typography sx={{ color: "#000000", fontWeight: 410, fontSize: "16px" }}>
                                    Receive Branch -
                                </Typography>
                                <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "16px" }}>
                                    {branchDetails.recBranchName}
                                </Typography>
                            </MDBox>
                        </Grid>
                    </Grid>

                }

                <MDBox sx={{marginY: 2}}>
                    {isView ?
                        <MDTable
                            tableData={tableDataView}
                            tableColumns={tableColumnsAdded}
                            searchKey={searchKey}
                        />
                        :
                        !isAdd?
                            <MDTable
                                tableData={tableData}
                                tableColumns={tableColumns}
                                searchKey={searchKey}
                                isSearch={true}
                            />
                            :
                            <MDTable
                                tableData={tableDataAdded}
                                tableColumns={tableColumnsAdded}
                                searchKey={searchKey}
                            />
                    }
                </MDBox>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>
                    <div style={{margin: '5px'}}>
                        {!isView &&
                            <CommonButton
                                name={isAdd ? 'Sent' : 'Add'}
                                variant={'Fill'}
                                disabled={isAdd? tableDataAdded.length <= 0 : false}
                                onClick={()=>{
                                    handleAction()
                                }}
                            />
                        }

                    </div>
                </Box>

            </MDBox>
        </ModalComponent>
    );
}

StockTransferItemAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView:PropTypes.bool,
    sentItems:PropTypes.array,
    branchDetails:PropTypes.object
};

export default StockTransferItemAddModal;
