import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MDBox from "common/components/MDBox";
import {
  collapseItem,
  collapseIconBox,
  collapseText,
} from "./styles/sidenavSubCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function SidenavSubCollapse({ name, active, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  return (
      <ListItem component="li">
          <MDBox
              {...rest}
              sx={(theme) =>
                  collapseItem(theme, {
                      active,
                      transparentSidenav,
                      whiteSidenav,
                      darkMode,
                      sidenavColor,
                  })
              }
          >
              <ListItemIcon
                  sx={(theme) =>
                      collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
                  }
              >
              </ListItemIcon>

              <ListItemText
                  primary={name}
                  sx={(theme) =>
                      collapseText(theme, {
                          miniSidenav,
                          transparentSidenav,
                          whiteSidenav,
                          active,
                      })
                  }
              />
          </MDBox>
      </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavSubCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavSubCollapse.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavSubCollapse;
