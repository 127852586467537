import React from 'react';
import ModalComponent from "common/components/Modal";
import MDBox from "common/components/MDBox";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

function ConfirmAlert({open, onClose ,title , subtitle , buttonText,name,src ,onclick, icon}) {

    const handleClose = () => {
        onClose();
    };

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="xs">
            <MDBox open={open} onClose={handleClose} maxWidth="xs" sx={{margin: '15px'}}>
                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '400px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>

                        <Avatar
                            alt="Avatar Image"
                            sx={{width: 70, height: 70 , margin:"0px" , padding:'10px', backgroundColor: '#EAF5FC', }}
                            src={src}
                            icon={icon}
                        />

                    <Typography sx={{fontSize: "15.5px", color: '#1C5D7C' , marginTop:'15px'}}>{name}</Typography>
                    <Typography sx={{ fontSize: "26px", color: '#1C5D7C', marginTop: '15px' , fontWeight:'bold'}}>{title}</Typography>
                    <Typography sx={{ fontSize: "13px", color: '#1C5D7C' }}>{subtitle}</Typography>
                </Box>

                <Grid container sx={{display:'flex' , flexDirection:'row' ,  marginTop:"25px" , justifyContent:'space-between'}}>
                    <Grid item>
                        <Button onClick={handleClose} variant={"outlined"} sx={{color:'#2D9DE5' , width:'100%',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                borderColor: '#2D9DE5',
                                color: '#2D9DE5',
                            },
                        }}>cancel</Button>
                    </Grid>

                    <Grid item>
                        <Button
                            onClick={onclick}
                            sx={{
                                backgroundColor: '#2D9DE5',
                                color: '#FFFFFF',
                                '&:hover': {
                                    backgroundColor: '#2D9DE5',
                                },
                                height: '40px',
                                width:'110px'
                            }}

                            variant="gradient"
                        >
                            {buttonText}
                        </Button>
                    </Grid>
                </Grid>

            </MDBox>
        </ModalComponent>
    );
}

ConfirmAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ConfirmAlert;
