import {Card, Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/system";
import MDBox from "common/components/MDBox";
import DailyUsageView from "components/DailyUsageView";
import PurchaseOrderView from "components/PurchaseOrderView";
import StockUsageView from "components/StockUsageView";
import TotalInventoryView from "components/TotalInventoryView";
import MainPurchaseOrderView from "components/MainPurchaseOrderView";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {allStockDashboardCount} from 'services/stockDashboard/stockDashboard'
function InventoryDashboard() {

    const dispatch = useDispatch();
    const [purchaseOrder, setPurchaseOrder] = useState(0);
    const [item, setItem] = useState(0);
    const [supplier, setSupplier] = useState(0);
    const [grn, setGrn] = useState(0);
    const count = useSelector(state => state.DashboardCount.count);
    const auth = useSelector((state) => state.AuthSlice.auth);

    useEffect(()=> {
        dispatch(allStockDashboardCount());
    },[]);

    useEffect(() => {
        if(count.isSuccess && !count.isLoading){
            const data = count.data;
            if (auth.data?.role === 'stock'){
                setPurchaseOrder(data?.branch_po_count);
                setItem(data?.item_count);
                setGrn(data?.grn_count);
            }else {
                setPurchaseOrder(data?.main_po_count);
                setItem(data?.item_count);
                setSupplier(data?.supplier_count);
            }
        }
    }, [count.data]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Card
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                fullWidth: true,
                                Width: "100%",
                                p: 2,
                            }}
                        >
                            <TotalInventoryView img="item" value={purchaseOrder} label="Purchase Order"/>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Card
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                fullWidth: true,
                                Width: "100%",
                                p: 2,
                            }}
                        >
                            <TotalInventoryView img="item" value={item} label="Item"/>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Card
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                fullWidth: true,
                                Width: "100%",
                                p: 2,
                            }}
                        >
                            <TotalInventoryView img="order"
                                                value={auth.data?.role === 'stock' ? grn : supplier}
                                                label={auth.data?.role === 'stock' ? "GRN" : "Supplier"}
                            />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <StockUsageView/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <PurchaseOrderView/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {auth.data?.role === 'stock' ?
                            <DailyUsageView/>
                            :
                            <MainPurchaseOrderView/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default InventoryDashboard;
