import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import {dentistLogo} from "config/images";
import Avatar from "@mui/material/Avatar";
import DropDown from "common/components/DropDown/DropDown";
import Box from "@mui/material/Box";
import AppointmentBookingView from "components/AppointmentBookingView";
import AppointmentBookingAdd from "components/AppointmentBookingAdd";
import {useDispatch, useSelector} from "react-redux";
import {getAvailableSlotsByDoctorAndDate} from "services/slot/slot";
import React, {useEffect, useState} from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {generateDate} from "common/utils/CommonFunctions";
import dayjs from "dayjs";
import {sendWarningNotification} from "common/components/Notification/SendNotification";
import { match } from "stylis";

function AppointmentBookingModal({branchDetails, open, onClose, action = 'add', selectedDoctor}) {

    const dispatch = useDispatch();
    const [bookingDate , setBookingDate] = useState(null);
    const [roomNo, setRoomNo] = useState('00');
    const [availableSlots , setAvailableSlots] = useState([]);
    const [selectedSlot , setSelectedSlot] = useState(null);
    const slots = useSelector(state => state.SlotsByDoctorAndDateSlice.slot);
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (slots.isSuccess) {
            const slotsData = slots.data;
            if(Array.isArray(slotsData)){
                const array = slotsData.map( slot => ({
                    value:slot ,
                    label:slot.startTime+" - "+slot.endTime,
                }));
                setAvailableSlots(array);
            }
        }
    }, [slots.data]);

    const handleClose = () => {
        onClose();
        setAvailableSlots([]);
        setBookingDate(null);
        setRoomNo("00")
        setSelectedSlot(null);
    };

    return (
        <ModalComponent showModal={open} setShowModal={handleClose} maxWidth="lg">
            <MDBox sx={{margin:'10px'}}>
                <Typography sx={{
                    color: '#1C5E7C',
                    fontWeight: 450,
                    fontSize: '16px',
                    marginBottom: '25px',
                    position: 'absolute',
                    top: '15px',
                    left: '15px'
                }}> {action==='add' ? "Appointment Booking" : "Appointment Details"}</Typography>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '800px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={1} sx={{marginTop: '10px'}}>
                    <Grid item xs={12} sm={12} md={4.5} lg={4}>
                        <MDBox sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div>
                                <Avatar
                                    alt="doctor"
                                    src={dentistLogo}
                                    sx={{bgcolor: '#EAF5FC', width: '60px', height: '60px', margin: '5px'}}
                                />
                            </div>
                            <Typography sx={{fontSize: "13px", fontWeight: 500, color: '#1C5E7C', marginTop: '5px'}}>
                                {selectedDoctor ? selectedDoctor.title + ' ' + selectedDoctor.name : ''}
                            </Typography>
                            <Typography sx={{fontSize: "12px", fontWeight: 500, color: '#1C5E7C', margin: '5px'}}>
                                {selectedDoctor ? selectedDoctor.address : " "}
                            </Typography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4.5} lg={4}>
                        <MDBox>
                            <Box sx={{
                                backgroundColor: '#EAF5FC',
                                borderRadius: '5px',
                                marginY: '2px',
                                paddingTop: '2px'
                            }}>
                                <Typography sx={{fontSize: "9px", fontWeight: 500, color: '#1C5E7C', margin: '2px'}}>
                                    Date-10
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="Date"
                                            value={bookingDate}
                                            slotProps={{ textField: { fullWidth: true } }}
                                            onChange={(val)=>{
                                                if(dayjs(val).isBefore(dayjs().subtract(1, 'day'))){
                                                    sendWarningNotification({
                                                        title: 'Warning',
                                                        message: 'Date does not match with current date. Please enter valid date'
                                                    })
                                                    setIsValid(true)
                                                }else{
                                                    setBookingDate(val)
                                                    setIsValid(false)
                                                    dispatch(getAvailableSlotsByDoctorAndDate({id:selectedDoctor?.doctorId,date:generateDate(val)}))
                                                }

                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{ backgroundColor:'#EAF5FC',borderRadius:'5px', marginTop: '15px',paddingTop:'2px'}}>
                                <Typography sx={{fontSize:"9px" , fontWeight:500 ,color:'#1C5E7C',margin:'2px'}}>
                                    TIME SLOT - 20
                                </Typography>
                                <DropDown
                                    label={''}
                                    disabled={isValid}
                                    options={availableSlots}
                                    onChange={(val) => {
                                        setSelectedSlot(val)
                                        setRoomNo(val?.chair?.chairNo)
                                    }}
                                />
                            </Box>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={4}>
                        <Box
                            sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'#EAF5FC',height:'100%',width:'100%',borderRadius:'8px', maxHeight: '150px'}}
                        >
                            <Typography sx={{fontSize:"15px" , fontWeight:425 ,color:'#1C5D7C' }}>
                                ROOM NO
                            </Typography>
                            <Typography sx={{fontSize:"35px" , fontWeight:500 ,color:'#1C5D7C' }}>
                                {roomNo}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                {action === 'view' ?
                    <AppointmentBookingView/>
                    :
                    <AppointmentBookingAdd branchDetails={branchDetails} handleClose={handleClose} slotDetails={selectedSlot}/>
                }
            </MDBox>
        </ModalComponent>
    );
}

AppointmentBookingModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    action: PropTypes.oneOf(['add', 'view']),
    selectedDoctor: PropTypes,
    branchDetails: PropTypes.object.isRequired
};

export default AppointmentBookingModal;
