import React from 'react';
import Card from "@mui/material/Card";
import { useTheme } from '@mui/system';
import PropTypes from "prop-types";

function WideCard({children  }) {
    const theme = useTheme();
    return (
        <Card sx={{
            padding: theme.spacing(1.5),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1.5),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(2),
            },
        }}>

            {children}

        </Card>
    );
}

WideCard.propTypes = {
    children: PropTypes.node.isRequired,
};

export default WideCard;