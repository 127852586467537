import React from 'react';
import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

function PrintBillModal({open, onClose, bill = false}) {

    const handleClose = () => {
        onClose();
    };

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox open={open} onClose={handleClose} maxWidth="sm" sx={{margin: '15px'}}>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '1000px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Typography sx={{color: "#1C5D7C", fontWeight: '450'}}>Print Bill</Typography>

                <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}
                      marginTop={'20px'}>
                    <Grid item sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'column', sm: 'column', lg: 'column'},
                    }}>
                        <Typography sx={{color: '#497D96', fontSize: '14px'}}>Visit Date</Typography>
                        <Typography sx={{color: '#497D96', fontSize: '14px', marginTop: '10px'}}>Procedure</Typography>
                        <Typography sx={{color: '#497D96', fontSize: '14px', marginTop: '10px'}}>Status</Typography>
                        <Typography sx={{color: '#497D96', fontSize: '14px', marginTop: '10px'}}>Treated
                            Date</Typography>
                        <Typography sx={{color: '#497D96', fontSize: '14px', marginTop: '10px'}}>Charge</Typography>
                        <Typography sx={{color: '#497D96', fontSize: '14px', marginTop: '10px'}}>Extra</Typography>
                    </Grid>

                    <Grid item sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'column', sm: 'column', lg: 'column'},
                    }}>
                        <Typography sx={{color: '#8DAEBD', fontSize: '14px'}}>2023-10-22</Typography>
                        <Typography sx={{color: '#8DAEBD', fontSize: '14px', marginTop: '10px'}}>45 000.00</Typography>
                        <Typography sx={{color: '#8DAEBD', fontSize: '14px', marginTop: '10px'}}>Treated</Typography>
                        <Typography sx={{color: '#8DAEBD', fontSize: '14px', marginTop: '10px'}}>2020-11-16</Typography>
                        <Typography sx={{color: '#8DAEBD', fontSize: '14px', marginTop: '10px'}}>200 000.00</Typography>
                        <Typography sx={{color: '#8DAEBD', fontSize: '14px', marginTop: '10px'}}>30 000.00</Typography>
                    </Grid>
                </Grid>

                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black'}}/>
                </Box>

                <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}
                      marginTop={'20px'}>
                    <Grid item sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'column', sm: 'column', lg: 'column'},
                    }}>
                        <Typography sx={{color: '#1C5D7C', fontSize: '14px', fontWeight: "bolder"}}>Total
                            Charge</Typography>
                    </Grid>

                    <Grid item sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'column', sm: 'column', lg: 'column'},
                    }}>
                        <Typography sx={{color: '#1C5D7C', fontSize: '14px', fontWeight: "bolder"}}>350
                            000.00</Typography>
                    </Grid>
                </Grid>
                <Button
                    sx={{
                        backgroundColor: '#2D9DE5',
                        color: '#FFFFFF',
                        marginTop: '12px',
                        '&:hover': {
                            backgroundColor: '#2D9DE5',
                        },
                    }}
                    fullWidth
                    variant="gradient"
                >
                    {bill ? "Bill" : "Print"}
                </Button>

            </MDBox>
        </ModalComponent>
    );
}

PrintBillModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    bill: PropTypes.bool
};

export default PrintBillModal;
