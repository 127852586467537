import React, {useEffect, useState} from 'react';
import ModalComponent from "common/components/Modal";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CommonButton from "common/components/Button";
import {useDispatch, useSelector} from "react-redux";
import {searchSlots} from "services/slot/slot";
import {generateDate} from "common/utils/CommonFunctions";

function SlotsViewModal({open, onClose,doctorId}) {
    const dispatch = useDispatch();
    const searchSlotData = useSelector(state => state.SearchSlotSlice.slot);
    const [slotData, setSlotData]=useState([]);

    useEffect(() => {
        const searchDetails = {
            doctorId:doctorId,
        }
        console.log('mmmmmm')
        dispatch(searchSlots(searchDetails));
    }, []);


    useEffect(() => {
        if(searchSlotData.isSuccess){
            const data = searchSlotData?.data;
            if(Array.isArray(data)){
                const array = data.map((slot)=>({
                    name: slot.doctorName,
                    value:slot.chairNo,
                    date:slot.date,
                    time:slot.startTime
                }))
                setSlotData(array)
            }

        }

    }, [searchSlotData.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox open={open} onClose={onClose} maxWidth="sm" sx={{margin: '10px'}}>

                <Typography sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0px',
                    margin: '15px',
                    color: '#1C5E7C',
                    fontWeight: 450,
                    fontSize: '19px',

                }}>Slots</Typography>

                <Grid container spacing={2} xs={12} sm={12} md={12} lg={12} sx={{
                    marginTop: {xs: 2, sm: 2, md: 2, lg: 2},
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '2px',
                }}>
                    {slotData.map((item, index) => (
                        <Grid
                            container
                            key={index}
                            sx={{
                                width: '100%',
                                height: '60px',
                                backgroundColor: '#EAF5FC',
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: '15px',
                                alignItems: 'center',
                                padding: {xs: 0.4}
                            }}
                        >
                            <Grid item xs={9} md={3.5} lg={3.5}>
                                <Typography sx={{
                                    fontSize: '14px',
                                    color: '#1C5D7C',
                                    marginLeft: '10px'
                                }}>{item.name}</Typography>
                            </Grid>

                            <Grid
                                item xs={3} md={1.5} lg={1.5}
                                sx={{
                                    width: '70px',
                                    height: '28px',
                                    border: '1px solid #2D9DE5',
                                    borderRadius: '15px',
                                    fontSize: '14px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: '#1C5D7C',
                                }}
                            >
                                {item.value}
                            </Grid>

                            <Grid
                                item xs={10} md={5} lg={5}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft:'6px'
                                }}
                            >
                                <EventIcon sx={{fontSize: '20px', marginRight: '5px', color: '#2D9DE5'}}/>
                                <Typography sx={{fontSize: {lg:'14px', xs:'12px'}, marginRight: '10px', color: '#1C5D7C'}}>
                                    {item.date}
                                </Typography>
                                <ScheduleIcon sx={{fontSize: '20px', marginRight: '5px', color: '#2D9DE5'}}/>
                                <Typography sx={{fontSize: {lg:'14px', xs:'12px'}, color: '#1C5D7C'}}>{item.time}</Typography>
                            </Grid>

                            <Grid
                                item xs={1.5} md={1} lg={1}
                                style={{marginTop: '-5px'}}>
                                <IconButton aria-label="delete" onClick={() => {
                                }}>
                                    <CloseIcon sx={{color: '#2D9DE5', fontSize: 2}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <div></div>

                    <CommonButton
                        sx={{width: '100px'}}
                        onClick={onClose}
                        name={'close'}
                        variant={'outline'}
                    />
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

export default SlotsViewModal;