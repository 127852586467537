import React, { useState } from "react";
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toggle from "common/components/Toggle/Toggle";
import MDBox from "common/components/MDBox";
import Item from "components/Item";
import Category from "components/Category";

const toggleData = [
  {
    view: "Item",
    value: "item",
  },
  {
    view: "Category",
    value: "category",
  },
];

function ManageStock( ) {
    const [onAlignment, setOnAlignment] = useState("item")

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setOnAlignment(newAlignment);
    }
  };

  return (
    <WideCard>
      <Typography sx={{ color: "#1C5E7C", fontWeight: 410, fontSize: "18px" }}>
        Manage Stock
      </Typography>
      <Box>
        <Divider sx={{ borderBottom: "1.3px solid black" }} />
      </Box>

      <Toggle
        data={toggleData}
        value={onAlignment}
        onChange={handleAlignment}
      />

      <MDBox>
        {onAlignment === "item" ? (
          <Item />
        ) : onAlignment === "category" ? (
          <Category/>
        ) : null}
      </MDBox>

    </WideCard>
  );
}
export default ManageStock;
