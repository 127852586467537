import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {CardContent} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {generateDate} from "common/utils/CommonFunctions";
import MDTable from "common/components/MDTable";
import MDBox from "common/components/MDBox";
import {useDispatch, useSelector} from "react-redux";
import {getLocationWiseIncome} from 'services/report/report';

const tableColumns = [
    {field: "id", headerName: "#", accessor: "id", textAlign: 'left'},
    {field: "location", headerName: "Location", accessor: "location", textAlign: 'left'},
    {field: "no_ofPatient", headerName: "Number Of Patient", accessor: "no_ofPatient", textAlign: 'left'},
    {field: "charged_amount", headerName: "Charge Amount", accessor: "charged_amount", textAlign: 'left'},
    {field: "paid_amount", headerName: "Paid Amount", accessor: "paid_amount", textAlign: 'left'}
];

export default function LocationWiseIncomeReport() {

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([])
    const [searchDetails, setSearchDetails] = useState({ startDate: '', endDate: '' });
    const incomeDetails = useSelector(state => state.LocationWiseIncomeSlice.locationWiseIncome);

    useEffect(() => {
        if (searchDetails.startDate && searchDetails.endDate) {
            dispatch(getLocationWiseIncome(searchDetails));
        }
    }, [searchDetails, dispatch]);

    useEffect(()=> {
        if(incomeDetails.isSuccess && !incomeDetails.isLoading){
            const array = [];
            incomeDetails.data?.forEach((val, index)=> {
                array.push({
                    id: ( 1 + index ),
                    location: val.location,
                    no_ofPatient: val.noOfPatient,
                    charged_amount: val.chargedAmount,
                    paid_amount: val.paidAmount
                })
            })
            setTableData(array)
        }
    },[incomeDetails.data])

    return(
        <Card sx={{width: '100%'}}>
            <CardContent>
                <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Location Wise Income Report</Typography>

                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black'}}/>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}> 
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                            label="From"
                            value={searchDetails.startDate}
                            onChange={(date) => {
                                setSearchDetails((prev) => ({
                                    ...prev,
                                    startDate: generateDate(date),
                                }));
                            }}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                        label="To"
                        value={searchDetails.endDate}
                        onChange={(date) => {
                            setSearchDetails((prev) => ({
                            ...prev,
                            endDate: generateDate(date),
                            }));
                        }}
                        />
                    </LocalizationProvider>
                </Box>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable
                        tableData={tableData}
                        tableColumns={tableColumns}    
                        isSearch={false}
                    />
                </MDBox>
            </CardContent>
        </Card>
    )
}
