import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const deleteChair = createAsyncThunk('deleteChair', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/chair/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


