import { Box } from "@mui/system";
import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Modal";
import TextInput from "common/components/TextInput/TextInput";
import React, { useEffect } from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CommonButton from "common/components/Button";
import IconButton from "@mui/material/IconButton";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import {
  sendSuccessNotification,
  sendWarningNotification,
} from "common/components/Notification/SendNotification";
import { getCategory, saveCategory } from "services/category/category";
import { useDispatch, useSelector } from "react-redux";
import { updateCategory } from "services/category/category";

export default function CategoryAddModal({
  open,
  onClose,
  isView = false,
  categoryData,
}) {
  const dispatch = useDispatch();
  const categorySave = useSelector((state) => state.SaveCategorySlice.category);
  const [description, setDescription] = useState(
    isView ? categoryData.description : ""
  );
  const categoryUpdate = useSelector(
    (state) => state.UpdateCategorySlice.category
  );
  const [viewMode, setViewMode] = useState(isView);
  const [editMode, setEditMode] = useState(!viewMode);
  const [saveLoader, setSaveLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const isComplete = description !== "";

  const handleAction = () => {
    const data = {
      description: description,
    };

    if (viewMode) {
      setUpdateLoader(true);
      dispatch(
        updateCategory({ id: categoryData.categoryId, categoryUpData: data })
      );
    } else {
      setSaveLoader(true);
      dispatch(saveCategory(data));
    }
  };

  useEffect(() => {
    if (saveLoader) {
      setSaveLoader(false);
      if (categorySave.isSuccess && !categorySave.isLoading) {
        dispatch(getCategory());
        sendSuccessNotification({
          title: "Success",
          message: "Category Saved Success",
        });
        onClose();
      } else if (!categorySave.isSuccess && !categorySave.isLoading) {
        sendWarningNotification({
          title: "Warning",
          message: "Category Saved Warning",
        });
      }
    }
  }, [categorySave.data]);

  useEffect(() => {
    if (updateLoader) {
      setUpdateLoader(false);
      if (categoryUpdate.isSuccess && !categoryUpdate.isLoading) {
        setViewMode(true);
        setEditMode(false);
        dispatch(getCategory());
        onClose();
        sendSuccessNotification({
          title: "Success",
          message: "Category Update Success",
        });
      } else if (!categoryUpdate.isSuccess && !categoryUpdate.isLoading) {
        sendWarningNotification({
          title: "Error",
          message: "Update Error",
        });
      }
    }
  }, [categoryUpdate.data]);

  return (
    <>
      <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
        <MDBox sx={{ margin: "10px" }}>
          <div style={{ overflow: "hidden" }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <Box sx={{ width: "500px", margin: "0 auto" }}></Box>
              </Grid>
            </Grid>
          </div>

          <Typography
            sx={{
              color: "#1C5E7C",
              fontWeight: 450,
              fontSize: "16px",
              marginBottom: "25px",
              position: "absolute",
              top: "15px",
              left: "15px",
            }}
          >
            {""}
            {!viewMode ? "Add Category" : "Category"}
          </Typography>

          <Box sx={{ marginTop: "20px" }}>
            <TextInput
              placeholder="Description"
              disabled={!editMode}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                position: "absolute",
                top: "8px",
                left: "70px",
              }}
            >
              {viewMode && (
                <IconButton aria-label="delete">
                  <DriveFileRenameOutlineIcon
                    fontSize={"small"}
                    sx={{ marginLeft: "40px" }}
                    onClick={() => {
                      if (editMode) {
                        setEditMode(false);
                      } else {
                        setEditMode(true);
                      }
                    }}
                  />
                </IconButton>
              )}
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <div style={{ margin: "5px" }}>
              <CommonButton
                name={"Cancel"}
                variant={"outline"}
                onClick={() => {
                  onClose();
                }}
              />
            </div>
            <div style={{ margin: "5px" }}>
              {editMode && (
                <CommonButton
                  name={viewMode ? "Update" : "Add"}
                  variant={"Fill"}
                  onClick={handleAction}
                  disabled={!isComplete}
                />
              )}
            </div>
          </Box>
        </MDBox>
      </ModalComponent>
    </>
  );
}
