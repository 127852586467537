import React, {useEffect, useRef, useState} from 'react';
import MDBox from "common/components/MDBox";
import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import DropDown from "common/components/DropDown/DropDown";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Toggle from "common/components/Toggle/Toggle";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches} from "services/branch/branch";
import {getAllProcedures, postProcedure, saveExcelSheet} from "services/procedure/procedure";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import PropTypes from "prop-types";
import ManageProceduresUpdateModal from "../ManageProceduresUpdateModal";

const toggleData = [
    {
        view: 'Insert',
        value: "insert",
    },
    {
        view: "Upload File",
        value: "uploadFile",
    },
];

function ManageProceduresAddModal({open, onClose}) {
    const dispatch = useDispatch();
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const saveProcedure = useSelector(state => state.SaveProcedureSlice.procedure);
    const saveExcelSheetData = useSelector(state => state.SaveExcelSheetSlice.procedure);
    const [branch, setBranch] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [onAlignment, setOnAlignment] = useState("insert");
    const fileInputRef = useRef(null);
    const [procedure, setProcedure] = useState('');
    const [type, setType] = useState('');
    const [fees, setFees] = useState('');
    const [cost, setCost] = useState('');
    const [extra, setExtra] = useState('');
    const [totalCharge, setTotalCharge] = useState('');
    const [saveLoader, setSaveLoader] = useState(false);
    const [feesError, setFeesError] = useState('');
    const [costError, setCostError] = useState('');
    const [extraError, setExtraError] = useState('');
    const [totalChargeError, setTotalChargeError] = useState('');
    const [fileSelected, setFileSelected] = useState(null);
    const [excelSaveLoader, setExcelSaveLoader] = useState(false)
    const [selectedValue, setSelectedValue] = useState(null);
    const [portionView, setPortionView] = useState(false);
    const [dentalPortion, setDentalPortion] = useState("");
    const [doctorPortion, setDoctorPortion] = useState("");
    const [doctorPortionError, setDoctorPortionError] = useState("");
    const [dentalPortionError, setDentalPortionError] = useState("");

    const clearForm = () => {
        setSelectedBranch(null)
        setProcedure('');
        setType('');
        setFees('');
        setCost('');
        setExtra('');
        setTotalCharge('');
        setSelectedValue(null);
        setDentalPortion("");
        setDoctorPortion("");
    };

    const handleProcedureChange = (e) => {
        setProcedure(e.target.value);
    };

    const handleTypeChange = (e) => {
        setType(e.target.value);
    };

    const handleFeesChange = (e) => {
        setFees(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setFeesError("Invalid Fee");
        } else {
            setFeesError("");
        }
    };

    const handleCostChange = (e) => {
        setCost(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setCostError("Invalid Fee");
        } else {
            setCostError("");
        }
    };

    const handleExtraChange = (e) => {
        setExtra(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setExtraError("Invalid Fee");
        } else {
            setExtraError("");
        }
    };

    const handleTotalChargeChange = (e) => {
        setTotalCharge(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setTotalChargeError("Invalid Fee");
        } else {
            setTotalChargeError("");
        }
    };

    const handleDocPortionChange = (e) => {
        setDoctorPortion(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setDoctorPortionError("Invalid Doctor Portion");
        } else {
            setDoctorPortionError("");
        }
    };

    const handleDentalPortionChange = (e) => {
        setDentalPortion(e.target.value);
        if (!/^\d+(\.\d{1,2})?$/.test(e.target.value)) {
            setDentalPortionError("Invalid Dental Portion");
        } else {
            setDentalPortionError("");
        }
    };

    const isDataComplete = selectedBranch && procedure && type && fees && cost && extra && totalCharge && selectedValue &&
        ((!portionView && !feesError && !costError && !extraError && !totalChargeError) ||
            (portionView && doctorPortion && dentalPortion && !doctorPortionError && !dentalPortionError && !feesError && !costError && !extraError && !totalChargeError));

    const isComplete = selectedBranch && fileSelected;

    const savedProcedure = () => {
        let doctorPayMethodValue;
        if (selectedValue === "portion") {
            doctorPayMethodValue = "portion";
        } else {
            doctorPayMethodValue = "percentage";
        }

        const proData = {
            procedureName: procedure,
            doctorPayMethod: doctorPayMethodValue,
            dentalPortion: selectedValue === "portion" ? dentalPortion : 0,
            doctorPortion: selectedValue === "portion" ? doctorPortion : 0,
            type: type,
            fees: fees,
            labCost: cost,
            extraCons: extra,
            totalCharge: totalCharge,
            branchId: selectedBranch.branchId
        };
        
        setSaveLoader(true);
        dispatch(postProcedure(proData));
    };

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (saveProcedure.isSuccess && !saveProcedure.isLoading) {
                dispatch(getAllProcedures(selectedBranch.branchId));
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Procedure Saved Success',
                })
                onClose();
                clearForm();

            } else if (!saveProcedure.isSuccess && !saveProcedure.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Procedure Saved Warning'
                })
            }
        }
    }, [saveProcedure.data]);

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(data.map((branch) => ({value: branch, label: branch.name})));
            }
        }
    }, [getBranchData.data]);

    const handleChooseClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile.name.toLowerCase().endsWith('.xlsx')) {
            alert('Invalid file format. Please upload a file with the ".xlsx" extension.');
            return;
        }
        setFileSelected(selectedFile);
    };

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setOnAlignment(newAlignment);
        }
    };

    const handleFileUpload = () => {
        const formdata = new FormData();
        formdata.append('file', fileSelected);
        formdata.append('branchId', selectedBranch.branchId);

        setExcelSaveLoader(true);
        dispatch(saveExcelSheet(formdata));
    }

    useEffect(() => {
        if (excelSaveLoader) {
            setExcelSaveLoader(false);
            if (saveExcelSheetData.isSuccess && !saveExcelSheetData.isLoading) {
                dispatch(getAllProcedures(selectedBranch.branchId));
                sendSuccessNotification({
                    title: 'Success',
                    message: 'ExcelSheet Saved Success',
                })
                onClose();
                clearForm();
            } else if (!saveExcelSheetData.isSuccess && !saveExcelSheetData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'ExcelSheet Save Error'
                })
            }
        }
    }, [saveExcelSheetData.data]);

    const docPayTypeArray = [
        {value: "percentage", label: "percentage"},
        {value: "portion", label: "portion"},

    ]

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="sm">
            <MDBox open={open} onClose={onClose} maxWidth="sm" sx={{margin: '15px'}}>
                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '870px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Typography sx={{
                    color: '#1C5E7C',
                    fontWeight: 450,
                    fontSize: '19px',
                    marginBottom: '25px',
                    position: 'absolute',
                    top: '15px',
                    left: '15px'
                }}> Add Procedure </Typography>

                <MDBox sx={{marginY: 2}}>
                    <DropDown
                        label={'Clinic'}
                        options={branch}
                        value={selectedBranch}
                        onChange={(value) => {
                            setSelectedBranch(value);
                            dispatch(getAllProcedures);
                        }}
                    />
                </MDBox>

                <MDBox sx={{marginBottom: '15px'}}>
                    <Toggle sx={{width: '100%'}} data={toggleData} value={onAlignment} onChange={handleAlignment}/>
                </MDBox>

                {onAlignment === "insert" ? (
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput placeholder={'Procedure'}
                                           value={procedure}
                                           onChange={handleProcedureChange}

                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DropDown
                                    label={'Doctor Pay Type'}
                                    options={docPayTypeArray}
                                    value={selectedValue}
                                    onChange={(value) => {
                                        setSelectedValue(value);
                                        if (value === 'portion') {
                                            setPortionView(true);
                                        } else {
                                            setPortionView(false);
                                        }
                                    }}
                                />
                            </Grid>

                            {portionView && (
                                <>
                                    <Grid item xs={4} sm={4} md={3} lg={3}>
                                        <TextInput placeholder={'Doctor Portion'}
                                                   value={doctorPortion}
                                                   onChange={handleDocPortionChange}
                                                   error={doctorPortionError !== ""}
                                        />
                                        {doctorPortionError &&
                                            <div style={{color: 'red', fontSize: '12px'}}>{doctorPortionError}</div>}
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={3} lg={3}>
                                        <TextInput placeholder={'Dental Portion'}
                                                   value={dentalPortion}
                                                   onChange={handleDentalPortionChange}
                                                   error={dentalPortionError !== ""}
                                        />
                                        {dentalPortionError &&
                                            <div style={{color: 'red', fontSize: '12px'}}>{dentalPortionError}</div>}
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput placeholder={'Type'}
                                           value={type}
                                           onChange={handleTypeChange}

                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextInput placeholder={'Fees'}
                                           value={fees}
                                           onChange={handleFeesChange}
                                           error={feesError !== ""}
                                />
                                {feesError && <div style={{color: 'red', fontSize: '12px'}}>{feesError}</div>}
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextInput placeholder={'Lab Cost'}
                                           value={cost}
                                           onChange={handleCostChange}
                                           error={costError !== ""}

                                />
                                {costError && <div style={{color: 'red', fontSize: '12px'}}>{costError}</div>}
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextInput placeholder={'Extra Cons'}
                                           value={extra}
                                           onChange={handleExtraChange}
                                           error={extraError !== ""}

                                />
                                {extraError && <div style={{color: 'red', fontSize: '12px'}}>{extraError}</div>}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextInput placeholder={'Total Charge'}
                                           value={totalCharge}
                                           onChange={handleTotalChargeChange}
                                           error={totalChargeError !== ""}
                                />
                            </Grid>
                            {totalChargeError && <div
                                style={{color: 'red', fontSize: '12px', marginLeft: '15px'}}>{totalChargeError}</div>}

                        </Grid>

                        <MDBox sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '15px'}}>
                            <CommonButton
                                onClick={savedProcedure}
                                name={'+ Add '}
                                variant={'Fill'}
                                disabled={!isDataComplete}
                            />
                        </MDBox>
                    </div>

                ) : onAlignment === "uploadFile" ? (
                    <Box>
                        <div
                            style={{
                                border: '1px dotted #C6C6C6',
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                cursor: 'pointer',
                            }}
                            onClick={handleChooseClick}
                        >
                            <BackupOutlinedIcon fontSize="large" color="disabled"/>
                            <Typography variant="body1" color="textSecondary"
                                        style={{marginTop: '10px', fontSize: '14px'}}>
                                Drag & Drop or
                                <span style={{cursor: 'pointer', color: '#2D9DE5'}}>
                                {' '}choose{' '}
                            </span>
                                file
                            </Typography>

                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{display: 'none'}}
                                onChange={handleFileChange}
                            />
                        </div>

                        <MDBox sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '15px'}}>
                            <CommonButton
                                name={'+ Add'}
                                variant={'Fill'}
                                onClick={handleFileUpload}
                                disabled={!isComplete}
                            />
                        </MDBox>
                    </Box>
                ) : null}

            </MDBox>
        </ModalComponent>
    );
}

ManageProceduresAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ManageProceduresAddModal;
