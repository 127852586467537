import React, {useState} from "react";
import TblCard from "common/components/TblCard";
import './styles.css'
import PropTypes from "prop-types";
import MDBox from "common/components/MDBox";
import TextInput from "common/components/TextInput/TextInput";
import SearchIcon from "@mui/icons-material/Search";

const MobileList = ({columns, dataJson, searchKey, isSearch}) => {

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filterData = dataJson.filter((row) =>
        columns.some((column) => {
            const value = row[column.s_key] ? row[column.s_key] : row[column.field];
            if (value !== null && value !== undefined) {
                return (
                    value.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
                );
            }
            return false;
        })
    );

    const filteredData = filterData.filter((row) =>
        columns.some(
            (column) =>
                row[column.field]
                    .toString()
                    .toLowerCase()
        )
    );

    return (
        <div>
            <MDBox sx={{marginBottom: '7px'}}>
                {isSearch &&
                    <TextInput
                        placeholder={'Search'}
                        onChange={handleSearch}
                        value={searchQuery}
                        endIcon={<SearchIcon/>}
                    />
                }
            </MDBox>
            {filteredData.map((val) => {
                return (
                    <div className="my-class" key={val.id}>
                        <TblCard columns={columns} dataJson={val} searchKey={searchKey}/>
                    </div>
                );
            })}
        </div>
    );
};

MobileList.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
        })
    ).isRequired,
    dataJson: PropTypes.array.isRequired,
    searchKey: PropTypes.string.isRequired,
    isSearch:PropTypes.bool,
};
export default MobileList;

