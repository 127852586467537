import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {CardContent} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {generateDate} from "common/utils/CommonFunctions";
import MDTable from "common/components/MDTable";
import MDBox from "common/components/MDBox";
import DropDown from "common/components/DropDown/DropDown";
import {useDispatch, useSelector} from "react-redux";
import {getDoctorWiseSales} from 'services/report/report';
import {getAllBranches} from "services/branch/branch";


const tableColumns = [
    {field: "id", headerName: "#", accessor: "id", textAlign: 'left'},
    {field: "name", headerName: "Name", accessor: "name", textAlign: 'left'},
    {field: "noOfPatient", headerName: "Number Of Patient", accessor: "noOfPatient", textAlign: 'left'},
    {field: "doctorCharge", headerName: "Doctor Charge", accessor: "doctorCharge", textAlign: 'left'},
    {field: "myDentistCharge", headerName: "My Dentist Charge", accessor: "myDentistCharge", textAlign: 'left'},
    {field: "extraCharge", headerName: "Extra Charge", accessor: "extraCharge", textAlign: 'left'},
    {field: "labCost", headerName: "Lab Cost", accessor: "labCost", textAlign: 'left'},
    {field: "doctorPercentage", headerName: "Percentage", accessor: "doctorPercentage", textAlign: 'left'},
];

export default function DoctorWiseSalesReport() {

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([])
    const [branch, setBranch] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [searchDetails, setSearchDetails] = useState({ startDate: '', endDate: '' });
    const doctorWiseSales = useSelector(state => state.DoctorWiseSalesSlice.doctorWiseSales);
    const getBranchData = useSelector((state) => state.BranchSlice.branch);

    const handleBranchChange = (value) => {
        setSelectedBranch(value);
        setSearchDetails((prev) => ({
            ...prev,
            branchDetails: value
        }));    
    };

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(
                    data.map((branch) => ({value: branch, label: branch.name}))
                );
            }
        }
    }, [getBranchData.data]);

    useEffect(() => {
        if (searchDetails.startDate && searchDetails.endDate && searchDetails.branchDetails ) {
            dispatch(getDoctorWiseSales(searchDetails))
        }
    }, [searchDetails, dispatch]);

    useEffect(()=> {
        if(doctorWiseSales.isSuccess && !doctorWiseSales.isLoading){
            const array = [];
            doctorWiseSales.data?.forEach((val, index)=> {
                array.push({
                    id: ( 1 + index ),
                    name: val.name,
                    noOfPatient: val.noOfPatient,
                    doctorPercentage: val.doctorPercentage,
                    doctorCharge: val.doctorCharge,
                    myDentistCharge: val.myDentistCharge,
                    extraCharge: val.extraCharge,
                    labCost: val.labCost,

                })
            })
            setTableData(array)
        }
    },[doctorWiseSales.data])

    return(
        <Card sx={{width: '100%'}}>
            <CardContent>
                <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Doctor Wise Sales Report</Typography>

                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black'}}/>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}> 
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                            label="From"
                            value={searchDetails.startDate}
                            onChange={(date) => {
                                setSearchDetails((prev) => ({
                                    ...prev,
                                    startDate: generateDate(date),
                                }));
                            }}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                        label="To"
                        value={searchDetails.endDate}
                        onChange={(date) => {
                            setSearchDetails((prev) => ({
                            ...prev,
                            endDate: generateDate(date),
                            }));
                        }}
                        />
                    </LocalizationProvider>

                    <DropDown
                            label={"Clinic"}
                            options={branch}
                            value={selectedBranch}
                            onChange={handleBranchChange}
                        />
                </Box>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable
                        tableData={tableData}
                        tableColumns={tableColumns}    
                        isSearch={false}
                    />
                </MDBox>
            </CardContent>
        </Card>
    )
}
