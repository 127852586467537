import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";
export const getPatientCheck = createAsyncThunk('api', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/booking`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deletePatientCheck = createAsyncThunk('delete-patient', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/booking/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
