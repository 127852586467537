import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import Divider from "@mui/material/Divider";
import React, {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import RoomNoContent from "components/RoomNoViewContent";
import {useDispatch, useSelector} from "react-redux";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {
    getAllBranchesWithChairCount,
    getBranchById,
    saveBranchWithChairs,
    updateBranchWithChairs
} from "services/branch/branch";
import {deleteChair} from 'services/chair/chair'
import Toggle from "common/components/Toggle/Toggle";

const toggleData = [
    {
        view: 'Active',
        value: 1,
    },
    {
        view: "Deactiva",
        value: 0,
    },
];

function BranchAddModal({open, onClose ,isView=false , branchDetails}) {

    const dispatch = useDispatch();
    const [branchId , setBranchId] = useState(branchDetails);
    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [viewMode,setViewMode] = useState(isView);
    const [branchLocation, setBranchLocation] = useState('')
    const [branchName, setBranchName] = useState('');
    const [branchCode,setBranchCode] = useState('');
    const [roomNo , setRoomNo] = useState('');
    const [newRooms , setNewRooms] = useState([]);
    const [currentRooms , setCurrentRooms] = useState([]);
    const [editMode, setEditMode] = useState(!viewMode);
    const [onAlignment, setOnAlignment] = useState(0);
    const savedBranchData = useSelector(state => state.SaveBranchWithChairsSlice.branch);
    const branchByIdData = useSelector(state => state.BranchByIdSlice.branch);
    const updateBranchData = useSelector(state => state.UpdateBranchWithChairSlice.branch);
    const deleteChairData = useSelector(state => state.DeleteChairSlice.chair);
    const auth = useSelector((state) => state.AuthSlice.auth);

    const isComplete = branchLocation && branchName && branchCode && (isView || newRooms.length > 0);

    const isCompleteRoom = roomNo;

    const deleteRoomFromArray = (val)=>{
        const array = [...newRooms];
        array.splice(val,1)
        setNewRooms(array);
    }
    const deleteCurrentRoomFromArray = (val)=>{
        dispatch(deleteChair(val))
    }
    const handleClose = () => {
        onClose();
    };

    const clearFields = ()=>{
        setBranchName('');
        setBranchCode('');
        setBranchLocation('')
        setNewRooms([]);
    }

    const handleAction = ()=>{

        const branchData = {
            name: branchName,
            location: branchLocation,
            branchCode:branchCode,
            countryId:auth.data?.countryId,
            chairs: newRooms,
        }
        const updateBranchData = {
            name: branchName,
            location: branchLocation,
            branchCode:branchCode,
            countryId:auth.data?.countryId,
            chairs: newRooms,
            emailStatus: onAlignment,
        }

        if(viewMode){
            setUpdateLoader(true);
            dispatch(updateBranchWithChairs({id:branchId,branchData: updateBranchData}));
        }else {
            setSaveLoader(true);
            dispatch(saveBranchWithChairs(branchData));
        }
        
    }

    useEffect(() => {
        if (saveLoader){
            setSaveLoader(false);
            if (savedBranchData.isSuccess && !savedBranchData.isLoading) {
                clearFields();
                setBranchId(savedBranchData.data.branchId);
                setViewMode(true);
                dispatch(getAllBranchesWithChairCount());
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Clinic Saved Success',
                })
            }  else if (savedBranchData.errorMessage === "Branch already exists") {
                sendWarningNotification({
                  title: "Warning",
                  message: "Clinic already exists",
                });
            }else if (!savedBranchData.isSuccess && !savedBranchData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Save Error'
                })
            }
        }
    }, [savedBranchData.data, savedBranchData.errorMessage]);

    useEffect(() => {
        if(deleteChairData.isSuccess && !deleteChairData.isLoading){
            dispatch(getBranchById(branchId));
            dispatch(getAllBranchesWithChairCount());
        }
    }, [deleteChairData.data]);

    useEffect(() => {
        if (updateLoader){
            setUpdateLoader(false);
            if (updateBranchData.isSuccess && !updateBranchData.isLoading) {
                clearFields();
                dispatch(getBranchById(branchId));
                dispatch(getAllBranchesWithChairCount());
                setEditMode(false);
                setViewMode(true);
                handleClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Clinic Update Success',
                })
            } else if (!updateBranchData.isSuccess && !updateBranchData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Update Error'
                })
            }
        }
    }, [updateBranchData.data]);

    useEffect(() => {
        if(branchByIdData.isSuccess){
            const data = branchByIdData.data;
            setOnAlignment(data.emailStatus);
            setBranchLocation(data.location);
            setBranchName(data.name);
            setBranchCode(data.branchCode);
            const array =  data.chairs.map((chair)=>({chairNo:chair.chairNo, id: chair.chairId}));
            setCurrentRooms(array);
        }
    }, [branchByIdData.data]);

    useEffect(() => {
        if(viewMode){
            dispatch(getBranchById(branchId))
        }else {
            setCurrentRooms([]);
            setBranchName('');
            setBranchLocation('');
            setBranchCode('');
        }
        setEditMode(!viewMode);

    }, [viewMode]);

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setOnAlignment(newAlignment);
        }
    };

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin:'10px'}}>
                <Box>
                    <div>
                        <Typography sx={{
                            color: '#1C5E7C',
                            fontWeight: 450,
                            fontSize: '16px',
                            marginBottom: '25px',
                            position: 'absolute',
                            top: '15px',
                            left: '15px'
                        }}> {viewMode? 'Clinic' : 'Add Clinic'} </Typography>
                    </div>
                    <div style={{
                        position: 'absolute',
                        top: '8px',
                        left: '70px'}}
                    >
                        {viewMode && <IconButton aria-label="delete">
                            <DriveFileRenameOutlineIcon fontSize={'small'} onClick={() => {
                                if(editMode){
                                    setEditMode(false);
                                }else {
                                    setEditMode(true);
                                }
                            }}/>
                        </IconButton>}
                    </div>
                </Box>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{marginY:'10px'}}>
                            <TextInput
                                placeholder={'Clinic Name'}
                                value={branchName}
                                onChange={(e)=>{setBranchName(e.target.value)}}
                                disabled={!editMode}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{marginY:'10px'}}>
                            <TextInput
                                placeholder={'Clinic Code'}
                                value={branchCode}
                                onChange={(e)=>{setBranchCode(e.target.value)}}
                                disabled={!editMode}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{marginY:'10px'}}>
                            <TextInput
                                placeholder={'Location'}
                                value={branchLocation}
                                onChange={(e)=>{setBranchLocation(e.target.value)}}
                                disabled={!editMode}
                            />
                        </Box>
                    </Grid>
                    {viewMode &&
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <MDBox sx={{margin: '0px', display: 'flex', justifyContent: 'space-between'}}>
                                <Typography sx={{
                                    color: '#9296ac',
                                    fontWeight: 100,
                                    fontSize: '14px',
                                }}>Email Status</Typography>
                                <Toggle isDisable={!editMode} sx={{width: '100%'}} data={toggleData} value={onAlignment} onChange={handleAlignment}/>
                            </MDBox>
                        </Grid>
                    }

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <TextInput
                                placeholder={'Room No'}
                                value={roomNo}
                                onChange={(e)=>{setRoomNo(e.target.value)}}
                                disabled={!editMode}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box>
                            <CommonButton
                                name={'Add  Room'}
                                variant={'Fill'}
                                fullWidth
                                onClick={()=>{
                                    if(roomNo !== ''){
                                        const exists = newRooms.some(room => room.chairNo == roomNo );
                                        const exists1 = currentRooms.some(room => room.chairNo == roomNo );
                                        if (!exists && !exists1){
                                            const array = [...newRooms];
                                            array.push({chairNo:roomNo});
                                            setNewRooms(array);
                                            setRoomNo('');
                                        }else {
                                            sendWarningNotification({
                                                title: 'Error',
                                                message: 'Room number already exists!'
                                            })
                                        }
                                    }
                                }}
                                disabled={!editMode || !isCompleteRoom}
                            />
                        </Box>

                    </Grid>
                </Grid>
                <Grid container spacing={1}>

                </Grid>
                <Box sx={{marginY:'10px'}}>
                    <Typography sx={{fontSize:"12px" , fontWeight:500 ,color:'#100f0f',margin:'5px' }}>
                        Room No
                    </Typography>
                    <Divider sx={{borderBottom: '1.3px solid black', marginTop: '5px', marginBottom: '5px'}}/>
                </Box>
                <Box>
                    {newRooms.map((room,index)=>(
                        <RoomNoContent
                            key={index}
                            isNew={true}
                            room={room}
                            id={index}
                            mode={!editMode}
                            deleteRoom={(val)=>{deleteRoomFromArray(val)}}
                        />
                    ))}
                </Box>
                <Box>
                    {currentRooms.map((room,index)=>(
                        <RoomNoContent
                            key={index}
                            isNew={false}
                            room={room}
                            id={index}
                            mode={!editMode}
                            deleteRoom={(val)=>{deleteCurrentRoomFromArray(val)}}
                        />
                    ))}
                </Box>
                <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                    <div style={{margin:'5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={()=>{
                                handleClose();
                            }}
                        />
                    </div>
                    <div style={{margin:'5px'}}>
                        {editMode &&
                            <CommonButton
                                name={'Confirm'}
                                variant={'Fill'}
                                disabled={!editMode || !isComplete}
                                onClick={handleAction}
                            />
                        }

                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

BranchAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView:PropTypes.bool,
    branchDetails:PropTypes.object,

};

export default BranchAddModal;
