import React, {useEffect, useState} from 'react';
import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CheckFilterPopup from "components/CheckFilterPopup";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import CommonButton from "common/components/Button";
import PrintBillModal from "components/PrintBillModal";
import {Popover} from "@material-ui/core";
import ConfirmAlert from "components/ConfirmAlert";
import {patientAdd} from "config/images";
import {useDispatch, useSelector} from "react-redux";
import {deletePatientCheck, getPatientCheck} from "services/PatientCheck/patientCheck";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import DeleteIcon from "@mui/icons-material/Delete";

function PatientCheck() {
    const dispatch = useDispatch();
    const patientCheckData = useSelector(state => state.PatientCheckSlice.patientCheck);
    const DltPatientCheckData = useSelector(state => state.DeletePatientCheckSlice.patientCheck);
    const [filterOpen, setFilterOpen] = useState(false);
    const [billOpen, setBillOpen] = useState(false);
    const [paidBillOpen, setPaidBillOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [selectedCheck, setSelectedCheck] = useState();

    const openAlert = () => {
        setIsAlertOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    //important
    const paidBillPopup = () => {
        setPaidBillOpen(true)
    }

    const billPopup = () => {
        setBillOpen(true)
    }

    const closeBill = () => {
        setBillOpen(false)
        setPaidBillOpen(false)
        setIsAlertOpen(false);
    }

    const openFilterPopup = () => {
        setFilterOpen(true)
    }

    const closeFilter = () => {
        setFilterOpen(false)
    }

    useEffect(() => {
        dispatch(getPatientCheck());
    }, []);

    const handleDelete = (id) => {
        setAnchorEl(null);
        setDeleteLoader(true)
        dispatch(deletePatientCheck(id));
    };

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false)
            if (DltPatientCheckData.isSuccess) {
                dispatch(getPatientCheck())
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Patient Deleted Success',
                })
                setIsAlertOpen(false);
            } else if (!DltPatientCheckData.isSuccess && !DltPatientCheckData.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Patient Deleted Warning'
                })
            }
        }
    }, [DltPatientCheckData.data]);

    useEffect(() => {
        if (patientCheckData.isSuccess) {
            const data = patientCheckData?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => ({
                        id: val.booking_id,
                        pName: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Avatar sx={{backgroundColor: '#EAF5FC', marginRight: '5px'}}>
                                    <IconButton sx={{color: '#2D9DE5'}}>
                                        <PersonIcon fontSize={"large"}/>
                                    </IconButton>
                                </Avatar>

                                <div style={{marginTop: '10px'}}>
                                    {val.patient?.title + " " + val.patient?.firstName + " " + val.patient?.lastName}
                                </div>
                            </div>
                        ),
                        doctor: val.slot?.doctor?.title + " " + val.slot?.doctor?.name,
                        roomNo: (
                            <div style={{
                                height: '30px',
                                width: '65px',
                                border: '1px solid #2D9DE5',
                                borderRadius: '15px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {val.slot?.chair?.chairNo}
                            </div>
                        ),
                        channelDate: val.slot?.date,
                        channelTime: val.slot?.startTime,
                        action: (
                            <div style={{display: 'flex', flexDirection: 'row'}}>

                                <IconButton onClick={() => {
                                    setSelectedCheck(val)
                                   openAlert();
                                }}>
                                    <DeleteIcon sx={{color: '#1C5D7C'}}/>
                                </IconButton>
                            </div>
                        )

                }));
                setTableData(array);
            }
        }
    }, [patientCheckData.isSuccess, patientCheckData.data]);

    const tableColumns = [
        {field: "pName", headerName: "Patient Name", accessor: "pName", textAlign: 'left'},
        {field: "doctor", headerName: "Doctor", accessor: "doctor", textAlign: 'left'},
        {field: "roomNo", headerName: "Room No", accessor: "roomNo", textAlign: 'left'},
        {field: "channelDate", headerName: "Channel Date", accessor: "channelDate", textAlign: 'left'},
        {field: "channelTime", headerName: "Channel Time", accessor: "channelTime", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['pName', 'age'];

    return (
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Appointmnet View</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>
           
            <CheckFilterPopup
                onClose={closeFilter}
                open={filterOpen}
            />

            <MDBox sx={{marginY: 2}}>
                <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            <PrintBillModal
                open={billOpen}
                onClose={closeBill}
                bill={true}
            />

            <PrintBillModal
                open={paidBillOpen}
                onClose={closeBill}
                bill={false}
            />

            <ConfirmAlert
                src={patientAdd}
                open={isAlertOpen}
                onClose={closeBill}
                title="Are you sure"
                subtitle="you want to delete patient ?"
                buttonText={'Delete'}
                onclick={() => {
                    handleDelete(selectedCheck.bookingId)
                }}
            />

        </WideCard>
    );
}

export default PatientCheck;