import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DropDown from "common/components/DropDown/DropDown";
import MDTable from "common/components/MDTable";
import WideCard from "common/components/WideCard";
import React, {useEffect, useState} from "react";
import { itm } from "config/images";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches} from "services/branch/branch";
import {getDailyUsage} from "services/DailyUsage/DailyUsage";
import {generateDate} from "common/utils/CommonFunctions";

export default function DailyUsagesView() {
    const dispatch = useDispatch();
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const DailyUsageData = useSelector(state => state.DailyUsageSlice.dailyUsage);
    const [branch, setBranch] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [date,setDate] = useState(null);
    const [dailyUsageItems,setDailyUsageItems] = useState([]);

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(data.map((branch) => ({value: branch, label: branch.name})));
            }
        }
    }, [getBranchData.data]);

    useEffect(() => {
        if(DailyUsageData.isSuccess && !DailyUsageData.isLoading){
            const data = DailyUsageData?.data;
            if(Array.isArray(data)){
                const array = data.map((item)=>(
                    {
                        item: (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar
                                    sx={{
                                        width: 30,
                                        height: 30,
                                        margin: "0px",
                                        padding: "0px",
                                        backgroundColor: "#EAF5FC",
                                        marginRight: "10px",
                                        marginTop: "8px",
                                    }}
                                    src={itm}
                                />

                                <div style={{ marginTop: "10px" }}>{item.item.itemName}</div>
                            </div>
                        ),
                        qty: item.qty,
                    }
                ));
                setDailyUsageItems(array)
            }
        }
    }, [DailyUsageData.data]);

  const tableColumns = [
    {
      field: "item",
      headerName: "Item",
      accessor: "item",
      textAlign: "left",
    },
    {
      field: "qty",
      headerName: "Qty",
      accessor: "qty",
      textAlign: "left",
    },
  ];
  const searchKey = ["item", "qty"];

  const getDailyUsageData = (dateValue,branchValue)=>{
      if(dateValue && branchValue){
          dispatch(getDailyUsage({branchId:branchValue.branchId,date:generateDate(dateValue)}))
      }
  }

  return (
    <WideCard>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography
            sx={{ color: "#1C5D7C", fontWeight: 410, fontSize: "18px" }}
          >
            Daily Usages View
          </Typography>
          <Box>
            <Divider sx={{ borderBottom: "1.3px solid black" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={3.6} lg={2.6}>
          <DropDown

            label={'Clinic'}
            options={branch}
            onChange={(value) => {
                setSelectedBranch(value)
                getDailyUsageData(date,value);
            }}
            value={ selectedBranch}

          />
        </Grid>
        <Grid item xs={12} sm={4} md={3.6} lg={2.6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: "100%" }}
              label="Date"
              value={date}
              onChange={(date) => {
                  setDate(date)
                  getDailyUsageData(date,selectedBranch);
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MDTable
            tableData={dailyUsageItems}
            tableColumns={tableColumns}
            searchKey={searchKey}
            isSearch={false}
          />
        </Grid>
      </Grid>
    </WideCard>
  );
}
