import { createSlice } from "@reduxjs/toolkit";
import {getActiveDiscount} from "services/discount/discount";

const initialState = {
    discount: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const activeDiscountSlice = createSlice({
    name: "getActiveDiscount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getActiveDiscount.pending, (state) => {
                state.discount.isLoading = true;
            })
            .addCase(getActiveDiscount.fulfilled, (state, { payload }) => {
                state.discount.isLoading = false;
                state.discount.isSuccess = true;
                state.discount.data = payload;
            })
            .addCase(getActiveDiscount.rejected, (state, { payload }) => {
                state.discount.isLoading = false;
                state.discount.isSuccess = false;
                state.discount.errorMessage = payload;
            });
    },
});

export default activeDiscountSlice.reducer;
