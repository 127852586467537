import React, {useEffect, useState, useRef} from "react";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import {transaction, doctor, patientAdd} from "config/images";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {numberFormat} from "common/utils/CommonFunctions";
import {addPayment} from "services/payment/payment";
import ConfirmAlert from "../ConfirmAlert";
import {saveAs} from "file-saver";
import PaymentPdfModal from "../PaymentPdfModal";
import DropDown from "common/components/DropDown/DropDown";
import TextInput from "common/components/TextInput/TextInput";
import {Box, IconButton} from "@mui/material";
import {Add} from "@mui/icons-material";

const textStyle = {
    textAlign: "end",
    color: "#8DAEBD",
    fontSize: "14px",
    marginTop: "10px",
};

function PaymentDoctor({discount, changePage}) {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [total, setTotal] = useState(0);
    const [conform, setConform] = useState(false);
    const [pdfModal, setPdfModal] = useState(false);
    const [dis, setDis] = useState(0);
    const [visitDate, setVisitDate] = useState("yyyy/mm/dd");
    const [doctorCharge, setDoctorCharge] = useState(0);
    const [extraCharge, setExtraCharge] = useState(0);
    const [extraConsCost, setExtraConsCost] = useState(0);
    const [labCost, setLabCost] = useState(0);
    const [myDentistCharge, setMyDentistCharge] = useState(0);
    const paymentData = useSelector((state) => state.GetPaymentSlice.payment);
    const postPaymentData = useSelector((state) => state.AddPaymentSlice.payment);
    const [isTotalValid, setIsTotalValid] = useState(true);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [component,setComponent]=useState(null);
    const paymentMethods = [
        {value: "Cash", label: "Cash"},
        {value: "Card", label: "Card"},
        {value: "Qr Payment", label: "Qr Payment"},
        {value: "Bank Transfer", label: "Bank Transfer"},
    ];

    const changePaymentMethod = (index, value) => {
        const array = [...paymentTypes];
        array[index].value = value;
        setPaymentTypes(array);
    };

    const changeAmountMethod = (index, value) => {
        const array = [...paymentTypes];
        array[index].amount = value;
        setPaymentTypes(array);
    };
    
  
    useEffect(() => {
        let tot = 0;
        paymentTypes.forEach((val) => {
            tot += parseFloat(val.amount);
        });

        if (tot > parseFloat(total)) {
            setIsTotalValid(false);
        } else {
            setIsTotalValid(true);
        }
    }, [paymentTypes]);

    useEffect(() => {
        if (!paymentData.isLoading && paymentData.isSuccess) {
            setVisitDate(paymentData.data?.visitDate);
            setDoctorCharge(paymentData.data?.doctorCharge);
            setExtraCharge(paymentData.data?.extraCharge);
            setExtraConsCost(paymentData.data?.extraConsCost);
            setLabCost(paymentData.data?.labCost);
            setMyDentistCharge(paymentData.data?.myDentistCharge);
            genTotal(discount);
        }
    }, [paymentData.data]);

    useEffect(() => {
        if (discount.percentage !== undefined) {
            setDis(discount.percentage);
            genTotal(discount);
        }
    }, [discount]);


    const genTotal = (dis) => {
        const tot =
            paymentData.data?.doctorCharge +
            paymentData.data?.extraCharge +
            paymentData.data?.extraConsCost +
            paymentData.data?.labCost;

        let disTot = 0;
        if (dis.percentage !== undefined) {
            disTot =
                paymentData.data?.myDentistCharge -
                (paymentData.data?.myDentistCharge / 100) * dis.percentage;
        } else {
            disTot = paymentData.data?.myDentistCharge;
        }
        const subTotal =
            paymentData.data?.foreignStatus === 0 ? tot + disTot : (tot + disTot) * 2;
        setTotal(subTotal);
        setPaymentTypes([{value: "Cash", amount: subTotal}]);
    };

    const handleConfirm = (val) => {
        if (val.download) {
            downloadBill();
        }
        if (val.print) {
            printBill();
        }
    };
    const componentRef = useRef(null);
    const printBill = () => {
        
        const blob = new Blob([postPaymentData.data], {
            type: "application/pdf;charset=utf-8",
        });
        /* print */
        const url=URL.createObjectURL(blob);
        const frame=window.open(url,'_blank');
        if(frame){
            frame.print();
        }
    };
 
    
    const downloadBill = () => {
       
        const blob = new Blob([postPaymentData.data], {
            type: "application/pdf;charset=utf-8",
        });
        saveAs(blob, "my-dentist-bill.pdf");
        changePage();
        setPdfModal(false);
    };

    const clickPay = () => {
        setConform(false);
        const type = [];
        paymentTypes.forEach((val) => {
            type.push({
                method: val.value,
                amount: val.amount
            })
        })
        const payment = {
            bookingId: paymentData.data?.bookingDetails[0]?.booking?.bookingId,
            paymentMethodList: type,
            discountId: discount.discountId !== undefined ? discount.discountId : null,
        };
        setLoader(true);
        dispatch(addPayment(payment));
    };

    useEffect(() => {
        if (!postPaymentData.isLoading && postPaymentData.isSuccess && loader) {
            setPdfModal(true);
            setLoader(false);
        }
    }, [postPaymentData.data]);

    return (
        <MDBox>
            <Grid container spacing={2}>
                <Grid
                    item
                    sx={{
                        border: "1px solid #2D9DE54D",
                        width: "100%",
                        height: "65px",
                        borderRadius: "5px",
                        margin: "10px 0px 10px 14px",
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                margin: "0px",
                                padding: "0px",
                                backgroundColor: "#EAF5FC",
                                marginRight: "8px",
                            }}
                            src={doctor}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "25px",
                        }}
                    >
                        <Typography sx={{fontSize: "14px", color: "#1C5D7C"}}>
                            {"(" +
                                paymentData.data?.doctor?.title +
                                ") " +
                                paymentData.data?.doctor?.name}
                        </Typography>
                        <Typography sx={{fontSize: "12px", color: "#888888"}}>
                            {paymentData.data?.doctor?.mobileNumberOne}
                        </Typography>
                    </div>
                </Grid>

                <Grid
                    item
                    sx={{
                        border: "1px solid #2D9DE54D",
                        width: "100%",
                        height: "65px",
                        borderRadius: "5px",
                        margin: "10px 0px 10px 14px",
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Avatar
                            sx={{
                                width: 30,
                                height: 30,
                                margin: "0px",
                                padding: "0px",
                                backgroundColor: "#EAF5FC",
                                marginRight: "8px",
                            }}
                            src={patientAdd}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "25px",
                        }}
                    >
                        <Typography sx={{fontSize: "14px", color: "#1C5D7C"}}>
                            {"(" +
                                paymentData.data?.patient?.title +
                                ") " +
                                paymentData.data?.patient?.firstName +
                                " " +
                                paymentData.data?.patient?.lastName}
                        </Typography>
                        <Typography sx={{fontSize: "12px", color: "#888888"}}>
                            {paymentData.data?.patient?.mobileNumber}
                        </Typography>
                    </div>
                </Grid>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "300px",
                        overflowY: "auto",
                        overflowX: 'hidden',
                        width: "100%",
                        padding: "15px",
                    }}
                >
                    <Grid
                        container
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    md: "column",
                                    sm: "column",
                                    lg: "column",
                                },
                            }}
                        >
                            <Typography sx={{color: "#497D96", fontSize: "14px"}}>
                                Visit Date
                            </Typography>
                            <Typography
                                sx={{color: "#497D96", fontSize: "14px", marginTop: "10px"}}
                            >
                                Doctor Charge
                            </Typography>
                            <Typography
                                sx={{color: "#497D96", fontSize: "14px", marginTop: "10px"}}
                            >
                                Extra
                            </Typography>
                            <Typography
                                sx={{color: "#497D96", fontSize: "14px", marginTop: "10px"}}
                            >
                                Extra Cons Cost
                            </Typography>
                            <Typography
                                sx={{color: "#497D96", fontSize: "14px", marginTop: "10px"}}
                            >
                                Lab Cost
                            </Typography>
                            <Typography
                                sx={{color: "#497D96", fontSize: "14px", marginTop: "10px"}}
                            >
                                My Dentist Charge
                            </Typography>
                            <Typography
                                sx={{color: "#497D96", fontSize: "14px", marginTop: "10px"}}
                            >
                                Discount
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: "flex",
                                flexDirection: {
                                    xs: "column",
                                    md: "column",
                                    sm: "column",
                                    lg: "column",
                                },
                            }}
                        >
                            <Typography sx={{color: "#8DAEBD", fontSize: "14px"}}>
                                {visitDate}
                            </Typography>
                            <Typography sx={textStyle}>
                                {numberFormat(doctorCharge)}
                            </Typography>
                            <Typography sx={textStyle}>
                                {numberFormat(extraCharge)}
                            </Typography>
                            <Typography sx={textStyle}>
                                {numberFormat(extraConsCost)}
                            </Typography>
                            <Typography sx={textStyle}>{numberFormat(labCost)}</Typography>
                            <Typography sx={textStyle}>
                                {numberFormat(myDentistCharge)}
                            </Typography>
                            <Typography sx={textStyle}>{dis}%</Typography>
                        </Grid>
                    </Grid>

                    {paymentTypes.map((val, index) => {
                        return (
                            <Grid
                                container
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                sx={{marginTop: 1.5}}
                            >
                                <Grid item sx={{display: "flex", flexDirection: "column"}}>
                                    <Box sx={{width: "130px"}}>
                                        <DropDown
                                            options={paymentMethods}
                                            value={val.value}
                                            onChange={(value) => {
                                                changePaymentMethod(index, value);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sx={{display: "flex", flexDirection: "column"}}>
                                    <TextInput
                                        onChange={(value) => {
                                            changeAmountMethod(index, value.target.value);
                                        }}
                                        value={val.amount}
                                        type={"text"}
                                        placeholder={"Amount"}
                                        sx={{width: 100}}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}

                    <Grid
                        container
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"flex-end"}
                        sx={{marginTop: 1}}
                    >
                        <Grid item sx={{display: "flex", flexDirection: "row"}}>
                            <IconButton
                                sx={{
                                    color: "#ffffff",
                                    margin: "0px",
                                    padding: "0px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    backgroundColor: '#2d9ce4',
                                    "&:hover": {
                                        backgroundColor: "#2D9DE5",
                                    },
                                }}
                                onClick={() => {
                                    const array = [...paymentTypes];
                                    array.push({value: "Card", amount: "0"});
                                    setPaymentTypes(array);
                                }}
                            >
                                <Add/>
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        sx={{marginTop: 1}}
                    >
                        <Grid item sx={{display: "flex", flexDirection: "column"}}>
                            <Typography
                                sx={{
                                    color: "#1C5D7C",
                                    fontSize: "16px",
                                    fontWeight: "bolder",
                                }}
                            >
                                Total Charge
                            </Typography>
                        </Grid>

                        <Grid item sx={{display: "flex", flexDirection: "column"}}>
                            <Typography
                                sx={{
                                    textAlign: "end",
                                    color: "#1C5D7C",
                                    fontSize: "16px",
                                    fontWeight: "bolder",
                                }}
                            >
                                {numberFormat(total)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>

                <Button
                    sx={{
                        backgroundColor: "#2D9DE5",
                        color: "#FFFFFF",
                        margin: "10px 0px 10px 14px",
                        "&:hover": {
                            backgroundColor: "#2D9DE5",
                        },
                    }}
                    fullWidth
                    variant="gradient"
                    onClick={() => {
                        setConform(true);
                    }}
                    disabled={!isTotalValid}
                >
                    {"Pay"}
                </Button>
                <ConfirmAlert
                    onClose={() => setConform(false)}
                    open={conform}
                    buttonText={"pay"}
                    title={"Are you sure"}
                    subtitle={"Process Payment ?"}
                    src={transaction}
                    onclick={() => {
                        clickPay();
                    }}
                />
                {pdfModal && (
                    <PaymentPdfModal
                        handleConfirm={handleConfirm}
                        onClose={() => setPdfModal(false)}
                        open={pdfModal}
                    />
                )}
            </Grid>
           
        </MDBox>
    );
}

PaymentDoctor.propTypes = {
    discount: PropTypes.object.isRequired,
    changePage: PropTypes.func.isRequired,
};

export default PaymentDoctor;
