import { createSlice } from "@reduxjs/toolkit";
import {getAllOnlineAppointments} from "services/onlineAppointment/onlineAppointment";

const initialState = {
    onlineAppointment: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const onlineAppointmentSlice = createSlice({
    name: "onlineAppointment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllOnlineAppointments.pending, (state) => {
                state.onlineAppointment.isLoading = true;
            })
            .addCase(getAllOnlineAppointments.fulfilled, (state, { payload }) => {
                state.onlineAppointment.isLoading = false;
                state.onlineAppointment.isSuccess = true;
                state.onlineAppointment.data = payload;
            })
            .addCase(getAllOnlineAppointments.rejected, (state, { payload }) => {
                state.onlineAppointment.isLoading = false;
                state.onlineAppointment.isSuccess = false;
                state.onlineAppointment.errorMessage = payload;
            });
    },
});

export default onlineAppointmentSlice.reducer;
