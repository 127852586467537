import { createSlice } from "@reduxjs/toolkit";
import {updateDiscount} from "services/discount/discount";

const initialState = {
    discount: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const updateDiscountSlice = createSlice({
    name: "updateDiscount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateDiscount.pending, (state) => {
                state.discount.isLoading = true;
            })
            .addCase(updateDiscount.fulfilled, (state, { payload }) => {
                state.discount.isLoading = false;
                state.discount.isSuccess = true;
                state.discount.data = payload;
            })
            .addCase(updateDiscount.rejected, (state, { payload }) => {
                state.discount.isLoading = false;
                state.discount.isSuccess = false;
                state.discount.errorMessage = payload;
            });
    },
});

export default updateDiscountSlice.reducer;
