import React, {useEffect, useState} from 'react';
import ModalComponent from "common/components/Modal";
import MDBox from "common/components/MDBox";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import {useDispatch, useSelector} from "react-redux";
import {getDiscount, postDiscount} from "services/discount/discount";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

function DiscountAddModal({open , onClose,}) {
    const dispatch = useDispatch();
    const discountSave = useSelector(state => state.SaveDiscountSlice.discount);
    const [saveLoader , setSaveLoader] = useState(false)
    const [desc, setDesc] = useState("");
    const [percent, setPercent] = useState("");
    const [percentError, setPercentError] = useState("");

    const isComplete = desc && percent && !percentError;

    const handlePercentChange = (e) => {
        setPercent(e.target.value)
        if (!/^(\d+(\.\d+)?%?)$/.test(e.target.value)) {
            setPercentError("Invalid Percentage");
        } else {
            setPercentError("");
        }
    }

    const savedDiscount = () => {
        const discountData = {
            description: desc,
            percentage: percent,

        };
        setSaveLoader(true);
        dispatch(postDiscount(discountData));

    };

    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (discountSave.isSuccess && !discountSave.isLoading) {
                dispatch(getDiscount());
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Discount Saved Success',
                })
                onClose();

            } else if (!discountSave.isSuccess && !discountSave.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Discount Saved Warning'
                })
            }
        }
    }, [discountSave.data]);


    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin: '10px'}}>
                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Typography sx={{
                    color: '#1C5E7C',
                    fontWeight: 450,
                    fontSize: '19px',
                    marginBottom: '25px',
                    position: 'absolute',
                    top: '15px',
                    left: '15px'
                }}>Add Discount </Typography>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{marginY:'10px'}}>
                            <TextInput
                                placeholder={'Description'}
                                value={desc}
                                onChange={(e)=>{setDesc(e.target.value)}}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box  >
                            <TextInput
                                placeholder={'Percentage'}
                                value={percent}
                                onChange={handlePercentChange}
                                variant="outlined"
                                error={percentError !== ""}
                            />
                            {percentError && <div style={{color: 'red', fontSize: '12px'}}>{percentError}</div>}
                        </Box>
                    </Grid>

                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'end', marginTop:'20px'}}>
                    <CommonButton
                        onClick={ savedDiscount}
                        name={'+  Add  '}
                        variant={'Fill'}
                        disabled={!isComplete}
                    />
                </Box>

            </MDBox>
        </ModalComponent>
    );
}

export default DiscountAddModal;