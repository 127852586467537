import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";
export const getDiscount = createAsyncThunk('get-discount', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/discount`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postDiscount = createAsyncThunk('post-discount', async (discountData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/discount`, discountData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateDiscount = createAsyncThunk('discount-update', async ({id,discountUpData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/discount/${id}`,  discountUpData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteDiscount = createAsyncThunk('delete-discount', async (id, { rejectWithValue }) => {
        try {
            const data = await instance.delete(`/discount/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getActiveDiscount = createAsyncThunk('get-active-discount', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/discount/active_discount`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

