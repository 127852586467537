import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CommonButton from "common/components/Button";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import {itm} from "config/images";
import {getAllPurchaseOrders, updatePurchaseOrderStatus} from "services/purchaseOrderMain";
import {generateDate} from "common/utils/CommonFunctions";
import dayjs from "dayjs";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {useDispatch, useSelector} from "react-redux";

function PurchaseOrderApprovalModal({open, onClose ,items}) {

    const dispatch = useDispatch();
    const [tableData,setTableData] = useState([]);
    const [loader,setLoader] = useState(false);
    const poStatusUpdateData = useSelector(state => state.UpdatePurchaseOrderStatusSlice.purchaseOrder);

    const tableColumns = [
        {field: "item", headerName: "Item", accessor: "item", textAlign: 'left'},
        {field: "qty", headerName: "Qty", accessor: "qty", textAlign: 'left'},

    ];
    const searchKey = ['item', 'qty'];

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        if(Array.isArray(items.poDetails)){
            const array = items.poDetails.map((item)=>(
                {
                    item:(
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    margin: '0px',
                                    padding: '0px',
                                    backgroundColor: '#EAF5FC',
                                    marginRight: '5px',
                                    marginTop: '8px',
                                }}
                                src={itm}
                            />

                            <div style={{marginTop: '10px'}}>{item.item.itemName}</div>
                        </div>
                    ),
                    qty:item.qty,
                }
            ));
            setTableData(array)
        }
    }, []);

    useEffect(() => {
        if (loader){
            setLoader(false);
            if (poStatusUpdateData.isSuccess && !poStatusUpdateData.isLoading) {
                dispatch(getAllPurchaseOrders());
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Purchase Order Status Update Success',
                })
            } else if (!poStatusUpdateData.isSuccess && !poStatusUpdateData.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Purchase Order Status Update Error'
                })
            }
        }
    }, [poStatusUpdateData.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin:'10px'}}>
                <Box>
                    <div>
                        <Typography sx={{
                            color: '#1C5E7C',
                            fontWeight: 450,
                            fontSize: '16px',
                            marginBottom: '25px',
                            position: 'absolute',
                            top: '15px',
                            left: '15px'
                        }}> {'Order Details'} </Typography>
                    </div>
                </Box>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Typography sx={{fontSize:'14px' , margin:'10px 0px 8px 5px'}}>Supplier - <span style={{color:'#1C5D7C'}}>{items.supplier.name}</span></Typography>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={false}/>
                </MDBox>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={() => {
                                handleClose()
                            }}
                        />
                    </div>

                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Approved'}
                            variant={'Fill'}
                            onClick={() => {
                                setLoader(true);
                                dispatch(updatePurchaseOrderStatus({id:items.mainPoId,updateData:{mainPoStatus:"approved",approvedDate:generateDate(dayjs())}}))
                            }}
                            disabled={items.mainPoStatus === 'approved'}
                        />
                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

PurchaseOrderApprovalModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    items:PropTypes.array
};

export default PurchaseOrderApprovalModal;
