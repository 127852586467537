import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PurchaseOrderAddModal from "components/PurchaseOrderAddModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllPurchaseOrders} from "services/purchaseOrderMain";

function PurchaseOrder(){

    const dispatch = useDispatch();
    const [isAddModalOpen,setIsAddModalOpen] = useState(false);
    const [isViewModalOpen,setIsViewModalOpen] = useState(false);
    const [purchaseOrders,setPurchaseOrders] = useState([]);
    const [selectedPO,setSelectedPO] = useState({});
    const poData = useSelector(state => state.PurchaseOrderSlice.purchaseOrder)

    const tableColumns = [
        {field: "pono", headerName: "PO No", accessor: "pono", textAlign: 'left'},
        {field: "supplier", headerName: "Supplier", accessor: "supplier", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "approvedData", headerName: "Approved Data", accessor: "approvedData", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];
    const searchKey = ['pono', 'supplier'];

    useEffect(() => {
        dispatch(getAllPurchaseOrders());
    }, []);

    useEffect(() => {
        if(poData.isSuccess && !poData.isLoading){
            const data = poData?.data;
            if(Array.isArray(data)){
                const array = data.map((item)=>(
                    {
                        pono:item.poNo,
                        supplier:item.supplier.name,
                        date:item.poDate,
                        approvedData:item.approvedDate === null? '-': item.approvedDate,
                        status:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px',backgroundColor:item.mainPoStatus === 'approval'? '#2D9DE5' :item.mainPoStatus === 'approved'? '#1C5D7C' : '' }}
                                    name={item.mainPoStatus}
                                    circular={true}
                                    variant={item.mainPoStatus === 'pending'? 'outline': 'Fill'}
                                    onClick={() => {

                                    }}
                                />
                            </div>
                        ),
                        action:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <IconButton
                                    onClick={() => {
                                        const poData = {
                                            poId:item.mainPoId,
                                            supplierId:item.supplier.supplierId,
                                            status:item.mainPoStatus
                                        }
                                        setSelectedPO(poData);
                                        setIsViewModalOpen(true)
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>
                            </div>
                        ),

                    }
                ));
                setPurchaseOrders(array)
            }
        }
    }, [poData.data]);

    return(
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Purchase Order</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'end'}}>
                <CommonButton
                    onClick={()=>{setIsAddModalOpen(true)}}
                    name={'+  Add Purchase Order'}
                    variant={'Fill'}
                />
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={purchaseOrders} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            {isAddModalOpen &&
                <PurchaseOrderAddModal
                    open={isAddModalOpen}
                    onClose={()=>{setIsAddModalOpen(false)}}
                    isView={false}
                />
            }

            {isViewModalOpen &&
                <PurchaseOrderAddModal
                    open={isViewModalOpen}
                    onClose={()=>{setIsViewModalOpen(false)}}
                    isView={true}
                    purchaseDetails={selectedPO}
                />
            }
        </WideCard>
    )
}

export default PurchaseOrder;
