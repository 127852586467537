import React, {useEffect, useState} from 'react';
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import ModalComponent from "common/components/Modal";
import IconButton from "@mui/material/IconButton";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Toggle from "common/components/Toggle/Toggle";
import {useDispatch, useSelector} from "react-redux";
import {getDiscount, updateDiscount} from "services/discount/discount";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";

const toggleData = [
    {
        view: 'Active',
        value: 1,
    },
    {
        view: "Deactive",
        value: 0,
    },
];

function DiscountUpdateModal({open, onClose, disData}) {
    const dispatch = useDispatch();
    const discountUpdate = useSelector(state => state.UpdateDiscountSlice.discount);
    const [onAlignment, setOnAlignment] = useState(disData.status === 1 ? 1 : 0);
    const [editMode, setEditMode] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [desc, setDesc] = useState("");
    const [percent, setPercent] = useState("");
    const [percentError, setPercentError] = useState("");

    const isComplete = desc && percent && !percentError;

    const handlePercentChange = (e) => {
        setPercent(e.target.value)
        if (!/^(\d+(\.\d+)?%?)$/.test(e.target.value)) {
            setPercentError("Invalid Percentage");
        } else {
            setPercentError("");
        }
    }

    const clear = () =>{
        setDesc("")
        setPercent("")
    }

    useEffect(() => {
        setPercent(disData.percentage)
        setDesc(disData.description)
    }, []);

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setOnAlignment(newAlignment);
        }
    };

    const handleEditClick = () => {
        setEditMode(!editMode);
    };

    const updatedDiscount = () => {
        const discountUpData = {
            description: desc,
            percentage: percent,
            status: onAlignment
        };
        setUpdateLoader(true);
        dispatch(updateDiscount({id: disData.discountId, discountUpData}));
    };

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (discountUpdate.isSuccess && !discountUpdate.isLoading) {
                clear();
                dispatch(getDiscount());
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Discount Updated Success',
                })
                onClose();

            } else if (!discountUpdate.isSuccess && !discountUpdate.isLoading) {
                sendWarningNotification({
                    title: 'Warning',
                    message: 'Discount Updated Warning'
                })
            }
        }
    }, [discountUpdate.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin: '10px'}}>
                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '500px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Typography sx={{
                        color: '#1C5E7C',
                        fontWeight: 450,
                        fontSize: '19px',
                        marginBottom: '25px',
                        position: 'absolute',
                        top: '15px',
                        left: '15px'
                    }}> Discount </Typography>

                    <div style={{
                        position: 'absolute',
                        top: '12px',
                        left: '90px'
                    }}
                    >
                        <IconButton aria-label="delete">
                            <DriveFileRenameOutlineIcon sx={{color: '#1C5E7C'}} fontSize={'small'}
                                                        onClick={handleEditClick}/>
                        </IconButton>
                    </div>
                </Box>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Description'}
                                value={desc}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}
                                disabled={!editMode}
                                variant="outlined"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                            <TextInput
                                placeholder={'Percentage'}
                                value={percent}
                                onChange={handlePercentChange}
                                disabled={!editMode}
                                variant="outlined"
                                error={percentError !== ""}
                            />
                            {percentError && <div style={{color: 'red', fontSize: '12px'}}>{percentError}</div>}
                        </Box>
                    </Grid>

                </Grid>

                {editMode && (
                    <MDBox sx={{margin: '15px', display: 'flex', justifyContent: 'center'}}>
                        <Toggle sx={{width: '100%'}} data={toggleData} value={onAlignment} onChange={handleAlignment}/>
                    </MDBox>
                )}

                <Box sx={{display: 'flex', justifyContent: 'end', marginTop: '20px'}}>
                    <CommonButton
                        disabled={editMode ? !isComplete : ""}
                        onClick={editMode ? updatedDiscount : onClose}
                        name={editMode ? "Update" : "Cancel"}
                        variant={editMode ? "Fill" : "outline"}
                    />
                </Box>

            </MDBox>
        </ModalComponent>
    );
}

export default DiscountUpdateModal;