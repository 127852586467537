import Dashboard from "pages/Dashboard";
import Sample from "pages/Sample";
import Icon from "@mui/material/Icon";
import DoctorList from "pages/DoctorList";
import General from "pages/Patient";
import PatientCheck from "pages/PatientCheck";
import AppointmentBooking from "pages/AppointmentBooking";
import BranchAndSlot from "pages/BranchAndSlot";
import UserManage from "pages/UserManage";
import LocationWiseIncomeReport from "pages/LocationWiseIncomeReport";
import DoctorWiseSalesReport from "pages/DoctorWiseSalesReport";
import ManageProcedures from "pages/ManageProcedures";
import OnlineTreatment from "pages/OnlineTreatment";
import PaymentFlow from "pages/PaymentFlow";
import PatientProcessFlow from "pages/PatientProcessFlow";
import ManageDiscount from "pages/ManageDiscount";
import PaymentDetails from "pages/PaymentDetails";
import ManageStock from "pages/ManageStock";
import ManageSupplier from "pages/ManageSupplier";
import InventoryDashboard from "pages/InventoryDashboard";
import DailyUsagesView from "pages/DailyUsagesView";
import ManagePurchaseOrderView from "pages/ManagePurchaseOrderView";
import PurchaseOrder from "pages/PurchaseOrder";
import StockTransferView from "pages/StockTransferView";
import DailyUsage from "pages/DailyUsage";
import StockTransfer from "pages/StockTransfer";
import ManageGrnIssue from "pages/ManageGrnIssue";
import ManageGRN from "pages/ManageGRN";
import POStockView from "pages/POStockView";
import POStock from "pages/POStock";
import GrnIssueView from "pages/GrnIssueView";
import BookingHistoryReport from "pages/BookingHistoryReport";
import ProcedureWiseSalesReport from "pages/ProcedureWiseSalesReport";
import PatientDetailsReport from "pages/PatientDetailsReport";
import PatientClinicalNoteReport from "pages/PatientClinicalNoteReport";
import PatientDetailedClinicalReport from "pages/PatientDetailedClinicalReport";

const routes = [
  {
    type: "collapse",
    name: "Sample",
    key: "sample",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/sample",
    userRole: ['none'],
    component: <Sample />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    userRole: ['admin', 'reception'],
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    userRole: ['financial-manager', 'stock', 'stock_manager'],
    component: <InventoryDashboard />,
  },
  {
    type: "collapse",
    name: "Administrator",
    key: "administrator",
    icon: <Icon fontSize="small">person</Icon>,
    userRole: ['admin'],
    subRoute: [
      {
        type: "collapse",
        name: "Doctor List",
        key: "doctor-list",
        route: "/administrator/doctor-list",
        userRole: ['admin'],
        component: <DoctorList />,
      },
      {
        type: "collapse",
        name: "Clinic & Slot",
        key: "Clinic-slot",
        route: "/administrator/clinic-slot",
        userRole: ['admin'],
        component: <BranchAndSlot />,
      },
    ]
  },

  {
    type: "collapse",
    name: "Treatment",
    key: "treatment",
    icon: <Icon fontSize="small">medical_services</Icon>,
    userRole: ['admin', 'reception'],
    subRoute: [
      {
        type: "collapse",
        name: "Online Treatment",
        key: "online-treatment",
        icon: <Icon fontSize="small"> </Icon>,
        route: "/online-treatment",
        userRole: ['admin'],
        component: <OnlineTreatment />,
      },
      {
        type: "collapse",
        name: "Manage Procedures",
        key: "manage-procedures",
        route: "/treatment/manage-procedures",
        userRole: ['admin'],
        component: <ManageProcedures/>,
      },
      {
        type: "collapse",
        name: "Checking List",
        key: "checking-list",
        route: "/treatment/checking-list",
        userRole: ['admin', 'reception'],
        component: <PatientProcessFlow/>,
      },
      {
        type: "collapse",
        name: "Checkout List",
        key: "checkout-list",
        route: "/treatment/checkout-list",
        userRole: ['admin', 'reception'],
        component: <PaymentFlow/>,
      },
      {
        type: "collapse",
        name: "Manage Discount",
        key: "manage-discount",
        route: "/treatment/manage-discount",
        userRole: ['admin'],
        component: <ManageDiscount/>,
      },
    ]
  },

  {
    type: "collapse",
    name: "Front Office",
    key: "front-office",
    icon: <Icon fontSize="small">assignment_ind</Icon>,
    userRole: ['admin', 'reception'],
    subRoute: [
      {
        type: "collapse",
        name: "Appointment View",
        key: "patient-view",
        route: "/front-office/appointment-view",
        userRole: ['admin', 'reception'],
        component: <PatientCheck />,
      },
      {
        type: "collapse",
        name: "Appointment Booking",
        key: "appointment-booking",
        route: "/front-office/appointment-booking",
        userRole: ['admin', 'reception'],
        component: <AppointmentBooking />,
      },
      {
        type: "collapse",
        name: "Patient Management",
        key: "patient-management",
        route: "/front-office/patient-management",
        userRole: ['admin', 'reception'],
        component: <General />,
      },
    ]
  },
  {
    type: "collapse",
    name: "Payment Details",
    key: "payment-details",
    icon: <Icon fontSize="small">paid</Icon>,
    route: "/payment-details",
    userRole: ['admin'],
    component: <PaymentDetails />,
  },
  {
    type: "collapse",
    name: "Stock",
    key: "stock",
    icon: <Icon fontSize="small">inventory</Icon>,
    userRole: ['stock', 'stock_manager'],
    subRoute: [
      {
        type: "collapse",
        name: "Manage Stock",
        key: "manage-stock",
        route: "/stock/manage-stock",
        userRole: ['stock_manager'],
        component: <ManageStock />,
      },
      {
        type: "collapse",
        name: "Daily Usage",
        key: "daily-usage",
        route: "/stock/daily-usage",
        userRole: ['stock',],
        component: <DailyUsage />,
      },
      {
        type: "collapse",
        name: "Daily Usages View",
        key: "daily-usage-view",
        route: "/stock/daily-usages-view",
        userRole: ['stock_manager'],
        component: <DailyUsagesView />,
      },
      {
        type: "collapse",
        name: "Stock Transfer",
        key: "stock-transfer",
        route: "/stock/stock-transfer",
        userRole: ['stock_manager'],
        component: <StockTransfer />,
      },
      {
        type: "collapse",
        name: "Stock Transfer View",
        key: "stock-transfer-view",
        route: "/stock/stock-transfer-view",
        userRole: ['stock'],
        component: <StockTransferView />,
      },
      {
        type: "collapse",
        name: "GRN Issue View",
        key: "grn-issue-view",
        route: "/stock/grn-issue-view",
        userRole: ['stock'],
        component: <GrnIssueView />,
      }
    ]
  },
  {
    type: "collapse",
    name: "Purchase Order",
    key: "purchase-order",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/purchase-order",
    userRole: ['stock_manager'],
    component: <PurchaseOrder/>,
  },
  {
    type: "collapse",
    name: "Purchase Order",
    key: "purchase-order",
    icon: <Icon fontSize="small">list_alt</Icon>,
    route: "/purchase-order",
    userRole: ['stock'],
    component: <POStock/>,
  },
  {
    type: "collapse",
    name: "Purchase Order View",
    key: "purchase-order-view",
    icon: <Icon fontSize="small">reorder</Icon>,
    route: "/purchase-order-view",
    userRole: ['stock_manager'],
    component: <POStockView/>,
  },
  {
    type: "collapse",
    name: "Purchase Order View",
    key: "purchase-order-view",
    icon: <Icon fontSize="small">grading</Icon>,
    route: "/purchase-order-view",
    userRole: ['financial-manager'],
    component: <ManagePurchaseOrderView/>,
  },
  {
    type: "collapse",
    name: "Manage GRN",
    key: "manage-grn",
    icon: <Icon fontSize="small">system_update_alt</Icon>,
    route: "/manage-grn",
    userRole: ['stock_manager'],
    component: <ManageGRN/>,
  },
  {
    type: "collapse",
    name: "GRN Issue",
    key: "manage-grn-issue",
    icon: <Icon fontSize="small">ios_share</Icon>,
    route: "/grn-issue",
    userRole: ['stock_manager'],
    component: <ManageGrnIssue/>,
  },
  {
    type: "collapse",
    name: "Manage Supplier",
    key: "manage-supplier",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/manage-supplier",
    userRole: ['admin','stock_manager'],
    component: <ManageSupplier/>,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "user-management",
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    route: "/user-management",
    userRole: ['admin'],
    component: <UserManage />,
  },
  {
    type: "collapse",
    name: "Reporting",
    key: "reporting",
    icon: <Icon fontSize="small">manage_accounts</Icon>,
    userRole: ['admin'],
    subRoute: [
      {
        type: "collapse",
        name: "Location Wise Income",
        key: "location-wise-income",
        route: "reporting/location-wise-income",
        userRole: ['admin'],
        component: <LocationWiseIncomeReport />,
      },
      {
        type: "collapse",
        name: "Doctor Wise Sales",
        key: "doctor-wise-sales",
        route: "reporting/doctor-wise-sales",
        userRole: ['admin'],
        component: <DoctorWiseSalesReport />,
      },
      {
        type: "collapse",
        name: "Booking History",
        key: "booing-history",
        route: "reporting/booking-history",
        userRole: ['admin'],
        component: <BookingHistoryReport />,
      },
      {
        type: "collapse",
        name: "Procedure Wise Sales",
        key: "procedure-wise-sales",
        route: "reporting/procedure-wise-sales",
        userRole: ['admin'],
        component: <ProcedureWiseSalesReport/>
      },
      {
        type: "collapse",
        name: "Patient: Procedures",
        key: "patient-details",
        route: "reporting/patient-details",
        userRole: ['admin'],
        component: <PatientDetailsReport/>
      },
      {
        type: "collapse",
        name: "Patient: ClinicalNote",
        key: "patient-clinical-notes",
        route: "reporting/patient-clinical-notes",
        userRole: ['admin'],
        component: <PatientClinicalNoteReport/>
      },
      {
        type: "collapse",
        name: "Patient: Detailed ClinicalNote",
        key: "patient-clinical-notes-detailed",
        route: "reporting/patient-clinical-notes-detailed",
        userRole: ['admin'],
        component: <PatientDetailedClinicalReport/>
      }

    ]
  }
  
];

export default routes;
