import React from "react";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "app/App";
import { Provider } from 'react-redux'
import {store} from "./store";
import { MaterialUIControllerProvider } from "context";
import { ReactNotifications } from 'react-notifications-component';
const container = document.getElementById("app");
const root = createRoot(container);



root.render(
  <Provider store={store}>
      <BrowserRouter>
          <MaterialUIControllerProvider>
              <ReactNotifications isMobile={true} breakpoint={425} />
              <App />
          </MaterialUIControllerProvider>
      </BrowserRouter>
  </Provider>
);
