import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import MDBox from "common/components/MDBox";
import InputBase from "@mui/material/InputBase";
import {styled, alpha ,} from "@mui/material/styles";
import {navbarButtonContainer,navbarIconBtn} from './styles'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarMobileMenu,
} from "common/components/DashboardNavbar/styles";
import {
    useMaterialUIController,
    setTransparentNavbar,
    setMiniSidenav,
} from "context";
import DateTime from "../DateTime";
import Calender from "../Calender";
import {NotificationsOutlined} from "@mui/icons-material";
// import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import ReceptionPopupCard from "components/ReceptionPopupCard";

const Search = styled("div")(({theme}) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "white",
    color: "white",
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.9),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
    padding: theme.spacing(0, 2, 0, 2),
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2D9DE5",
    borderRadius: 5,
    marginLeft: 362,
    height: 30,
    width: 30,
    marginTop: 6.5,
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: "white",
    width: 400,
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(1)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

function DashboardNavbar({absolute, light, isMini}) {
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const {miniSidenav, transparentNavbar, fixedNavbar, darkMode} = controller;
    const [isCardOpen, setIsCardOpen] = useState(false);

    const openCard = () => {
        setIsCardOpen(true);
    };

    const closeCard = () => {
        setIsCardOpen(false);
    };

    useEffect(() => {
        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        window.addEventListener("scroll", handleTransparentNavbar);

        handleTransparentNavbar();

        return () => window.removeEventListener("scroll", handleTransparentNavbar);
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    const iconsStyle = ({palette: {dark, white, text}, functions: {rgba}}) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, {transparentNavbar, absolute, light, darkMode})}
        >
                 <Toolbar sx={(theme) => navbarContainer(theme)}>
                                {isMini ? null : (
                                    <MDBox sx={(theme) => navbarRow(theme, {isMini})}>
                                        <MDBox color={light ? "dark" : "inherit"} style={{ display: 'flex' }}>
                                            <IconButton
                                                size="large"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon sx={iconsStyle} fontSize="medium">
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                            <Grid container style={{display:'flex' , flexDirection:'row', justifyContent:'space-between'}}>
                                <Grid item sx={{
                                    marginLeft:'10px',
                                    display: 'none',
                                    flexDirection: 'row',
                                    '@media (min-width:0px) and (max-width: 545px)': {
                                        display: 'none'
                                    },
                                    '@media (min-width:540px)': {
                                        display: 'flex'
                                    }
                                }}>
                                    <DateTime/>
                                </Grid>
                                <Grid item sx={{
                                    marginLeft:'15px',
                                    display: 'none',
                                    flexDirection: 'row',
                                    '@media (min-width:0px) and (max-width: 545px)': {
                                        display: 'none'
                                    },
                                    '@media (min-width:540px)': {
                                        display: 'flex'
                                    }
                                }}>
                                    <Calender/>
                                </Grid>
                                <Grid item style={{ position:'absolute',margin:'auto' ,  right:'0px'}}>
                                <MDBox sx={navbarButtonContainer}>
                                    <IconButton sx={navbarIconBtn} size={"medium"}>
                                        <NotificationsOutlined/>
                                        {/*<Badge  sx={{*/}
                                        {/*    "& .MuiBadge-badge": {*/}
                                        {/*        color: "lightgreen",*/}
                                        {/*        backgroundColor: "#2D9DE5"*/}
                                        {/*    }*/}
                                        {/*}} variant={"dot"}*/}
                                        {/*       overlap="circular"><NotificationsOutlined/></Badge>*/}
                                    </IconButton>
                                    <IconButton sx={navbarIconBtn} size={"medium"}>
                                        <MailOutlineIcon/>
                                        {/*<Badge sx={{*/}
                                        {/*    "& .MuiBadge-badge": {*/}
                                        {/*        color: "lightgreen",*/}
                                        {/*        backgroundColor: "#2D9DE5"*/}
                                        {/*    }*/}
                                        {/*}}  variant={"dot"}*/}
                                        {/*       overlap="circular"><MailOutlineIcon/></Badge>*/}
                                    </IconButton>
                                    <IconButton sx={navbarIconBtn} onClick={isCardOpen ? closeCard : openCard} size="medium">
                                        <PersonIcon style={{ color: '#2D9DE5' }} />
                                    </IconButton>
                                    <ReceptionPopupCard open={isCardOpen} openCard={openCard} closeCard={closeCard} />

                                </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
};

DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
};

export default DashboardNavbar;
