import WideCard from "common/components/WideCard";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommonButton from "common/components/Button";
import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PurchaseOrderApprovalModal from "components/PurchaseOrderApprovalModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllPurchaseOrders} from "services/purchaseOrderMain";

function ManagePurchaseOrderView(){

    const dispatch = useDispatch();
    const poData = useSelector(state => state.PurchaseOrderSlice.purchaseOrder);
    const [purchaseOrders,setPurchaseOrders] = useState([]);
    const [pOrder,setPOrder] = useState([]);
    const [isModalOpen,setModalOpen] = useState(false);

    const tableColumns = [
        {field: "pono", headerName: "PO No", accessor: "pono", textAlign: 'left'},
        {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
        {field: "supplier", headerName: "Supplier", accessor: "supplier", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];
    const searchKey = ['pono', 'date'];

    useEffect(() => {
        dispatch(getAllPurchaseOrders());
    }, []);

    useEffect(() => {
        if(poData.isSuccess && !poData.isLoading){
            const data = poData?.data;
            const filterdData = data.filter(val => val.mainPoStatus!=='pending');
            if(Array.isArray(filterdData)){
                const array = filterdData.map((item)=>(
                    {
                        pono:item.poNo,
                        supplier:item.supplier.name,
                        date:item.poDate,
                        status:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px',backgroundColor:item.mainPoStatus === 'approval'? '#2D9DE5' :item.mainPoStatus === 'approved'? '#1C5D7C' : '' }}
                                    name={item.mainPoStatus}
                                    circular={true}
                                    variant={item.mainPoStatus === 'pending'? 'outline': 'Fill'}
                                    onClick={() => {

                                    }}
                                />
                            </div>
                        ),
                        action:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <IconButton
                                    onClick={() => {
                                        setPOrder(item)
                                        setModalOpen(true);
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>
                            </div>
                        ),

                    }
                ));
                setPurchaseOrders(array)
            }
        }
    }, [poData.data]);

    return(
        <WideCard>
            <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Purchase Order View</Typography>
            <Box>
                <Divider sx={{borderBottom: '1.3px solid black'}}/>
            </Box>

            <MDBox sx={{marginY: 2,}}>
                <MDTable tableData={purchaseOrders} tableColumns={tableColumns} searchKey={searchKey} isSearch={true}/>
            </MDBox>

            {isModalOpen &&
                <PurchaseOrderApprovalModal onClose={()=>{setModalOpen(false)}} open={isModalOpen} items={pOrder}/>
            }
        </WideCard>
    )
}

export default ManagePurchaseOrderView;
