import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {CardContent} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {generateDate} from "common/utils/CommonFunctions";
import MDTable from "common/components/MDTable";
import MDBox from "common/components/MDBox";
import DropDown from "common/components/DropDown/DropDown";
import {useDispatch, useSelector} from "react-redux";
import {getPatientProcedures} from 'services/report/report';
import {getAllBranches} from "services/branch/branch";

const tableColumns = [
    {field: "id", headerName: "#", accessor: "id", textAlign: 'left'},
    {field: "location", headerName: "Location", accessor: "location", textAlign: 'left'},
    {field: "date", headerName: "Date", accessor: "date", textAlign: 'left'},
    {field: "bookingNo", headerName: "Booking Number", accessor: "bookingNo", textAlign: 'left'},
    {field: "time", headerName: "Time", accessor: "doctorName", textAlign: 'left'},
    {field: "doctorName", headerName: "Doctor", accessor: "doctorName", textAlign: 'left'},
    {field: "patientName", headerName: "Patient", accessor: "patientName", textAlign: 'left'},
    {field: "age", headerName: "Age", accessor: "age", textAlign: 'left'},
    {field: "dob", headerName: "DOB", accessor: "dob", textAlign: 'left'},
    {field: "phone", headerName: "Phone", accessor: "phone", textAlign: 'left'},
    {field: "procedure", headerName: "Procedure", accessor: "procedure", textAlign: 'left'},
    {field: "charge", headerName: "Charge", accessor: "charge", textAlign: 'left'},
];

export default function PatientDetailsReport() {

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([])
    const [branch, setBranch] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [searchDetails, setSearchDetails] = useState({ startDate: '', endDate: '' });
    const getBranchData = useSelector((state) => state.BranchSlice.branch);
    const patientProcedureData = useSelector(state => state.PatientProceduresSlice.patientProcedures)


    const handleBranchChange = (value) => {
        setSelectedBranch(value);
        setSearchDetails((prev) => ({
            ...prev,
            branchId: value
        })); 
    };

    useEffect(() => {
        dispatch(getAllBranches());
    }, [dispatch]);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(
                    data.map((branch) => ({ value: branch.branchId, label: branch.name}))
                );
            }
        }
    }, [getBranchData.isSuccess, getBranchData.data]);
    
    

    useEffect(() => {
        if (searchDetails.startDate && searchDetails.endDate && searchDetails.branchId) {
            dispatch(getPatientProcedures(searchDetails))
        }
    }, [searchDetails, dispatch]);
    
    useEffect(()=> {
        if(patientProcedureData.isSuccess && !patientProcedureData.isLoading){
            const array = [];
            patientProcedureData.data?.forEach((val, index)=> {
                array.push({
                    id: ( 1 + index ),
                    location: val.location,
                    date: val.date,
                    bookingNo: val.bookingNo,
                    time:val.time,
                    doctorName: val.doctorName,                                    
                    patientName: val.patientName,
                    age: val.age,
                    dob: val.birthDay,
                    phone:val.mobileNumber,
                    procedure: val.procedureName,
                    charge: val.totalCharge, 
                })
            })
            setTableData(array)
        }
    },[patientProcedureData])

    return(
        <Card sx={{width: '100%'}}>
            <CardContent>
                <Typography sx={{color: '#1C5E7C', fontWeight: 410, fontSize: '18px'}}>Patient Procedures Report</Typography>

                <Box>
                    <Divider sx={{borderBottom: '1.3px solid black'}}/>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: 2 }}>
                    <DropDown
                        label={"Clinic"}
                        options={branch}
                        value={selectedBranch || ''}
                        onChange={handleBranchChange}
                    />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                            label="Visit From"
                            value={searchDetails.startDate || ""}
                            onChange={(date) => {
                                setSearchDetails((prev) => ({
                                    ...prev,
                                    startDate: generateDate(date),
                                }));
                            }}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{ width: '100%', fontSize: '14px', '.MuiInputBase-input': { padding: '8px' } }}
                            label="Visit To"
                            value={searchDetails.endDate || ""}
                            onChange={(date) => {
                                setSearchDetails((prev) => ({
                                ...prev,
                                endDate: generateDate(date),
                                }));
                            }}
                        />
                    </LocalizationProvider>

                </Box>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable
                        tableData={tableData}
                        tableColumns={tableColumns}    
                        isSearch={false}
                    />
                </MDBox>
            </CardContent>
        </Card>
    )
}
