import { createSlice } from "@reduxjs/toolkit";
import { getDetailedClinicalNotes } from "services/report/report";

const initialState = {
    detailedClinicalNote: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const detailedClinicalNoteSlice = createSlice({
    name: "detailedClinicalNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDetailedClinicalNotes.pending, (state) => {
                state.detailedClinicalNote.isLoading = true;
            })
            .addCase(getDetailedClinicalNotes.fulfilled, (state, { payload }) => {
                state.detailedClinicalNote.isLoading = false;
                state.detailedClinicalNote.isSuccess = true;
                state.detailedClinicalNote.data = payload;
            })
            .addCase(getDetailedClinicalNotes.rejected, (state, { payload }) => {
                state.detailedClinicalNote.isLoading = false;
                state.detailedClinicalNote.isSuccess = false;
                state.detailedClinicalNote.errorMessage = payload;
            });
    },
});

export default detailedClinicalNoteSlice.reducer;