import ModalComponent from "common/components/Modal";
import Typography from "@mui/material/Typography";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TextInput from "common/components/TextInput/TextInput";
import CommonButton from "common/components/Button";
import React, {useEffect, useState} from "react";
import IconButton from '@mui/material/IconButton';
import DropDown from "common/components/DropDown/DropDown";
import MDTable from "common/components/MDTable";
import {useDispatch, useSelector} from "react-redux";
import {getSuppliers} from "services/suppllier/supplier";
import {getItems} from "services/item/item";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {
    deleteMainGrn, getAllMainGrn,
    postMainGrn,
    searchMainGrn,
    updateMainGrn,
    updateMainGrnStatus
} from "services/MainGrn/MainGrn";
import {generateDate} from "common/utils/CommonFunctions";
import {sendSuccessNotification, sendWarningNotification} from "common/components/Notification/SendNotification";
import {Delete} from "@mui/icons-material";
import dayjs from "dayjs";

function ManageGRNAddModal({open, onClose, isView = false, grnDetails}) {

    const [viewMode, setViewMode] = useState(isView)
    const [grnId, setGrnId] = useState(isView ? grnDetails.mainGrnId : '')
    const dispatch = useDispatch();
    const [selectedSupplier, setSelectedSupplie] = useState(isView ? grnDetails.supplierId : '');
    const [isApprovalDisable, setApprovalDisable] = useState(!isView ? true : grnDetails.status !== 'pending');
    const [selectedItem, setSelectedItem] = useState('');
    const [qty, setQty] = useState('');
    const [inv, setInv] = useState(isView ? grnDetails.invoiceNo : '')
    const [price, setPrice] = useState('');
    const [date, setDate] = useState( null);
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [loader, setLoader] = useState()
    const [suppliers, setSuppliers] = useState([]);
    const [items, setItems] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [qtyError, setQtyError] = useState('');
    const [priceError, setPriceError] = useState('');
    const supplierData = useSelector(state => state.SupplierSlice.supplier);
    const itemData = useSelector(state => state.ItemSlice.item);
    const saveMainGrn = useSelector(state => state.SaveMainGrnSlice.mainGrn);
    const MainGrnSearch = useSelector(state => state.SearchMainGrnSlice.mainGrn);
    const MainGrnUpdate = useSelector(state => state.UpdateMainGrnSlice.mainGrn);
    const MainGrnDlt = useSelector(state => state.DltMainGrnSlice.mainGrn);
    const MainGrnStatus = useSelector(state => state.UpdateMainGrnStatusSlice.mainGrn);
    const [isUpdate, setIsUpdate] = useState(false);
    const [mainGrnDetailsId, setMainGrnDetailsId] = useState('')
    const [dataAdded, setDataAdded] = useState(false);

    useEffect(() => {
        if(viewMode){
            setDataAdded(tableData.length > 0);
        }
    }, [tableData]);

    const tableColumns = [
        {field: "item", headerName: "Item", accessor: "item", textAlign: 'left'},
        {field: "invoiceDate", headerName: "Invoice Date", accessor: "invoiceDate", textAlign: 'left'},
        {field: "exDate", headerName: "Ex Date", accessor: "exDate", textAlign: 'left'},
        {field: "price", headerName: "Price", accessor: "price", textAlign: 'left'},
        {field: "qty", headerName: "Qty", accessor: "qty", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'center'},
    ];

    const searchKey = ['item', 'exDate'];

    const isComplete = inv && selectedItem && date && selectedSupplier && qty && price && !priceError && !qtyError;

    const handleAction = () => {
        if (isUpdate) {
            const upData = {
                mainGrnDetailsId:mainGrnDetailsId,
                qty: qty,
                price: price,
                itemId: selectedItem,
                mainGrnId: grnId,
                exDate: generateDate(date),
            }
            setLoader(true);
            dispatch(updateMainGrn({id:mainGrnDetailsId , grnUpData: upData}))
        } else {
            if (viewMode) {
                const grnData = {
                    mainGrnId: grnId,
                    invoiceNo: inv,
                    invoiceDate:generateDate(invoiceDate),
                    supplierId: selectedSupplier,
                    grnDetailsDtos: {
                        qty: qty,
                        price: price,
                        itemId: selectedItem,
                        exDate: generateDate(date),
                    }
                }
                setLoader(true);
                dispatch(postMainGrn(grnData));
            } else {
                const grnData = {
                    invoiceNo: inv,
                    supplierId: selectedSupplier,
                    invoiceDate:generateDate(invoiceDate),
                    grnDetailsDtos: {
                        qty: qty,
                        price: price,
                        itemId: selectedItem,
                        exDate: generateDate(date),
                    }
                }
                setLoader(true);
                dispatch(postMainGrn(grnData));
            }
        }
    }

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (saveMainGrn.isSuccess && !saveMainGrn.isLoading) {
                setApprovalDisable(false);
                setViewMode(true);
                setGrnId(saveMainGrn.data.mainGrnId)
                dispatch(searchMainGrn(saveMainGrn.data.mainGrnId))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'Grn Saved Success',
                })
            } else if (!saveMainGrn.isSuccess && !saveMainGrn.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'Grn Save Error'
                })
            }
        }
    }, [saveMainGrn.data]);

    useEffect(() => {
        if (MainGrnSearch.isSuccess && !MainGrnSearch.isLoading && viewMode) {
            const data = MainGrnSearch?.data.mainGrnDetails;
            if (Array.isArray(data)) {
                const array = data.map((val) => ({
                    item: val.itemId?.itemName,
                    qty: val.qty,
                    invoiceDate:MainGrnSearch?.data.invoiceDate,
                    exDate: val.exDate,
                    price:val.price,
                    action: (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <CommonButton
                                sx={{width: '100px'}}
                                name={'Update'}
                                circular={true}
                                variant={'Fill'}
                                onClick={() => {
                                    setIsUpdate(true);
                                    setSelectedItem(val.itemId?.itemId)
                                    setPrice(val.price)
                                    setDate(dayjs(val.exDate))
                                    setInvoiceDate(dayjs(val.invoiceDate))
                                    setQty(val.qty)
                                    setMainGrnDetailsId(val.mainGrnDetailsId)
                                }}
                                disabled={isApprovalDisable}
                            />

                            <IconButton
                                onClick={() => {
                                    setLoader(true);
                                    dispatch(deleteMainGrn(val.mainGrnDetailsId))
                                }}
                                disabled={isApprovalDisable}
                            >
                                <Delete sx={{color: "#1C5D7C"}}/>
                            </IconButton>
                        </div>
                    ),

                }));
                setTableData(array);
            }
        }
    }, [MainGrnSearch.isSuccess, MainGrnSearch.data]);

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (MainGrnDlt.isSuccess && !MainGrnDlt.isLoading) {
                dispatch(getAllMainGrn());
                dispatch(searchMainGrn(grnId))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'GRN Item Delete Success',
                })
            } else if (!MainGrnDlt.isSuccess && !MainGrnDlt.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'GRN Item Delete Error'
                })
            }
        }
    }, [MainGrnDlt.data]);

    const clearFields = () => {
        setSelectedItem(null);
        setQty('');
        setPrice('');
    }

    useEffect(() => {
        dispatch(getSuppliers());
        dispatch(getItems());
        if (isView) {
            dispatch(searchMainGrn(grnDetails.mainGrnId))
        }
    }, []);

    useEffect(() => {
        if (supplierData.isSuccess && !supplierData.isLoading) {
            const data = supplierData?.data;
            if (Array.isArray(data)) {
                const array = data.map((supplier) => (
                    {
                        label: supplier.name,
                        value: supplier.supplierId
                    }
                ));
                setSuppliers(array);
            }
        }
    }, [supplierData.data]);

    useEffect(() => {
        if (itemData.isSuccess && !itemData.isLoading) {
            const data = itemData?.data;
            if (Array.isArray(data)) {
                const array = data.map((item) => (
                    {
                        label: item.itemName,
                        value: item.itemId
                    }
                ));
                setItems(array);
            }
        }
    }, [itemData.data]);

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (MainGrnUpdate.isSuccess && !MainGrnUpdate.isLoading) {
                clearFields();
                setIsUpdate(false);
                dispatch(searchMainGrn(grnId))
                sendSuccessNotification({
                    title: 'Success',
                    message: 'GRN Item Update Success',
                })
            } else if (!MainGrnUpdate.isSuccess && !MainGrnUpdate.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'GRN Item Update Error'
                })
            }
        }
    }, [MainGrnUpdate.data]);

    useEffect(() => {
        if (loader) {
            setLoader(false);
            if (MainGrnStatus.isSuccess && !MainGrnStatus.isLoading) {
                dispatch(getAllMainGrn());
                onClose();
                sendSuccessNotification({
                    title: 'Success',
                    message: 'GRN Submit Success',
                })
            } else if (!MainGrnStatus.isSuccess && !MainGrnStatus.isLoading) {
                sendWarningNotification({
                    title: 'Error',
                    message: 'GRN Submit Error'
                })
            }
        }
    }, [MainGrnStatus.data]);

    return (
        <ModalComponent showModal={open} setShowModal={onClose} maxWidth="md">
            <MDBox sx={{margin: '10px'}}>
                <Box>
                    <div>
                        <Typography sx={{
                            color: '#1C5E7C',
                            fontWeight: 450,
                            fontSize: '16px',
                            marginBottom: '25px',
                            position: 'absolute',
                            top: '15px',
                            left: '15px'
                        }}> {viewMode ? "Update Grn" : "Add Grn"} </Typography>
                    </div>

                </Box>

                <div style={{overflow: 'hidden'}}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            <Box sx={{width: '1000px', margin: '0 auto'}}>
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Invoice No'}
                                value={inv}
                                onChange={(e) => {
                                    setInv(e.target.value);
                                }}
                                disabled={dataAdded}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box sx={{marginY: '10px'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label="Invoice Date"
                                    value={invoiceDate}
                                    onChange={(value) => setInvoiceDate(value)}
                                    disabled={dataAdded}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={5}>
                        <Box sx={{marginY: '10px'}}>
                            <DropDown
                                label={'Item'}
                                options={items}
                                value={selectedItem}
                                onChange={(value) => {
                                    setSelectedItem(value);
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box sx={{marginY: '10px'}}>
                            <DropDown
                                label={'Supplier'}
                                options={suppliers}
                                value={selectedSupplier}
                                onChange={(value) => {
                                    setSelectedSupplie(value);
                                }}
                                disabled={dataAdded}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Price'}
                                value={price}
                                onChange={(e) => {
                                    setPrice(e.target.value)
                                    if (!/^(\d+(\.\d{1,2})?)$/.test(e.target.value)) {
                                        setPriceError("Invalid Price");
                                    } else {
                                        setPriceError("");
                                    }
                                }}
                                error={priceError !== ""}
                            />
                            {priceError && <div style={{color: 'red', fontSize: '12px'}}>{priceError}</div>}
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box sx={{marginY: '10px'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    sx={{width: '100%'}}
                                    label="Ex Date"
                                    value={date}
                                    onChange={(value) => setDate(value)}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                        <Box sx={{marginY: '10px'}}>
                            <TextInput
                                placeholder={'Qty'}
                                value={qty}
                                onChange={(e) => {
                                    setQty(e.target.value)
                                    if (!/^\d+$/.test(e.target.value)) {
                                        setQtyError("Invalid Qty");
                                    } else {
                                        setQtyError("");
                                    }
                                }}
                                error={qtyError !== ""}
                                variant="outlined"
                            />
                            {qtyError && <div style={{color: 'red', fontSize: '12px'}}>{qtyError}</div>}
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>

                    {isUpdate &&
                        <div style={{margin: '5px'}}>
                            <CommonButton
                                name={"Cancel"}
                                variant={'outline'}
                                onClick={() => {
                                    setIsUpdate(false)
                                    clearFields()
                                }}
                            />
                        </div>
                    }

                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={isUpdate ? 'Update' : 'Add'}
                            variant={'Fill'}
                            onClick={() => {
                                handleAction();
                            }}
                            disabled={!isComplete}
                        />
                    </div>
                </Box>

                <MDBox sx={{marginY: 2,}}>
                    <MDTable tableData={tableData} tableColumns={tableColumns} searchKey={searchKey} isSearch={false}/>
                </MDBox>

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Cancel'}
                            variant={'outline'}
                            onClick={() => {
                                onClose();
                            }}
                        />
                    </div>

                    <div style={{margin: '5px'}}>
                        <CommonButton
                            name={'Submit'}
                            variant={'Fill'}
                            onClick={() => {
                                setLoader(true);
                                dispatch(updateMainGrnStatus({id: grnId}))
                            }}
                            disabled={tableData.length === 0 || isApprovalDisable}
                        />

                    </div>
                </Box>
            </MDBox>
        </ModalComponent>
    );
}

ManageGRNAddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isView: PropTypes.bool,
    purchaseDetails: PropTypes.object,
};

export default ManageGRNAddModal;
