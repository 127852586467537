import MDBox from "common/components/MDBox";
import MDTable from "common/components/MDTable";
import CommonButton from "common/components/Button";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import StockTransferItemAddModal from "components/StockTransferItemAddModal";
import {useDispatch, useSelector} from "react-redux";
import {getAllStockTransfers} from "services/stockTransfer/stockTransfer";

function StockTransferView() {

    const dispatch = useDispatch();
    const [isViewFormOpen, setIsViewFormOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [itemDetails,setItemDetails] = useState([])
    const stockTransferData = useSelector(state => state.StockTransferSlice.stockTransfer);

    const tableColumns = [
        {field: "sentBranch", headerName: "Sent Branch", accessor: "sentBranch", textAlign: 'left'},
        {field: "receiveBranch", headerName: "Receive Branch", accessor: "receiveBranch", textAlign: 'left'},
        {field: "receiveDate", headerName: "Receive Date", accessor: "receiveDate", textAlign: 'left'},
        {field: "status", headerName: "Status", accessor: "status", textAlign: 'left'},
        {field: "action", headerName: "Action", accessor: "action", textAlign: 'left'},
    ];

    const searchKey = ['sentBranch', 'receiveBranch'];

    useEffect(() => {
        dispatch(getAllStockTransfers())
    }, []);

    useEffect(() => {
        if(stockTransferData.isSuccess && !stockTransferData.isLoading){
            const data = stockTransferData?.data;
            console.log("dddd",data);
            if(Array.isArray(data)){
                const array = data.map((val)=>(
                    {
                        sentBranch:val.sentBranch.name,
                        receiveBranch:val.receiveBranch.name,
                        receiveDate:val.date,
                        status:(
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <CommonButton
                                    sx={{width: '100px'}}
                                    name={val.transferStatus}
                                    circular={true}
                                    variant={val.transferStatus === 'pending'? 'outline': 'Fill'}
                                    onClick={() => {

                                    }}
                                />
                            </div>
                        ),
                        action:(
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <IconButton
                                    onClick={() => {
                                        setItemDetails(val.stockTransferDetailsDto)
                                        setIsViewFormOpen(true);
                                    }}>
                                    <RemoveRedEyeIcon sx={{color: '#2D9DE5'}}/>
                                </IconButton>
                            </div>
                        ),
                    }
                ));
                setTableData(array)
            }
        }
    }, [stockTransferData.data]);


    return(
        <>
            <MDBox sx={{marginY: 2}}>
                <MDTable
                    tableData={tableData}
                    tableColumns={tableColumns}
                    searchKey={searchKey}
                    isSearch={true}
                />
            </MDBox>

            {isViewFormOpen &&
                <StockTransferItemAddModal
                    onClose={()=>{setIsViewFormOpen(false)}}
                    open={isViewFormOpen}
                    isView={true}
                    sentItems={itemDetails}
                />
            }

        </>
    )
}

export default StockTransferView;
