import { createSlice } from "@reduxjs/toolkit";
import {saveClinicalNote} from "services/clinicalNote/clinicalNote";

const initialState = {
    clinicalNote: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const saveClinicalNoteSlice = createSlice({
    name: "saveClinicalNote",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveClinicalNote.pending, (state) => {
                state.clinicalNote.isLoading = true;
            })
            .addCase(saveClinicalNote.fulfilled, (state, { payload }) => {
                state.clinicalNote.isLoading = false;
                state.clinicalNote.isSuccess = true;
                state.clinicalNote.data = payload;
            })
            .addCase(saveClinicalNote.rejected, (state, { payload }) => {
                state.clinicalNote.isLoading = false;
                state.clinicalNote.isSuccess = false;
                state.clinicalNote.errorMessage = payload;
            });
    },
});

export default saveClinicalNoteSlice.reducer;
