import { APP_HOST_NAME, APP_TEST, APP_PRD, APP_DEV } from "./host";

const baseUrls = {
  prd: "https://vssdms.com/api/",
  test: "http://62.72.12.131:8080/api-0.0.1-SNAPSHOT-qa",
  dev: "https://srv695379.hstgr.cloud/api",
};

const frontendBaseUrls = {
  prd: "https://vssdms.com/",
  test: "http://vssdms.com/admin-panel-dev",
  dev: "http://62.72.12.131/admin-panel-dev",
};

export const getBaseUrl = () => {
  switch (APP_HOST_NAME) {
    case "prd":
      return baseUrls[APP_PRD];
    case "test":
      return baseUrls[APP_TEST];
    case "dev":
      return baseUrls[APP_DEV];
    default:
      throw new Error("Unknown app name. No base API URL registered.");
  }
};

export const getFrontendBaseUrl = () => {
  switch (APP_HOST_NAME) {
    case "prd":
      return frontendBaseUrls[APP_PRD];
    case "test":
      return frontendBaseUrls[APP_TEST];
    case "dev":
      return frontendBaseUrls[APP_DEV];
    default:
      throw new Error("Unknown app name. No base API URL registered.");
  }
};
