import React, {useState} from 'react';
import FilterModal from "common/components/FilterModal";
import TextInput from "common/components/TextInput/TextInput";
import DropDown from "common/components/DropDown/DropDown";
import MDBox from "common/components/MDBox";
import SearchIcon from "@mui/icons-material/Search";
import CommonButton from "common/components/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

function Index({open, onClose}) {
    const [selectedValue, setSelectedValue] = useState('');

    const optionsArray = [
        {value: "One", label: "One"},
        {value: "Two", label: "Two"},
        {value: "Three", label: "Three"}
    ]

    const handleClose = () => {
        onClose();
    };

    return (
        <FilterModal isOpen={open} closeModel={onClose} maxWidth="md">
            <MDBox open={open} onClose={handleClose} maxWidth="md">

                <Box sx={{marginTop: '15px'}}>
                    <TextInput
                        placeholder={'Search'}
                        endIcon={<SearchIcon/>}
                    />
                </Box>

                <Box sx={{marginTop: '20px'}}>
                    <DropDown
                        label={'Select'}
                        options={optionsArray}
                        value={selectedValue}
                        onChange={(value) => {
                            setSelectedValue(value);
                        }}
                    />
                </Box>

                <Grid container style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                    <Grid item xs={5} lg={3}>
                        <CommonButton
                            sx={{width: '100px'}}
                            name={'Active'}
                            circular={true}
                            variant={'Fill'}
                            onClick={() => {
                                console.log("Clicked")
                            }}
                        />
                    </Grid>

                    <Grid item xs={1.2} lg={1.2}></Grid>

                    <Grid item xs={5} lg={3}>
                        <CommonButton
                            sx={{width: '100px'}}
                            name={'Inactive'}
                            circular={true}
                            variant={'outline'}
                        />

                    </Grid>

                    <Grid item xs={12} lg={4.8}></Grid>

                </Grid>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                    <CommonButton
                        name={'Show (10) doctor'}
                        variant={'Fill'}
                        onClick={() => {
                            console.log("Clicked")
                        }}
                    />
                </Box>
            </MDBox>
        </FilterModal>
    );
}

FilterModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.bool.isRequired,
};

export default Index;