import WideCard from "common/components/WideCard";
import MDBox from "common/components/MDBox";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import CommonButton from "common/components/Button";
import MDTable from "common/components/MDTable";
import AppointmentBookingModal from "components/AppointmentBookingModal";
import DropDown from "common/components/DropDown/DropDown";
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches} from "services/branch/branch";
import {getAllDoctorsWithSlotCounts} from "services/doctor/doctor";

function AppointmentBooking() {
    const dispatch = useDispatch();
    const docSlotCount = useSelector((state) => state.DoctorsWithSlotCountSlice.doctor);
    const getBranchData = useSelector(state => state.BranchSlice.branch);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [action, setAction] = useState("add");
    const [tableData, setTableData] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branch, setBranch] = useState([]);

    useEffect(() => {
        if (selectedBranch){
            dispatch(getAllDoctorsWithSlotCounts(selectedBranch.branchId));
        }
    }, [selectedBranch]);

    useEffect(() => {
        dispatch(getAllBranches());
    }, []);

    useEffect(() => {
        if (getBranchData.isSuccess) {
            const data = getBranchData.data;
            if (Array.isArray(data)) {
                setBranch(data.map((branch) => ({value: branch, label: branch.name})));
                setSelectedBranch(data[0])
            }
        }
    }, [getBranchData.data]);

    const getStatusInfo = (status) => {
        if (status === 1) {
            return {text: "Book Now", variant: "Fill"};
        } else {
            return {text: "Book Now", variant: "Fill"};
        }
    };

    useEffect(() => {
        if (docSlotCount.isSuccess) {
            const data = docSlotCount?.data;
            if (Array.isArray(data)) {
                const array = data.map((val) => {
                    const {text, variant} = getStatusInfo(val.status);
                    return {
                        id: val.doctorId,
                        doctorName: (
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <Avatar sx={{backgroundColor: "#EAF5FC", marginRight: "5px"}}>
                                    <IconButton sx={{color: "#2D9DE5"}}>
                                        <PersonIcon fontSize={"large"}/>
                                    </IconButton>
                                </Avatar>

                                <div style={{marginTop: "10px"}}>
                                    {val.title + " " + val.name}
                                </div>
                            </div>
                        ),
                        name: val.name,
                        specialist: val.specialty,
                        contact: val.mobileNumberOne,
                        slots: val.slotCount,
                        appointment: (
                            <div>
                                <CommonButton
                                    sx={{width: "100px"}}
                                    onClick={() => {
                                        handleOpen(variant === "Fill" ? "add" : "view", val);
                                    }}
                                    circular={true}
                                    variant={variant}
                                    name={text}
                                />
                            </div>
                        ),
                    };
                });
                setTableData(array);
            }
        }
    }, [docSlotCount.isSuccess, docSlotCount.data]);

    const handleOpen = (action, doctorData) => {
        if (action === "view") {
            setAction("view");
        } else {
            setAction("add");
        }
        setIsAddFormOpen(true);
        setSelectedDoctor(doctorData);
    };

    const closeForm = () => {
        setIsAddFormOpen(false);
    };

    const tableColumns = [
        {
            field: "doctorName",
            headerName: "Doctor Name",
            accessor: "doctorName",
            textAlign: "left",
        },
        {
            field: "specialist",
            headerName: "Specialist",
            accessor: "specialist",
            textAlign: "left",
        },
        {
            field: "contact",
            headerName: "Contact",
            accessor: "contact",
            textAlign: "left",
        },
        {
            field: "slots",
            headerName: "Slots",
            accessor: "slots",
            textAlign: "left",
        },
        {
            field: "appointment",
            headerName: "Appointment",
            accessor: "appointment",
            textAlign: "left",
        },
    ];
    const searchKey = ["doctorName", "roomNo"];

    return (
        <WideCard>
            <MDBox>
                <Typography sx={{fontSize: "15px", fontWeight: 500, color: "#1C5E7C"}}>
                    Appointment Booking
                </Typography>
            </MDBox>
            <MDBox>
                <Divider
                    sx={{
                        borderBottom: "1.3px solid black",
                        marginTop: "5px",
                        marginY: "5px",
                    }}
                />
            </MDBox>
            <MDBox sx={{width: '20%'}}>
                <DropDown
                    label="Clinic"
                    options={branch}
                    value={selectedBranch}
                    onChange={(value) => {
                        setSelectedBranch(value);
                    }}
                />
            </MDBox>
            <MDBox sx={{marginTop: 1}}>
                <MDTable
                    tableData={tableData}
                    tableColumns={tableColumns}
                    searchKey={searchKey}
                    isSearch={true}
                />
            </MDBox>
            {isAddFormOpen && (
                <AppointmentBookingModal
                    branchDetails={selectedBranch}
                    onClose={closeForm}
                    open={isAddFormOpen}
                    action={action}
                    selectedDoctor={selectedDoctor}
                />
            )}
        </WideCard>
    );
}

export default AppointmentBooking;
