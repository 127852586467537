import { createSlice } from "@reduxjs/toolkit";
import { postDoctor } from "services/doctor/doctor";

const initialState = {
  doctor: {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
  },
};

export const saveDoctorSlice = createSlice({
  name: "saveDoctor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postDoctor.pending, (state) => {
        state.doctor.isLoading = true;
      })
      .addCase(postDoctor.fulfilled, (state, { payload }) => {
        state.doctor.isLoading = false;
        state.doctor.isSuccess = true;
        state.doctor.data = payload;
        state.doctor.errorMessage = "";
      })
      .addCase(postDoctor.rejected, (state, { payload }) => {
        state.doctor.isLoading = false;
        state.doctor.isSuccess = false;
        state.doctor.data = [];
        state.doctor.errorMessage = payload;
      });
  },
});

export default saveDoctorSlice.reducer;
