import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "services/AxiosOrders";

export const getAllStockTransfers = createAsyncThunk('get-stock-transfer', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_transfer`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getSendBranchItems = createAsyncThunk('get-sent-branch-items', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_transfer/branch_with_items/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getReceiverBranchItems = createAsyncThunk('get-receiver-branch-items', async (id, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/stock_transfer/branch_with_items/${id}`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const saveStockTransfer = createAsyncThunk('save-stock-transfer', async (saveData, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/stock_transfer`, saveData, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateStockTransferStatus = createAsyncThunk('update-status', async ({id,updateData}, { rejectWithValue  }) => {
    try {
        const { data } = await instance.put(`/stock_transfer/${id}`,  updateData,{
            headers: { "Content-type": "application/json" },
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

