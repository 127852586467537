import {Card, Grid, Typography} from "@mui/material";
import DropDown from "common/components/DropDown/DropDown";
import React, {useState, useEffect, useRef} from "react";
import Chart from "chart.js/auto";
import {useDispatch, useSelector} from "react-redux";
import {getAllStockCount} from 'services/stockDashboard/stockDashboard'
import {getAllBranches} from "services/branch/branch";

export default function StockUsageView() {

    const dispatch = useDispatch()
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [dropData, setDropData] = useState([]);
    const [selectBranch, setSelectBranch] = useState("");
    const [labels, setLabels] = useState("");
    const [data, setData] = useState("");
    const branchesData = useSelector(state => state.BranchSlice.branch);
    const stockCount = useSelector(state => state.StockDashboardCount.stock);
    const auth = useSelector((state) => state.AuthSlice.auth);

    useEffect(() => {
        if(auth.data?.role !== 'stock'){
            dispatch(getAllBranches());
        }else {
            setSelectBranch(auth.data?.branchId)
        }
    }, []);

    useEffect(() => {
        if (selectBranch !== ""){
            dispatch(getAllStockCount(selectBranch));
        }
    }, [selectBranch]);

    useEffect(()=> {
        if(branchesData.isSuccess && !branchesData.isLoading){
            const array = []
            branchesData.data?.forEach((val, index)=> {
                array.push({value: val?.branchId, label: val?.name},)
                if (index === 0){
                    setSelectBranch(val?.branchId)
                }
            })
            setDropData(array)
        }
    },[branchesData.data]);

    useEffect(()=> {
        if (stockCount.isSuccess && !stockCount.isLoading){
            const name = []
            const stockData = []
            stockCount.data.forEach((val)=> {
                name.push(val?.item?.itemName);
                stockData.push(Math.round(val?.percentage * 10) / 10)
            })
            setData(stockData);
            setLabels(name);
        }
    },[stockCount.data])

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        const myChartref = chartRef.current.getContext("2d");

        chartInstance.current = new Chart(myChartref, {
            type: "line",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Line Chart",
                        data: data,
                        fill: true,
                        backgroundColor: createGradient(myChartref, [
                            "rgba(45, 157, 229, 0.8)",
                            "rgba(255, 255, 255, 0)",
                        ]), // Modify the gradient color as needed
                        borderColor: "#2D9DE5",
                        borderWidth: 2,
                        pointBackgroundColor: "#1C5D7C",
                        pointBorderColor: "#1C5D7C",
                    },
                ],
            },
        });
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [labels, data]);

    const createGradient = (ctx, colors) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 500); // Modify the gradient direction and size as needed
        gradient.addColorStop(0, colors[0]);
        gradient.addColorStop(1, colors[1]);
        return gradient;
    };

    return (
        <Card
            sx={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #EAF5FC",
                borderRadius: "8px",
                marginBottom: "15px",
                padding: "10px",
            }}
        >
            <Grid container spacing={2} sx={{marginBottom: "20px"}}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight: 700,
                            color: "#1C5D7C",
                        }}
                    >
                        Stock Usage
                    </Typography>
                </Grid>
                {auth.data?.role !== 'stock' &&
                    <Grid item xs={6} sm={4.5} md={4.5} lg={4.5}>
                        <DropDown
                            label={"Branch"}
                            options={dropData}
                            value={selectBranch}
                            onChange={(val) => {
                                setSelectBranch(val)
                            }}
                        />
                    </Grid>
                }
            </Grid>
            <canvas ref={chartRef} width="400" height="222vh"/>
        </Card>
    );
}
