import {createSlice} from "@reduxjs/toolkit";
import { updateDoctor} from "services/doctor/doctor";

const initialState = {
    doctor: {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: "",
    },
};

export const updateDoctorSlice = createSlice({
    name: "updateDoctor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateDoctor.pending, (state) => {
                state.doctor.isLoading = true;
            })
            .addCase(updateDoctor.fulfilled, (state, {payload}) => {
                state.doctor.isLoading = false;
                state.doctor.isSuccess = true;
                state.doctor.data = payload;
            })
            .addCase(updateDoctor.rejected, (state, {payload}) => {
                state.doctor.isLoading = false;
                state.doctor.isSuccess = false;
                state.doctor.errorMessage = payload;
            });
    },
});

export default updateDoctorSlice.reducer;
